.popup-overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 130;

    .popup-mobile-apps {
        position: fixed;
        bottom: -350px;
        left: 0;
        right: 0;
        height: 345px;
        padding: 33px 0 25px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #ffffff;
        z-index: 150;
        transition: bottom .4s ease;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-family: Montserrat, sans-serif;

        &__stores-info {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            text-align: center;

            .stores-info__logo {
                width: 250px;
                height: 116px;
            }

            .stores-info__text {
                color: #000000;
                font-size: 14px;
            }

            .stores-info__buttons {
                margin-top: 20px;
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                justify-content: center;

                a {
                    display: inline-block;

                    img {
                        width: 135px;
                        height: 40px;
                    }

                    &:hover {
                        filter: brightness(1.1);
                    }
                }
            }
        }

        &__recommendation {
            color: #3D3D3D;
            display: none;
        }

        &__screenshot {
            margin-left: 80px;
            margin-right: 80px;
            width: 140px;
            height: 287px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../public/mobile/app-screenshot.png");

            &_en {
                background-image: url("../public/mobile/app-screenshot-en.png");
            }
        }

        &__right-block {
            max-width: 400px;

            &-header {
                font-weight: bold;
                font-size: 18px;
                color: #3D3D3D;

                .text-tablet {
                    display: none;
                }
            }

            .popup-mobile-apps__action-button {
                display: none;
            }

            .popup-mobile-apps__qr-code {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .qr-code-image {
                    position: relative;
                    left: -5px;
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 178px;
                    height: 178px;

                    &_local {
                        background-image: url("../public/mobile/qr-redirect-local.svg");
                    }

                    &_dev {
                        background-image: url("../public/mobile/qr-redirect-dev.svg");
                    }

                    &_prod {
                        background-image: url("../public/mobile/qr-redirect-prod.svg");
                    }
                }

                .qr-code-text {
                    width: 219px;
                    margin-left: 18px;
                    font-size: 14px;
                    color: #000000;
                }
            }
        }

        .close-button {
            position: absolute;
            top: 30px;
            right: 30px;
            width: 16px;
            height: 16px;
            font-size: 30px;
            line-height: 16px;
            font-weight: 100;
            color: #CACACA;
            cursor: pointer;

            &:hover {
                color: #3D3D3D;
            }
        }

        .action-button {
            padding: 15px 40px;
            border-radius: 5px;
            background-color: #FF3D41;
            font-size: 14px;
            color: #FFFFFF;

            &:hover, &:active {
                background-color: #FF4D51;
            }
        }
    }

    &_show {
        visibility: visible;

        .popup-mobile-apps {
            bottom: 0;
        }
    }
}

// tablets
@media only screen and (pointer:coarse) and (min-device-width: 768px) and (max-device-width: 2732px) {
    .popup-overlay {
        .popup-mobile-apps {
            &__stores-info {
                .stores-info__logo {
                    width: auto;
                    height: 80px;
                }

                .stores-info__text {
                    font-size: 12px;
                }

                .stores-info__buttons {
                    a img {
                        width: 110px;
                        height: 32px;
                    }
                }
            }

            &__screenshot {
                background-image: url("../public/mobile/app-screenshot-pad.png");
                margin-left: 50px;
                margin-right: 50px;

                &_en {
                    background-image: url("../public/mobile/app-screenshot-tablet-en.png");
                }
            }

            &__right-block {
                max-width: 300px;

                &-header {
                    .text-desktop {
                        display: none;
                    }
                    .text-tablet {
                        display: block;
                    }
                }

                .popup-mobile-apps__qr-code {
                    display: none;
                }

                .popup-mobile-apps__action-button {
                    display: block;
                    margin-top: 30px;
                }
            }
        }
    }
}

// phones
@media only screen and (pointer:coarse) and (max-device-width: 767px) {
    .popup-overlay {
        .popup-mobile-apps {
            height: auto;
            padding: 40px 20% 80px;
            flex-flow: column;
            justify-content: center;
            text-align: center;

            &__stores-info {
                .stores-info__logo {
                    width: auto;
                    height: 126px;
                }

                .stores-info__text {
                    font-size: 20px;
                }

                .stores-info__buttons {
                    display: none;
                }
            }

            &__screenshot {
                display: none;
            }

            &__recommendation {
                display: block;
                margin-top: 30px;
                font-size: 36px;
                font-weight: 300;
            }

            &__right-block {
                max-width: initial;

                &-header, .popup-mobile-apps__qr-code {
                    display: none;
                }

                .popup-mobile-apps__action-button {
                    display: block;
                    margin-top: 30px;
                }

                .action-button {
                    display: block;
                    padding: 30px 80px;
                    font-size: 28px;
                }
            }

            .close-button {
                width: 32px;
                height: 32px;
                line-height: 32px;
                font-size: 60px;
            }
        }
    }
}
