
.search-preview-results {
    position: relative;
    width: calc(~'100% + 40px');
    background: #FFFFFF;
    min-height: 60px;
    margin-left: -20px;
    top: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 5px 5px;

    &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
        width: 100%;
        height: 5px;
        background: #fff;
    }

    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.7);
        display: none;
        z-index: 2;
    }

    &.waiting-for-request .loading {
        display: block;
    }

    .search_wrap_result {
        max-height: calc(~'100vh - 250px');
        overflow-x: hidden;
        overflow-y: auto;
        padding: 4px 20px 20px 20px;

        .wrap_results {

            a {
                text-decoration: none;
            }

            .search-block-type {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .search-preview {
                text-align: center;
                color: #676a6c;
                font-weight: 600;
                padding: 5px 0;
                .search-name {
                    display: inline-block;
                    padding-bottom: 1px;
                    margin: 5px 0 0;
                    font-weight: 600;
                    font-size: 12px;
                }
            }
            .search-preview-group-code {
                text-align: center;
                cursor: pointer;
                color: #676a6c;
                font-weight: 600;
                padding: 5px 0;
                border-radius: 2px;
                position: relative;
                height: 28px;

                .search-name-group-code {
                    margin: 0;
                    display: inline-block;
                    padding-bottom: 1px;
                    border-bottom: 1px dashed;
                    font-size: 12px;
                }

                .one-filter-close-button {
                    position: absolute;
                    right: 6px;
                    top: 0;
                    color: red;
                    font-size: 23px;
                    font-weight: 500;
                    line-height: 27px;
                    width: 27px;
                    height: 27px;
                    text-align: center;
                    cursor: pointer;
                    opacity: 0.5;
                }

                &:hover {
                    .search-name-group-code {
                        border-bottom: none;
                    }
                }

                &.search-preview-one-filter {
                    background: rgba(255, 0, 0, 0.1);
                    &:hover {
                        .one-filter-close-button {
                            opacity: 1;
                        }
                    }
                }
            }

            .search-preview-model {
                cursor: pointer;
                padding: 5px 0;
                width: 100%;
                display: flex;
                justify-content: space-between;

                &:hover {
                    background-color: #f3f3f3;
                }

                .search-img {
                    max-height: 60px;
                    width: 60px;
                    overflow: hidden;
                    text-align: center;

                    img {
                        height: 100%;
                    }
                }

                .search-model {
                    width: calc(~'100% - 70px');
                    .search-model-name {
                        color: #000;
                        font-size: 12px;
                        font-weight: 600;
                        margin: 5px 0;
                        max-height: 35px;
                        overflow: hidden;
                    }

                    .search-sku {
                        font-size: 11px;
                        color: #676a6c;
                    }
                }
            }

            .search-block-empty {
                text-align: center;
                padding-top: 14px;
                font-size: 13px;
                color: #676a6c;
            }
        }
    }
}

.show-all-search-results {
    min-width: 100%;
    @media (max-width: 900px) {
        font-size: 16px;
    }
}

.top_search {
    order: 2;
    z-index: 10;
    width: 416px;
    padding: 0;

    @media (max-width: 880px) {
        order: 1;
        margin: 0 auto;
    }

    @media (max-width: 500px) {
        padding: 0 10px;
        width: 100%;
    }

    .main-search {
        width: 100% !important;
        height: 50px !important;
        position: relative;
        box-shadow: none !important;
        padding: 0 !important;
        top: 0;
        right: auto;
        margin: 0 auto;

        input {
            margin: 0;
            width: 100%;
            border-width: 2px;
            border-color: #E5E5E5;
            top: 0;
            height: 50px;
            visibility: visible;
            opacity: 1;
            position: relative;
        }

        svg {
            position: absolute;
            top: 14px;
            left: 10px;
            cursor: default;
        }

        &.active {

            input {
                margin: 0;
                width: 100%;
            }

            .clear_search_input {
                right: 10px;
                top: 13px;
            }
        }

        .search-preview-results {
            margin-left: 0;
            width: 100%;
        }
    }

    .search-preview-results {
        .search_wrap_result {
            max-height: calc(100vh - 480px);

            @media (max-width: 905px) {
                max-height: calc(100vh - 400px);
            }

            @media (max-width: 600px) {
                max-height: calc(100vh - 500px);
            }
        }
    }
}

.main-search {
    position: absolute;
    padding-top: 12px;
    z-index: 2;
    width: 50px;
    top: 0;
    right: 100%;
    color: #676a6c;
    font-family: "Open Sans", sans-serif;
    //noinspection LessResolvedByNameOnly
    .transition(all, 400ms, 0ms, cubic-bezier(0.43, 0.65, 0.07, 0.96));

    &.active {
        width: 480px;
        height: 48px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);

        input {
            opacity: 1;
            margin: 0 20px;
            width: calc(~'100% - 40px');
            visibility: visible;
            padding-right: 25px;
        }

        .clear_search_input {
            position: absolute;
            right: 25px;
            top: 11px;
            color: #ff0000;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            width: 24px;
            height: 24px;
            text-align: center;
            cursor: pointer;
            opacity: 0.5;
            display: block;
            &:hover {
                opacity: 1;
            }
        }

        .search-preview-results {
            display: block;
        }
    }
    svg {
        z-index: 1;
        position: relative;
        cursor: pointer;
        left: 7px;
    }

    input {
        position: absolute;
        left: 0;
        top: 7px;
        height: 32px;
        width: 100%;
        font-size: 16px;
        border: 1px solid #ccc;
        padding: 0 15px 0 40px;
        opacity: 0;
        visibility: hidden;
        //noinspection LessResolvedByNameOnly
        .transition(all, 400ms, 0ms, cubic-bezier(0.43, 0.65, 0.07, 0.96));
        outline: none !important;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font-size: 14px;
            color: #999;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            font-size: 14px;
            color: #999;
        }

        &:-ms-input-placeholder { /* IE 10+ */
            font-size: 14px;
            color: #999;
        }

        &:-moz-placeholder { /* Firefox 18- */
            font-size: 14px;
            color: #999;
        }
    }

    .clear_search_input {
        display: none;
    }

    .search-preview-results {
        display: none;
    }
}

.suggests {
    font-weight: bold;
    font-size: 12px;
    line-height: 2em;
    cursor: pointer;
}
