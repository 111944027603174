@baseFontSize: 16;

@green: #009688;

.rem(@val) {
    @return (@val / @baseFontSize) ~ 'rem';
}

.checkboxes-multicheck-list {
    margin: 10px;
    font-size: 17px;
    &__item {
        margin: 0 0 .5em 0;
        padding: 0;
    }
    &__item-label {
        position: relative;
        margin: 0 .5em;
        font-family: "Open Sans", sans-serif;
        line-height: 135%;
        cursor: pointer;
        display: inline-block;
        max-width: none;
        font-weight: normal;
        font-size: 15px;
        width: 100%;
    }
    &__checkbox[type=checkbox]{
        position: relative;
        top: -0.3em;
        margin: 0 10px 0 0 ;
        cursor: pointer;
        vertical-align: bottom;
        &:before {
            .transition(all, .3s, 0ms, ease-in-out);

            content: "";

            position: absolute;
            left: 0;
            z-index: 1;

            width: 1em;
            height: 1em;

            border: 2px solid #f2f2f2;
        }

        &:checked {
            &:before {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);

                height: .5em;

                border-color: @green;
                border-top-style: none;
                border-right-style: none;
            }
        }

        &:after {
            content: "";

            position: absolute;
            top: -0.125em;
            left: 0;

            width: 1.1em;
            height: 1.1em;

            background: #fff;

            cursor: pointer;
        }
    }
}