.md-skin{
    .navbar-fixed-top{
        background-color: #fff !important;
    }
    .navbar-header{
        .btn-primary{
            background-color: #FFFFFF;
            border-color: #FFFFFF;
            color: @primaryColorLight;
            &:hover{
                background-color: fade(@primaryColorLight, 20%);
            }
        }
    }
    .nav.navbar-right > li{
        > a{
            color: #777;
        }
    }
}
@media (max-width: 768px) {
    .fixed-sidebar.body-small .navbar-static-side {
        display: block;
        width: 0;
        background-color: #ffffff;
    }
}

.md-skin .nav-header {
    background: url(../theme/less/patterns/4_red.jpg) no-repeat;
}
.nav > li.active {
    border-left: 4px solid @primaryColorLight;
}
.btn-primary {
    background-color: @primaryColorLight;
    border-color: @primaryColorLight;
    &:hover, &:focus, &:active, &.active, &:active:focus, &:active:hover, &.active:hover, &.active:focus {
        background-color: @primaryColor;
        border-color: @primaryColor;
    }
    &[disabled], &[disabled]:hover, &.disabled, &.disabled:hover, &.disabled:active, &.disabled:focus{
        background-color: fade(@primaryColorLight, 60%)!important;
        border-color: fade(@primaryColorLight, 60%)!important;;
    }
    &.btn-outline{
        background-color: transparent;
        color: @primaryColorLight;
    }
}
.btn-green {
    background-color: @greenColorLight;
    border-color: @greenColorLight;
    color: white;
    &:hover, &:focus, &:active, &.active, &:active:focus, &:active:hover, &.active:hover, &.active:focus {
        background-color: @greenColor;
        border-color: @greenColor;
        color: white;
    }
    &.disabled, &.disabled:hover{
        background-color: fade(@greenColorLight, 60%);
        border-color: fade(@greenColorLight, 60%);
    }
    &.btn-outline{
        background-color: transparent;
        color: @greenColorLight;
        &:hover{
            background-color: @greenColorLight;
            color: white;
        }
    }
}
.panel-primary{
    border-color: @primaryColor;
    > .panel-heading{
        background-color: @primaryColorLight;
        border-color: @primaryColor;
    }
}