.products-wrap {
    display: none;
    height: 100%;
    width: 100%;
    @media (min-width: 1200px) {
        width: 1080px;
        &.merge-models {
            width: 1330px;
        }
    }

    .total-info {
        text-align: right;
        padding: 10px 60px 10px 30px;
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;

        .positions {
            font-size: 14px;
        }

        .total-amount-box {
            text-transform: uppercase;
            .new-price-amount {
                line-height: 1.4;
                font-size: 24px;
                color: #ED1C24;
            }
            .old-price-amount {
                font-size: 16px;
                text-decoration: line-through;
                color: #444;
                line-height: 1;
            }
        }
    }
}

.products-popup {
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-width: 750px;
    padding: 26px 25px 20px;
    overflow-y: auto;
    height: 100%;
    font-family: Open Sans,sans-serif;

    .products-popup__filter {
        &-wrapper {
            top: 15px;
            width: 100%;
            margin-bottom: 10px;
            border: 1px solid #E6E6E6;
            box-shadow: none;
        }
        &-header {
            padding: 4px 15px;
            background: #f5f5f5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            min-height: 35px;
            h3 {
                color: #000;
                margin-bottom: 5px;
            }
            .collapse-link {
                color: #c4c4c4;
                padding: 5px;
                cursor: pointer;
                margin: 0;
                right: -5px;
                &:hover {
                    color: #8E8E8E;
                }
            }
            .ibox-tools {
                display: flex;
            }
        }
        &-items {
            padding: 10px 15px 5px;
            background: #f5f5f5;
        }
        &-item {
            margin-bottom: 12px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: baseline;
            gap: 6px;
            &_title {
                color: #000;
                font-size: 14px;
                line-height: 1.4;
                font-weight: bold;
                display: inline-block;
                margin: 0;
            }
            &_element {
                border-radius: 3px;
                background: #e5e5e5;
                padding: 3px 10px;
                line-height: 16px;
                cursor: pointer;
                &:hover {
                    background: #ccc;
                }
                &.active {
                    background: #d4eaca;
                    &:hover {
                        background: #c2e3b3;
                    }
                }
            }
        }
    }

    &__reset-filters {
        padding: 2px 5px;
    }

    &__legend-box {
        margin-top: 18px;
        text-align: right;
        //noinspection LessResolvedByNameOnly
        .align-self(flex-end);
    }

    &__legend-table {
        .available-indicator {
            display: inline-block;
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }
        }

        .delay-indicator {
            display: inline-block;
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .quantity {
        text-align: right;
        height: 20px;
        margin-bottom: -10px;
        top: 0;
        //noinspection LessResolvedByNameOnly
        .align-self(flex-end);
    }

    .quantity-switcher-wrapper {
        text-align: center;
        top: 0;
        //noinspection LessResolvedByNameOnly
        .align-self(flex-end);
    }

    .photos-wrapper {
        width: 100%;
        top: 50px;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .main-photo-wrapper {
            width: 216px;
            text-align: center;
            height: 300px;
            margin-bottom: 10px;
            display: inline-block;
            vertical-align: top;
        }

        .promo-material-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .item_promo_material {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #efefef;
                color: #313131;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                overflow: hidden;

                &.edit_promo_material {
                    position: absolute;
                    background: transparent;
                    width: 20px;
                    height: 20px;
                    right: 0;
                    bottom: 0;
                    filter: drop-shadow(0 0 3px rgba(255,255,255,1));
                    padding: 5px;
                    box-sizing: content-box;

                    &:hover {
                        svg path {
                            stroke: #5f5f5f;
                        }
                    }
                }

                &.add_promo_material {
                    &:hover {
                        background: #e7e7e7;
                    }
                }

                &.promo_img {
                    height: 48px;
                    width: 100%;

                    &:hover {
                        opacity: 0.8;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        max-height: unset;
                    }

                    .promo_default_name {
                        position: absolute;
                    }
                }
            }
        }

        .main-photo {
            margin: 0 auto;
            display: inline-block;
            height: 100%;
            width: auto;

            video {
                max-height: 100% !important;
                max-width: 100% !important;
            }

            &.empty {
                width: 100%;
                padding: 0;
            }
        }

        .photo-tabs {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
            width: calc(~"100% - 220px");
            padding-left: 20px;
            gap: 2%;
            .photo-tab {
                display: inline-block;
                flex: 1 1 auto;
                text-align: center;
                padding: 3px;
                margin-bottom: 16px;
                color: #808080;
                border-bottom: 2px solid transparent;
                font-size: 14px;
                box-shadow: 0 1px 5px 0 #00000040;
                &:hover {
                    cursor: pointer;
                }
                &:hover,
                &.tab-active {
                    color: #df2324;
                    border-bottom-color: #df2324;
                }
            }
        }

        .additional-photos {
            max-height: 320px;
            overflow-y: auto;
            width: calc(~"100% - 220px");
            vertical-align: top;
            padding-left: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            align-items: center;

            .photo {
                display: inline-block;
                width: 57.5px;
                height: 80px;
                overflow: hidden;

                &.video {
                    video {
                        width: 72px;
                        height: 72px;
                    }

                    &:after {
                        content: ' ';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        background-position: center;
                        background-size: 30px;
                        background-repeat: no-repeat;
                        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMzJweCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJMYXllcl8xIi8+PGcgaWQ9InBsYXlfeDVGX2FsdCI+PHBhdGggZD0iTTE2LDBDNy4xNjQsMCwwLDcuMTY0LDAsMTZzNy4xNjQsMTYsMTYsMTZzMTYtNy4xNjQsMTYtMTZTMjQuODM2LDAsMTYsMHogTTEwLDI0VjhsMTYuMDA4LDhMMTAsMjV6ICAgIiBzdHlsZT0iZmlsbDojNEU0RTUwOyIvPjwvZz4KCjxnIGlkPSJwbGF5X3g1Rl9hbHQiPjxwYXRoIGQ9Ik0xMCwyNFY4bDE2LjAwOCw4TDEwLDI1eiAgICIgc3R5bGU9ImZpbGw6I0ZGRkZGRjsiLz48L2c+Cgo8L3N2Zz4=");
                        opacity: 0.7;
                    }
                }
            }
        }

        &.with-photo-tabs {
            .additional-photos {
                max-height: 280px !important;
            }
        }
    }

    .main-info {
        margin-left: 0;
        width: 100%;
        vertical-align: top;
        position: relative;
        //noinspection LessResolvedByNameOnly
        .flex-display(flex);
        //noinspection LessResolvedByNameOnly
        .flex-direction(column);

        .model-name {
            top: -340px;
            font-family: "Open Sans", sans-serif;
            font-size: 24px;
            position: relative;
            padding-bottom: 5px;
            margin-bottom: 0;
            margin-top: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 300;
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-right: 250px;
            //noinspection LessResolvedByNameOnly
            .flex-shrink(0);

            &:before {
                content: "";
                background-color: red;
                height: 1px;
                width: 190px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .short-description {
            margin-top: 5px;
            min-height: 20px;
            //noinspection LessResolvedByNameOnly
            .flex-shrink(0);
        }

        .is_honest_sign .short-description {
            width: calc(~"100% - 90px");
        }

        .wrap_column {
            margin-top: 10px;
            height: 21px;
            display: flex;
            flex-direction: row;
            //noinspection LessResolvedByNameOnly
            .flex-shrink(0);
            //noinspection LessResolvedByNameOnly
            .align-self(flex-start);
        }

        .show-full-description {
            position: relative;
            margin-right: 20px;
            .dashed {
                cursor: pointer;
                border-bottom: 1px dashed #808080;
            }
        }

        .modelSku {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .patternCode {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-left: 20px;
        }

        .full-description {
            display: none;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 2;
            height: auto;
            background-color: #ffffff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            padding: 15px;
            margin-top: 10px;
            width: auto;
            min-width: 400px;
            max-width: 600px;
            filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
            white-space: pre-wrap;

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                position: absolute;
                top: -20px;
                left: 30px;
                border-bottom-color: #ffffff;
            }
        }
        .check_all_users {
            padding-right: 15px;
        }
        .honest_sign {
            background-size: contain;
            background-image: url('../img/chz-logo.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 78px;
            height: 32px;
            position: absolute;
            top: 22px;
            right: 0;
        }
    }

    .products-list {
        margin-top: 10px;
        border-collapse: collapse;
        padding-bottom: 20px;

        .products-table {
            position: relative;
            display: table;
            width: 100%;

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.7);
                display: none;
                z-index: 2;
            }

            &.waiting-for-request {
                .loading {
                    display: block;
                }
            }
        }

        .table-cell {
            display: table-cell;
            text-align: center;
        }

        .products-table-header {
            line-height: 30px;
            font-size: 15px;
            white-space: nowrap;
            width: 100%;
            display: table-row;
            padding-right: 15px;

            .available-head {
                text-align: center;
                padding-right: 15px;
                min-width: 60px;

                .desc_norm_text_dealer {
                    display: none;
                    flex-wrap: wrap;
                    margin-bottom: -5px;
                    margin-right: -10px;
                    position: relative;
                    top: -5px;

                    .quantityStore {
                        display: block;
                        width: 100%;
                        line-height: 14px;
                        height: 14px;
                    }

                    .inStore {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        text-align: center;
                        line-height: 11px;
                        height: 20px;
                        padding-left: 5px;
                        align-items: center;
                        padding-top: 2px;

                        span {
                            white-space: pre-wrap;
                            word-break: break-word;
                            width: 58px;
                            padding: 0 5px;
                            &.available {
                                color: #1ba91b;
                            }
                            &.delay {
                                color: #2fa8e1;
                            }
                        }
                    }
                }
            }

            .table-cell {
                color: #959595;
                font-size: 12px;

                &.currency {
                    text-align: center;
                    padding-right: 5px;
                    padding-left: 5px;
                    line-height: 17px;
                    top: -5px;
                }

                &.color-box {
                    padding-left: 4px;
                }
            }
        }

        .size-block {
            width: 100%;
            display: table;
        }

        .size-header {
            line-height: 30px;
            font-size: 15px;
            white-space: nowrap;
            width: 100%;
            display: table-caption;
            font-weight: bold;
            color: #000000;
            padding-right: 15px;
        }

        .has-parent-stock {
            .available-head {
                .desc_norm_text_dealer {
                    display: flex;
                }

                .desc_norm_text {
                    display: none;
                }

                .desc_small_text {
                    display: none;
                }
            }
        }

        .product-row {
            border: 1px solid #e6e6e6;
            border-bottom: none;
            background-color: #f5f5f5;
            line-height: 55px;
            font-size: 14px;
            display: table;
            width: 100%;
            &:last-child {
                border-bottom: 1px solid #e6e6e6;
                line-height: 54px;
            }
        }

        .products-table-header,
        .product-row {
            > div {
                display: table-cell;
            }

            &.with-old-price {
                .price-box {
                    line-height: 15px;
                    height: 100%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    vertical-align: top;
                }

                .old-price {
                    font-size: 12px;
                    text-decoration: line-through;
                }

                .new-price {
                    color: #ff7575;
                }
            }

            .color-icon {
                width: 38px;
                height: 46px;
                position: absolute;
                top: 5px;
                left: 0;
                display: block;
                overflow: hidden;
                img {
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .color-box {
                padding-left: 50px;
                padding-right: 5px;
                white-space: nowrap;
                max-width: 160px;
                min-width: 160px;
                width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover .dashed-gray {
                    border-bottom-color: transparent;
                }

                @media (max-width: 901px) {
                    max-width: 200px;
                    min-width: 200px;
                    width: 200px;
                }
            }

            .art-box {
                padding-right: 4px;
                padding-left: 4px;
                white-space: nowrap;
                width: 250px;
            }

            .pattern-box {
                padding-right: 4px;
                padding-left: 4px;
                white-space: nowrap;
                width: 200px;
            }

            .price-box {
                text-align: center;
                padding-right: 10px;
                padding-left: 5px;
                white-space: nowrap;
                width: 80px;
            }

            .retail-price {
                text-align: center;
                padding-right: 10px;
                padding-left: 5px;
                white-space: nowrap;
                width: 80px;
            }

            .count_box {
                display: flex;
                padding-right: 15px;
                justify-content: center;
                min-width: 60px;

                .available-indicator,
                .delay-indicator {
                    justify-content: center;
                    display: flex;
                    height: 100%;
                    vertical-align: middle;
                    top: 4px;
                }
            }

            .undelay,
            .unavailable {
                position: absolute;
                left: -15px;
                top: -5px;
                transform: translate(12px, -50%);
                color: #808080;
            }

            .delay-box,
            .available-box {
                &.just-text {
                    font-size: 11px;
                    vertical-align: top;
                    white-space: nowrap;
                    text-align: center;

                    &.available-count-many,
                    &.available-count-enough,
                    &.available-count-several {
                        font-size: 14px;
                    }
                }
            }

            .availableMyCount-box,
            .availableMy-box {
                outline: none !important;
                text-align: right;
                padding-right: 10px;
                min-width: 60px;

                > span {
                    border-bottom: 1px dashed #337ab7;
                    color: #337ab7;
                    cursor: pointer;
                    display: inline-block;
                    line-height: 15px;
                }
            }

            .availableMyCount-box {
                padding-right: 20px;
            }

            .price-hide {
                cursor: pointer;
                text-align: center;

                span {
                    border-bottom: 1px dashed #a0a0a0;
                    color: #a0a0a0;
                    font-size: 13px;
                    line-height: 16px;
                    display: inline-block;
                    position: relative;
                    top: -2px;
                }

                &:hover {
                    span {
                        border-bottom-color: transparent;
                    }
                }
            }
        }

        &__empty {
            display: none;
            padding-top: 30px;
            font-size: 14px;
            text-align: center;
        }
    }

    &.product-page {
        .products-list {
            max-height: none !important;
            min-height: auto !important;
        }
        .additional-photos {
            max-height: none !important;
        }
    }
    &.is-promo-material {
        .promo-material-wrapper {
            margin-bottom: 16px;
        }
    }

    .close-popup {
        position: absolute;
        right: 10px;
        top: 5px;
        color: #cacaca;
        font-size: 36px;
        font-weight: 500;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        z-index: 2;
    }

    &.hiddenPrice {
        .products-popup__legend-box {
            display: none;
        }
    }

    @media (min-width: 1200px) {
        .photos-wrapper {
            width: 320px;
            float: left;
            top: 0;

            .main-photo-wrapper {
                display: block;
                height: 443px;
                width: 100%;
            }

            .photo-tabs {
                width: 100%;
                padding-left: 0;
            }

            .additional-photos {
                width: 100%;
                padding-left: 0;
            }
        }

        .main-info {
            margin-left: 348px;
            width: calc(~"100% - 348px");
            height: 100%;
            font-size: 13px;
            font-family: "Open Sans", sans-serif;

            .model-name {
                top: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.delay-indicator,
.available-indicator {
    .item {
        display: inline-block;
        width: 6px;
        height: 18px;
        background-color: #8DCF0E;
        margin: 1.5px;
        border-radius: 10px;
        vertical-align: middle;
        top: -1px;
    }

    &.available-count-enough {
        .third {
            background-color: lightgray;
        }
    }

    &.available-count-delay {
        .first, .second, .third {
            background-color: #2fa8e1;
        }
    }

    &.available-count-several {
        .first {
            background-color: orange;
        }

        .second, .third {
            background-color: lightgray;
        }
    }

    &.available-count-none {
        .first, .second, .third {
            background-color: lightgray;
        }
    }
}

.delay-box {
    min-width: 50px;
    padding-left: 15px;

    .undelay {
        display: none;
        font-size: 11px;
        color: #555;
        white-space: nowrap;
    }

    &.available-count-none {
        color: gray;
    }

    &.available-count-delay {
        color: #2fa8e1;
    }

    &.available-count-many {
        color: #1ba91b;
    }

    &.available-count-enough {
        color: #1ba91b;
    }

    &.available-count-several {
        color: #1ba91b;
    }
}

.not-in-stock {
    .count-selector {
        visibility: hidden;
    }

    &.not-parent-stock {
        .available-box {
            .unavailable {
                display: block !important;
            }

            .available-indicator {
                display: none !important;
            }
        }

        &.not-in-stock__delay {
            .available-box[data-count="0"] {
                .unavailable {
                    display: block !important;
                }

                .available-indicator {
                    display: none !important;
                }
            }
        }
    }
}

.available-box {
    min-width: 50px;

    .unavailable {
        display: none;
        font-size: 11px;
        color: #555;
        white-space: nowrap;
    }

    &.available-count-none {
        color: gray;
    }

    &.available-count-delay {
        color: #2fa8e1;
    }

    &.available-count-many {
        color: #1ba91b;
    }

    &.available-count-enough {
        color: #1ba91b;
    }

    &.available-count-several {
        color: #1ba91b;
    }
}

.count-box {
    padding: 0 10px 0 0;
    white-space: nowrap;
    width: 110px;
    min-width: 110px;
    line-height: 30px;
    display: inline-block;
    .offsel();

    td& {
        display: table-cell;
    }

    input {
        line-height: 21px;
        margin: 0 7px;
        border: 1px solid #E6E6E6;
        border-radius: 3px;
        text-align: center;
        font-size: 15px;
        width: 44px;
    }

    .count-selector-button {
        color: #E32B2D;
        font-size: 19px;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;

        .fa {
            background-color: transparent;
        }
    }

    .number-wrapper {
        display: inline-block;
        font-size: 15px;
        width: 49px;
        margin: 3px 7px 0;
        height: 23px;
        overflow: hidden;
        border-radius: 3px;
        vertical-align: top;
        border: 1px solid #e6e6e6;
        background-color: white;
    }

    input[type=number] {
        line-height: 21px;
        border: none;
        text-align: center;
        font-size: 15px;
        width: 100%;
        outline: none !important;
        vertical-align: top;
        margin: 0;
        -moz-appearance: textfield;

        &[data-for-all="1"] {
            background-color: #e8f1ff;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
            line-height: 21px;
            font-size: 15px;
            vertical-align: top;
            margin: 0;
            text-align: center;
        }
    }
}

.code-switcher {
    &__button {
        display: inline-block;
        border-bottom: 1px dashed #666;
        color: #666;
        line-height: 15px;

        &:hover {
            color: #666;
        }

        &.active {
            border-bottom: 1px dashed transparent;
            color: @primaryColor;
            cursor: default;

            &:hover {
                color: @primaryColor;
            }
        }
    }
}

.quantity-switcher {
    &__button {
        display: inline-block;
        border-bottom: 1px dashed #666;
        color: #666;
        line-height: 15px;

        &:hover {
            color: #666;
        }

        &.active {
            border-bottom: 1px dashed transparent;
            color: @primaryColor;
            cursor: default;

            &:hover {
                color: @primaryColor;
            }
        }
    }
}

.product-badges {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: 65px;
    height: 19px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-family: SegoeUI, Verdana, sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    line-height: 18px;
    padding: 0 4px;
    z-index: 1;

    &:nth-child(2) {
        top: 29px;
    }

    &:nth-child(3) {
        top: 53px;
    }

    &:nth-child(4) {
        top: 77px;
    }

    &.sold {
        box-shadow: 0 1px 1px #c25f45;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c25f45+0,c25f45+28,c25f45+62,cc7d68+66,cd7d68+82,cd7d68+100 */
        background: #c25f45; /* Old browsers */
        background: -moz-linear-gradient(45deg, #c25f45 0%, #c25f45 28%, #c25f45 62%, #cc7d68 66%, #cd7d68 82%, #cd7d68 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #c25f45 0%, #c25f45 28%, #c25f45 62%, #cc7d68 66%, #cd7d68 82%, #cd7d68 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #c25f45 0%, #c25f45 28%, #c25f45 62%, #cc7d68 66%, #cd7d68 82%, #cd7d68 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c25f45', endColorstr='#cd7d68', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    }

    &.new {
        box-shadow: 0 1px 1px #61c27c;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#61c27c+0,6fc888+29,66c381+63,81cf98+66,7ecd96+82,7fcf96+100 */
        background: #61c27c; /* Old browsers */
        background: -moz-linear-gradient(45deg, #61c27c 0%, #6fc888 29%, #66c381 63%, #81cf98 66%, #7ecd96 82%, #7fcf96 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #61c27c 0%, #6fc888 29%, #66c381 63%, #81cf98 66%, #7ecd96 82%, #7fcf96 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #61c27c 0%, #6fc888 29%, #66c381 63%, #81cf98 66%, #7ecd96 82%, #7fcf96 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61c27c', endColorstr='#7fcf96', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    }

    &.pre-order {
        box-shadow: 0 1px 1px #eec836;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#61c27c+0,6fc888+29,66c381+63,81cf98+66,7ecd96+82,7fcf96+100 */
        background: #eec836; /* Old browsers */
        background: -moz-linear-gradient(45deg, #eec836 0%, #f1d155 29%, #f6da71 63%, #f6da71 66%, #f6da71 82%, #f6da71 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #eec836 0%, #f1d155 29%, #f6da71 63%, #f6da71 66%, #f6da71 82%, #f6da71 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #eec836 0%, #f1d155 29%, #f6da71 63%, #f6da71 66%, #f6da71 82%, #f6da71 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eec836', endColorstr='#f6da71', GradientType=1); /* IE6-9 fallback on horizontal gradient */

    }

    &.outlet {
        box-shadow: 0 1px 1px #ff550c;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff550c+0,fc6220+29,f95c18+63,ff6c2d+66,fc6d2a+82,ff6e30+100 */
        background: #ff550c; /* Old browsers */
        background: -moz-linear-gradient(45deg, #ff550c 0%, #fc6220 29%, #f95c18 63%, #ff6c2d 66%, #fc6d2a 82%, #ff6e30 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #ff550c 0%, #fc6220 29%, #f95c18 63%, #ff6c2d 66%, #fc6d2a 82%, #ff6e30 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #ff550c 0%, #fc6220 29%, #f95c18 63%, #ff6c2d 66%, #fc6d2a 82%, #ff6e30 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff550c', endColorstr='#ff6e30',GradientType=1); /* IE6-9 fallback on horizontal gradient */
    }

    &.soon {
        box-shadow: 0 1px 1px #64add1;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#64add1+0,64acd1+29,64add3+63,80bddb+66,80bddb+82,80bddb+100 */
        background: #64add1; /* Old browsers */
        background: -moz-linear-gradient(45deg, #64add1 0%, #64acd1 29%, #64add3 63%, #80bddb 66%, #80bddb 82%, #80bddb 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #64add1 0%, #64acd1 29%, #64add3 63%, #80bddb 66%, #80bddb 82%, #80bddb 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #64add1 0%, #64acd1 29%, #64add3 63%, #80bddb 66%, #80bddb 82%, #80bddb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#64add1', endColorstr='#80bddb', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    }

    &.in-order {
        box-shadow: 0 1px 1px #2FA8E1;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2FA8E1+0,2ca4dd+29,2b9bd0+63,62add0+66,62add0+82,62add0+100 */
        background: #2FA8E1; /* Old browsers */
        background: -moz-linear-gradient(45deg, #2FA8E1 0%, #2ca4dd 29%, #2b9bd0 63%, #62add0 66%, #62add0 82%, #62add0 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #2FA8E1 0%, #2ca4dd 29%, #2b9bd0 63%, #62add0 66%, #62add0 82%, #62add0 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #2FA8E1 0%, #2ca4dd 29%, #2b9bd0 63%, #62add0 66%, #62add0 82%, #62add0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2FA8E1', endColorstr='#80bddb', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    }

    &.sale {
        width: 36px;
        height: 36px;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e7110c+0,e20202+29,e50602+55,e54340+63,e04c4a+78,e55754+100 */
        background: #e7110c; /* Old browsers */
        background: -moz-linear-gradient(45deg, #e7110c 0%, #e20202 29%, #e50602 55%, #e54340 63%, #e04c4a 78%, #e55754 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #e7110c 0%, #e20202 29%, #e50602 55%, #e54340 63%, #e04c4a 78%, #e55754 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #e7110c 0%, #e20202 29%, #e50602 55%, #e54340 63%, #e04c4a 78%, #e55754 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e7110c', endColorstr='#e55754',GradientType=1); /* IE6-9 fallback on horizontal gradient */
        border-radius: 50%;
        text-align: center;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.64);
        font-weight: bold;
        font-family: calibri;
        font-size: 18px;
        line-height: 37px;

        &.with-percents {
            font-size: 14px;
            line-height: 40px;
            min-width: 40px;
            min-height: 40px;
        }
    }
}

body.is-guest {
    .catalog-grid .models-list .model-card {

        .model-price,
        .model-count .count-wrap .count-available,
        .model-count .count-wrap .count-delay,
        .product-badges {
            display: none;
        }
    }
}

body.is-guest, body.hide-prices {
    .products-popup .products-list {
        margin-top: 20px;

        .product-row.not-in-stock-available .available-box .unavailable,
        .product-row.not-in-stock-delay .delay-box .undelay,
        .product-row .price-box {
            display: none;
        }
    }
}

body.is-guest {
    .catalog-grid .models-list .model-card {
        padding-bottom: 10px;
        .model-retail-price {
            display: none;
        }
    }
    .products-popup .products-popup__legend-box {
        display: none;
    }
}

.showHideButtonAllPhoto {
    display: block;
    width: 100%;
    font-size: 12px;
    line-height: 25px;
    color: #df2324;
    &:hover {
        color: #df2324;
        text-decoration: underline;
    }
}

.desc_small_text {
    display: none;
}

.extended-info-my-storecounts {
    display: none;
    padding: 15px;
    font-size: 13px;

    &__row {
        white-space: nowrap;
        text-align: left;
        margin-bottom: 3px;

        > .dashed-link {
            display: inline-block;
            color: @primaryColor;
            border-bottom: 1px dashed @primaryColor;
            line-height: 15px;
            cursor: pointer;
        }

        > .one-element {
            display: inline-block;
            font-weight: bold;
        }

        > b {
            float: right;
            margin-left: 10px;
        }
    }
}

@media (max-width: 1080px) and (min-width: 1200px) {
    .desc_small_text {
        display: inline;
    }

    .desc_norm_text {
        display: none;
    }

    .available-box.none-available-box {
        min-width: 50px !important;
    }

    .delay-box.none-delay-box {
        min-width: 50px !important;
    }
}

@media (max-width: 1199px) {
    .products-popup {
        .close-popup {
            font-size: 50px;
            position: fixed;
            top: 50px;
        }
        .main-info {
            .model-name {
                top: -325px;
                max-height: 56px;
            }
            .model-description {
                top: -125px;
                margin-left: 260px;
            }
            .products-list {
                max-height: 450px;
                top: -95px;
            }
            .total-info {
                position: absolute;
                display: inline-block;
                width: 100%;
                bottom: 10px;
            }
            .honest_sign {
                top: 8px;
            }
        }
        &.is-promo-material {
            .model-description {
                top: -85px;
            }
        }
        .main-photo-wrapper {
            float: left;
        }
        .promo-material-wrapper {
            padding-left: 20px;
        }
        &__legend-box {
            top: -90px;
        }
        .products-popup__filter-wrapper {
            top: -75px;
        }
        .photos-wrapper{
            float: left;
            .additional-photos {
                max-height: 160px !important;
            }
            &.with-photo-tabs {
                .additional-photos {
                    max-height: 120px !important;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .product-badges.sale {
        font-family: SegoeUI,Verdana,sans-serif;
        &.with-percents {
            font-size: 13px;
            line-height: 38px;
        }
    }

    .popupWrapProduct {
        position: fixed !important;
        z-index: 1000;
        .fancybox-inner {
            height: calc(~'100vh - 48px') !important;
            min-height: 800px;
        }
    }

    .products-popup .products-list {
        .size-header,
        .product-row {
            font-size: 17px;
        }
    }
}

@media (max-width: 750px) {
    .products-popup {
        .main-info {
            .products-list {
                max-height: 300px;
            }
        }
    }
}
