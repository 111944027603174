html {
    display: flex;
    height: auto;
    min-height: 100%;
}

body {
    height: auto;
    position: relative;
    flex-grow: 1;
    padding-bottom: 60px;

    &.md-skin {
        font-family: "Open Sans", sans-serif;
        padding-top: 48px;
    }
}

.my-modal {
    width: 650px;
    min-height: 350px;
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    outline: 0 none;
}

.ibox-tools {
    float: right;
}

.top-button-side {
    display: none;
    z-index: 2;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: fade(@primaryColor, 6%);
    .transition(background-color, 0.3s, 0s);
    cursor: pointer;
    @media (min-width: 1287px) {
        display: block;
    }

    &:hover {
        background-color: fade(@primaryColor, 10%);

        & .top-button-side__rect {
            background-color: fade(@primaryColor, 45%);
        }
    }

    &__rect {
        position: absolute;
        left: 10px;
        bottom: 10px;
        width: 30px;
        height: 30px;
        background-color: fade(@primaryColor, 40%);
        .transition(background-color, 0.3s, 0s);
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
        color: white;
        padding-top: 3px;
    }
}

.import-window {
    .flex-display(flex);
    .flex-direction(column);

    .import-form-wrapper {
        .flex-grow(1);
        .flex-display(flex);

        .progress-box-wrapper {
            .flex-grow(1);
            .flex-display(flex);
            .flex-direction(column);

            .show-on-analysis {
                display: none;
            }

            .show-on-waiting {
                display: none;
            }

            .show-on-changing {
                display: none;
            }

            .show-on-finished {
                display: none;
            }

            &.analysis {
                .show-on-analysis {
                    display: block;
                }
            }

            &.waiting {
                .show-on-waiting {
                    display: block;
                }
            }

            &.changing {
                .show-on-changing {
                    display: block;
                }
            }

            &.finished {
                .show-on-finished {
                    display: block;
                }
            }

            .message-box {
                .flex-grow(1);
            }

            .progress-box-footer {
                padding: 15px;

                .info-text-analysis {
                    font-size: 11px;
                }

                .analysis-in-progress {
                    text-align: center;
                    padding: 10px 0 5px 0;
                    color: #1C84C6;
                    height: 36px;
                }
            }
        }
    }
}

.proceed-outlet {
    .full-list-link {
        margin: 7px 0 0 15px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.order-proceed-modal {
    width: 700px;

    .addresses-not-found {
        color: red;
        font-size: 12px;
        padding-top: 8px;
    }
}

.lh-21 {
    line-height: 21px;
}

.table-buttons {
    margin-bottom: 15px;
    min-height: 39px;
    @media (max-width: 900px) {
        min-height: 41px;
    }

    .margin-right {
        margin-right: 7px;
    }
}

.cal-month {
    font-size: 15px;
}

dl {
    margin-top: 10px;

    dt {
        float: left;
        width: 200px;
        line-height: 22px;
        font-weight: normal;
        color: #18886f;
    }

    dd {
        line-height: 22px;
        min-height: 22px;
        margin-left: 200px;
    }
}

.price-history-info {
    dl {
        dt {
            width: 120px;
        }

        dd {
            margin-left: 120px;
        }
    }
    &__right {
        dl {
            dt {
                width: 60px;
            }

            dd {
                margin-left: 60px;
            }
        }
    }
}

@media (min-width: 900px) {
    .newStatus {
        dl {
            dt {
                width: 140px;
            }
            dd {
                margin-left: 140px;
            }
        }
    }
}

img[title="Sticky Password"] {
    display: none !important;
}

.delete-company-button,
.delete-address-button {
    margin-left: 10px;
    cursor: pointer;
}

.delete-stores-button {
    margin-right: 10px;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 32px;
    right: 112px;
}

.store_delete_button:after,
.store_delete_button:before,
.manager_delete_button:after,
.manager_delete_button:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    background: #ccc;
}

.manager_delete_button:before,
.store_delete_button:before {
    top: 16px;
    right: 100px;
    width: 30px;
}

.manager_delete_button:after,
.store_delete_button:after {
    right: 123px;
    width: 13px;
    transform: rotate(90deg);
}

.store_delete_button:after {
    top: 22px;
}

.manager_delete_button:after {
    top: 9px;
}

.delete_store_rule_limit_label {
    margin-right: 0;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 4px;
    right: 190px;
}

.delete_store_rule_limit_support1:after,
.delete_store_rule_limit_support1:before,
.delete_store_rule_limit_support2:after,
.delete_store_rule_limit_support2:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    background: #ccc;
}

.delete_store_rule_limit_support2:before,
.delete_store_rule_limit_support1:before {
    right: -24px;
    width: 30px;
}

.delete_store_rule_limit_support2:after,
.delete_store_rule_limit_support1:after {
    right: -6px;
    width: 25px;
    transform: rotate(90deg);
}

.delete_store_rule_limit_support1:before {
    top: -36px;
}

.delete_store_rule_limit_support2:before {
    top: 62px;
}

.delete_store_rule_limit_support1:after {
    top: -24px;
}

.delete_store_rule_limit_support2:after {
    top: 50px;
}

.float-e-margins .btn {
    margin-bottom: 0;
}

.navbar-default {
    .nav > li.nav-header {
        a.btn {
            color: white;
            margin-top: 20px;
            padding: 6px 12px;

            &.btn-danger {
                &:hover, &:active, &:focus {
                    background-color: #ec4758;
                    border-color: #ec4758;
                    color: #FFFFFF;
                    font-weight: normal;
                }
            }

            &.btn-warning {
                &:hover, &:active, &:focus {
                    background-color: #f7a54a;
                    border-color: #f7a54a;
                    color: #FFFFFF;
                    font-weight: normal;
                }
            }

            .caret {
                display: inline-block;
            }
        }
    }
}

.manager-settings-form {
    .image-form-box {
        form.image-form {
            float: left;
            width: 100%;
            min-height: 130px;
            max-width: 500px;

            .placeholder {
                top: -36px;
            }
        }
    }

    .address-input {
        width: 100%;
        max-width: 500px;
    }
}

.loaded-image {
    display: inline-block;
    height: 110px;
    width: 140px;
    position: relative;
    vertical-align: top;
    padding-top: 25px;
    text-align: center;

    .file-creation-date {
        position: absolute;
        top: 4px;
        left: 0;
        width: 100%;
        text-align: center;
    }

    .thumb-image {
        max-width: 100%;
        max-height: 100%;
    }

    .delete-file {
        color: red;
        position: absolute;
        right: 7px;
        top: 5px;
        font-size: 14px;
        cursor: pointer;
    }
}

form.image-form {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background-color: white;
    border: 1px dashed #0087f7;
    box-sizing: border-box;
    .flex-display(flex);
    //flex-wrap: wrap;
    .flex-direction(column);

    &.mini {
        border-width: 1px;
    }

    &.filled {
        border-color: transparent;
    }

    &.multiple {
        min-height: 200px;
        padding: 10px;

        .image-box {
            position: relative;
            margin: 10px;

            img.uploaded_image {
                max-height: 160px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
            }
        }

        &.filled {
            border-color: #0087f7;

            &:hover, &.dragenter {
                border-color: #008700;
            }

            .placeholder {
                display: none;
            }
        }
    }

    &.small-form {
        height: 106px;
        width: auto;
        display: inline-block;
        padding: 5px 15px;
        border: 1px dashed #0087f7;
        margin: 3px 5px;
        vertical-align: top;

        .placeholder {
            font-size: 0.9rem;
        }
    }

    &.with-title {
        padding-top: 22px;

        .title {
            display: block;
            width: 100%;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            line-height: 26px;
        }
    }

    &[disabled] {
        .placeholder {
            display: none;
        }

        input[type=file] {
            display: none;
        }
    }

    .title {
        display: none;
    }

    .add-file-icon {
        color: #e0e0e0;
        font-size: 50px;
        position: absolute;
        left: 50%;
        top: 60%;
        margin: -25px;
    }

    .placeholder {
        text-align: center;
        .flex-display(flex);
        flex-direction: column;
        margin: auto;
        color: #ccc;
        font-size: 1.2rem;
        width: 100%;
    }

    input[type=submit] {
        display: none;
    }

    .icon {
        margin: auto;
        color: rgba(0, 135, 247, 0.19);
        font-size: 60px;
    }

    .image-bottom-panel {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
        line-height: 27px;
        padding-left: 15px;
        box-sizing: border-box;
        overflow: hidden;

        .image-close-button {
            position: absolute;
            right: 2px;
            bottom: 0;
            cursor: pointer;
            font-size: 23px;
        }
    }

    input[type=file] {
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        padding: 0;
    }

    .image-box {
        display: inline-block;
        position: absolute;
        z-index: 3;
        left: 0;
        top: 0;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        img.uploaded_image {
            background-color: white;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }

    &:hover:not([disabled]), &.dragenter {
        background-color: #f9fff9;
        border-color: #008700;

        .mask {
            background-color: #F5FFF8;

            .mask-inner {
                border-color: #5eb666;
                color: #5eb666;
            }
        }
    }

    &.error {
        .mask {
            background-color: #FFF5F6;

            .mask-inner {
                border-color: #FF7177;
                color: #FFB5B8;
            }
        }
    }

    .mask {
        background-color: #FbFbFb;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;

        .mask-inner {
            width: 100%;
            height: 100%;
            border: 1px dashed #9A9A9A;
            box-sizing: border-box;
            color: #9A9A9A;
            text-shadow: 0 1px 0 white;

            > span {
                text-align: center;
                width: 100%;
                padding: 10px;
                display: block;
                margin-top: 3px;
                box-sizing: border-box;
                font-size: 12px;
            }

            .icon {
                font-size: 40px;
                margin-left: -20px;
                display: block;
                position: absolute;
                margin-top: -21px;
                left: 50%;
                top: 65%;
            }
        }
    }
}

.my-spinner {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);

    &.active {
        display: block;
    }

    .sk-spinner-cube-grid.sk-spinner {
        margin: auto;
    }
}

.flexbox {
    .my-spinner.active {
        .flex-display(flex);
    }
}

.small--margined {
    margin-bottom: 8px;
}

.dp-col-header, .dp-day {
    font-size: 1.3em;
}

.dp-col-header {
    font-size: 1em;
}

.dp-current:not(.dp-selected):not(:hover) {
    background: none;
    color: #3B404D;
}

.dp-current.dp-day-disabled:not(.dp-selected):not(:hover) {
  background: transparent;
  color: #DDD;
}

.message-box {
    padding: 0 15px 15px;
}

table.table {
    tbody {
        tr {
            height: 33px;

            &.uncheck {
                background-color: #cbf3cb;
            }

            &.not-enough {
                background-color: #f7d1d1;
            }

            td {
                padding: 5px 8px;

                &.cell-numeric {
                    text-align: right;
                    font-family: 'Droid Sans Mono', monospace;
                    font-feature-settings: "tnum" 1, "lnum" 1;

                    .price-hide {
                        cursor: pointer;
                        font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

                        span {
                            border-bottom: 1px dashed #a0a0a0;
                            color: #a0a0a0;
                            font-size: 12px;
                            line-height: 14px;
                            display: inline-block;
                            position: relative;
                            min-width: 84px;
                        }
                    }
                }
            }
        }
    }

    thead {
        tr {
            th {
                padding: 5px 8px;
                font-size: 13px;

                .mobile {
                    display: none;
                }

                .desktop {
                    display: inline-block;
                }

                &.cell-numeric {
                    text-align: right;
                }
            }
        }
    }

    tfoot {
        tr {
            th {
                padding: 5px 8px;

                &.cell-numeric {
                    text-align: right;
                    font-family: 'Droid Sans Mono', monospace;
                    font-feature-settings: "tnum" 1, "lnum" 1;
                }
            }
        }
    }
}

.table .table-column-width-shrink {
    width: 0;
}

.table-bordered > thead > tr > th {
    background-color: #f9f9f9;
}

.status-history-dropdown {
    text-align: left;
    width: 210px;
    font-family: 'Open Sans';

    &__row {
        color: lightgrey;
        line-height: 18px;
        font-size: 12px;

        &_passed {
            color: grey;

            &.status-history-dropdown__row_current {
                color: black;
                font-weight: bold;
            }
        }
    }

    &__status-name {
        min-width: 100px;
        display: inline-block;
    }

    &__empty-message {
        display: block;
        line-height: 20px;
        padding-top: 10px;
    }
}

table.dataTable {
    thead {
        .sorting:after,
        .sorting_asc:after,
        .sorting_desc:after,
        .sorting_asc_disabled:after,
        .sorting_desc_disabled:after {
            bottom: inherit;
            top: 6px;
        }

        > tr {
            height: auto;

            > th {
                outline: none !important;

                &.filter {
                    &.sorting, &.sorting_asc, &.sorting_desc {
                        padding-right: 45px;
                    }

                    &.filter-active {
                        background-color: rgba(26, 179, 148, 0.08);

                        .show-filters-button {
                            color: @greenColor;
                        }

                        .yadcf-filter-wrapper {
                            .yadcf-filter-reset-button {
                                display: block;
                            }
                        }
                    }
                }

                .yadcf-filter-wrapper {
                    display: none;
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    width: 100%;
                    padding: 0 5px;

                    .yadcf-filter-wrapper-inner {
                        width: 111px;

                        .yadcf-filter-range-number-seperator {
                            &:before {
                                content: " - ";
                            }
                        }
                    }

                    .yadcf-filter-range {
                        width: 49px;
                        outline: none !important;
                        padding: 1px 5px;
                        border: 1px solid #e7e7e7;
                        height: 22px;
                        color: rgb(149, 149, 149);
                    }

                    .yadcf-filter {
                        width: 100%;
                        outline: none !important;
                        padding: 1px 5px;
                        border: 1px solid #e7e7e7;
                        height: 22px;
                        color: rgb(149, 149, 149);
                    }

                    select.yadcf-filter {
                        padding: 1px 15px 1px 5px;

                        + .yadcf-filter-reset-button {
                            right: 20px;
                        }
                    }

                    .yadcf-filter-reset-button {
                        display: none;
                        background: none;
                        border: 0;
                        box-shadow: none;
                        position: absolute;
                        right: 9px;
                        top: 2px;
                        font-size: 12px;
                        height: 18px;
                        width: 11px;
                        padding: 0;
                        font-weight: normal;
                        color: #d0d0d0;
                        outline: none !important;
                    }
                }

                .show-filters-button {
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    position: absolute;
                    top: 4px;
                    right: 22px;
                    opacity: 0.3;
                    font-size: 15px;
                }
            }
        }
    }

    tbody {
        td {
            vertical-align: middle;
            .systemName-th {
                max-width: 250px;
                word-wrap: break-word;
            }
        }
    }

    &.show-filters {
        > thead {
            > tr {
                height: 58px;

                > th {
                    .yadcf-filter-wrapper {
                        display: block;
                    }

                    .show-filters-button {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.button-search {
   font-size: 14px;
}

.grid-wrapper {
    font-family: SegoeUI, sans-serif;

    thead {
        th {
            color: #959595;
            font-weight: normal;
        }
    }

    .subgrid-row {
        tbody {
            tr {
                td {
                    line-height: 40px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                color: #333;
                border-top: 0;
            }
        }
    }
}

.invisible {
    visibility: hidden;
}

.actions-page {

}

.actions-wrapper {
    .flex-display(flex);
    .flex-wrap(wrap);
}

.action-card {
    min-height: 340px;
    margin-bottom: 20px;
    padding-bottom: 28px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24), 0 3px 5px -2px rgba(0, 0, 0, 0.27), 0 1px 9px 0 rgba(0, 0, 0, 0.22);
    }

    a {
        color: #333;
    }

    &_inactive {
        opacity: 0.5;
        border: 1px solid orange;
    }

    &__photo-wrapper {
        height: 210px;
        overflow: hidden;
    }

    &__photo {
        width: 100%;
    }

    &__header {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        padding: 0 15px;
    }

    &__short-description {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 15px;
        margin-top: 10px;

        a {
            text-decoration: underline;
        }
    }

    &__date {
        padding: 0 15px 10px;
        text-align: right;
        color: gray;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &__buttons {
        display: block;
        position: absolute;
        right: 5px;
        top: 5px;

        a {
            color: white;
        }

        .btn {
            line-height: 29px;
        }
    }

    & &__buttons {
        display: none;
    }

    &:hover &__buttons {
        display: block;
    }
}

.ibox {
    &.catalog-filters {
        margin-bottom: 10px;
        margin-top: -10px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 3px 3px 0 rgba(0, 0, 0, 0.12);
    }

    &.with-bottom-padding {
        padding-bottom: 20px;

        .ibox-content {
            padding-bottom: 0;
        }
    }

    &.hide-not-changed-button {
        > .ibox-title {
            .btn {
                display: none;
            }
        }

        &.changed {
            > .ibox-title {
                .btn {
                    display: inline;
                }
            }
        }
    }

    .ibox-title {
        background-color: #F9F8F8;

        h5 + .js-save-form .btn,
        h5 > .btn {
            left: 0;
            top: -1px;
            margin-left: 15px;
        }
    }

    .ibox-content {
        background-color: white;
    }
}

.columns-chooser {
    &__wrap {
        width: 100%;
        overflow-x: auto;
        position: relative;
    }
    &__table {
        border-collapse: collapse;
        width: 100%;

        td, th {
            border: 1px solid #eee;
            font-size: 11px;
            padding: 4px 2px;
            max-width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        thead {
            background-color: #E6E6E6;

            th {
                text-align: center;
                font-size: 13px;
            }
        }

        select {
            width: 100%;
            border: 1px solid #ccc;
            font-weight: normal;
            font-size: 11px;
            opacity: 0.7;
        }
    }

    &__info {
        padding: 10px 15px;

        .btn {
            display: block;
            margin-top: 10px;
        }
    }

    .is-selected {
        background-color: rgba(0, 187, 0, 0.26);

        select {
            opacity: 1;
        }
    }
}

.catalog-filters {
    .brand-select-wrapper {
        .optWrapper {
            min-width: 160px;
        }
    }

    .type-select-wrapper {
        .optWrapper {
            min-width: 230px;
        }
    }

    .model-select-wrapper {
        .optWrapper {
            min-width: 170px;
        }
    }

    .size-select-wrapper {
        .optWrapper {
            min-width: 100px;
        }
    }

    .color-select-wrapper {
        .optWrapper {
            min-width: 140px;
        }
    }

    .brands-filter {
        .SumoSelect > .optWrapper.multiple > .options .opt {
            &:hover, &.sel {
                background-color: #ffe5e5;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                background-size: contain;
                top: 2px;
                left: 58px;
                width: 101px;
                height: 31px;
                z-index: 2;
            }

            &.conte-elegant {
                &:before {
                    background: url(../img/logo-conte-elegant.png) no-repeat;
                    margin-left: -4px;
                }
            }

            &.conte-kids {
                &:before {
                    background: url(../img/logo-conte-kids.png) no-repeat;
                }
            }

            &.diwari {
                &:before {
                    background: url(../img/logo-diwari.png) no-repeat;
                }
            }

            span {
                left: 0;
                margin-left: 0;
            }

            label {
                visibility: hidden;
            }
        }
    }

    .form-group .input-group {
        display: block;
    }
}

.form-group {
    .form-text-in-value-box {
        padding-top: 7px;
        height: 34px;
    }

    .input-group {
        .reload-table {
            position: absolute;
            right: -13px;
            bottom: 0;
            display: none;
            z-index: 11;
            width: 14px;
            @media (max-width: 767px) {
                left: -24px;
                right: 0;
            }

            button {
                padding: 40% !important;
                @media (max-width: 900px) {
                    padding: 29% !important;
                }
                @media (max-width: 767px) {
                    border-radius: 4px 0 0 4px;
                    padding: 31% !important;
                }
            }
        }

        .SumoSelect {
            width: 100%;

            &.open {
                .SelectBox {
                    border: 1px solid #aaa;
                }
            }

            select {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
                outline: none !important;
            }

            .SelectBox {
                z-index: 2;
                height: 34px;
                padding: 4px 12px;
                display: block;
                line-height: 24px;
                box-shadow: none !important;
                border: 1px solid #cbd5dd;

                .placeholder {
                    color: #999;
                    font-style: normal;
                }
            }

            .optWrapper {
                border: 1px solid #aaa;
                margin-top: -1px;
                box-shadow: none;

                .opt {
                    &:hover, &.sel {
                        background-color: @primaryColorLight;
                        color: white;
                    }

                    label {
                        margin: 3px 0;
                    }
                }

                .options {
                    min-height: 160px;
                    max-height: 280px;
                    padding-bottom: 50px;
                }
            }
        }
    }
}

table.catalog-table {
    border-left: 0;
    margin-top: -2px !important;

    .expanded {
        .expandButton-th {
            .fa-angle-right {
                display: none;
            }

            .fa-angle-down {
                display: inline-block;
            }
        }
    }

    tbody {
        tr {
            &.in-order {
                .photos-wrapper::before {
                    background-color: #2FA8E1;
                }
            }

            &.new {
                .photos-wrapper::before {
                    background-color: #61c27c;
                }
            }

            &.sale {
                .photos-wrapper::before {
                    background-color: #e7110c;
                }
            }

            &.soon {
                .photos-wrapper::before {
                    background-color: #64add1;
                }
            }

            &.expand-subgrid {
                cursor: pointer;
            }

            .photos-wrapper::before {
                content: "";
                width: 8px;
                height: 8px;
                position: absolute;
                top: -4px;
                left: -4px;
                border-radius: 50%;
                display: block;
                background-color: transparent;
            }

            td {
                line-height: 20px;
                padding: 5px 8px;

                &.images-th {
                    font-size: 16px;
                    padding-top: 5px;

                    .fa {
                        color: @greenColor;
                    }

                    .no-photo {
                        height: 60px;
                        width: 45px;
                        margin: auto;

                        .fa {
                            font-size: 20px;
                            color: #dfdfdf;
                            text-shadow: none;
                        }
                    }
                }

                &.color-th {
                    img {
                        height: 25px;
                        width: 25px;
                        margin-right: 5px;
                        display: inline-block;
                        overflow: hidden;
                    }

                    .without-icon {
                        margin-left: 30px;
                    }
                }

                &.available-th {
                    .count_box {
                        display: flex;
                        padding-right: 15px;
                        justify-content: center;

                        .available-indicator,
                        .delay-indicator {
                            justify-content: center;
                            display: flex;
                            height: 100%;
                            vertical-align: middle;
                            top: 4px;
                        }
                    }

                    .available-box.just-text {
                        white-space: nowrap;
                        padding-right: 0;
                    }

                    .delay-box.just-text {
                        white-space: nowrap;
                        padding-right: 0;
                    }
                }

                &.delay-th {
                    .delay-box.just-text {
                        white-space: nowrap;
                        padding-right: 0;
                    }
                }

                &.expandButton-th {
                    text-align: center;
                    font-size: 16px;
                    cursor: pointer;
                    color: green;

                    .fa-angle-down {
                        display: none;
                    }
                }

                &.expandProducts-th {
                    position: relative;

                    .sk-spinner {
                        margin: 0;
                        top: 2px;
                    }
                }
            }
        }

        td > .count-selector {
            line-height: 20px;
            padding-top: 10px;
        }

        .model-th {
            max-width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            &.is_honest_sign {
                position: relative;
                padding-right: 28px;
            }
        }

        .honest_sign {
            background-size: contain;
            background-image: url('../img/chz-logo-small.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            right: 2px;
            transform: translateY(-50%);
        }
    }

    .to-order-button {
        color: #1eb732;
        font-size: 15px;
    }

    .remove-from-order-button {
        color: #de0000;
        font-size: 13px;
    }

    .subgrid-row {
        display: none;
        background-color: white;

        &:hover {
            background-color: white;
        }

        .subgrid-cell {
            padding: 0;
            width: 27px;
        }

        td {
            padding: 0 8px;
        }

        .subgrid-data {
            padding: 0;
            border-top: 1px solid #ebebeb;
            border-bottom: 1px solid #ebebeb;

            .subgrid-container {
                float: left;
                min-width: 40%;
                margin-left: 31px;

                .row {
                    margin: 0;

                    .col-sm-12 {
                        padding: 0;
                    }
                }
            }
        }

        &.hiddenPrice {
            .price-th,
            .available-th,
            .delay-th,
            .count-th {
                //display: none;
            }
        }
    }

    &.cart-table {
        margin: 0 !important;
        border-top: 0;
    }
}

table.catalog-table-with-product {
    &.table-bordered.dataTable {
        tbody {
            td {
                border-bottom-width: 1px;
            }
        }
    }
    thead {
        .sorting_asc,
        .sorting_desc {
            cursor: initial;
            &:after {
                display: none;
            }
        }
    }
    tbody {
        tr {
            &[data-type="model"] {
                font-weight: bold;
            }
            &[data-type="product"] {
                td {
                    &:not(.images-th) {
                        background: #f5f5f5;
                    }
                    &.images-th {
                        background: #fff;
                    }
                    .open-photos {
                        margin: auto;
                        width: 150px;
                        height: auto;
                    }
                }
            }
        }
    }
}

.open-photos {
    height: 60px;
    text-align: center;
    width: 50px;
    display: none;
    overflow: visible;

    &:last-child {
        display: block;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &:hover {
        .hovered-image {
            position: fixed;
            top: 50%;
            left: 50%;
            height: auto;
            max-width: 400px;
            max-height: 535px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
            opacity: 1;

            /* Transition */
            -webkit-transition: opacity 200ms ease 100ms; /* For Safari 3.1 to 6.0 */
            transition: opacity 200ms ease 100ms;

            /* Transform */
            -webkit-transform: translate(-50%, -50%); /* For iOS and old Android */
            -ms-transform: translate(-50%, -50%); /* For IE 9 */
            transform: translate(-50%, -50%);

            &.preorder-image {
                max-height: min(900px, 90%);
                max-width: 800px;
            }
        }
    }

    .hovered-image {
        display: block;
        position: absolute;
        z-index: 999;
        left: 0;
        bottom: 0;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        opacity: 0;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
    }
}

.check-user {
    &__button {
        display: inline-block;
        border-bottom: 1px dashed #de0000;
        color: #de0000;
        line-height: 15px;

        &:hover {
            color: #de0000;
        }
    }
}

.images-switcher {
    height: 35px;
    cursor: pointer;

    &:hover {
        .images-switcher__text {
            border-bottom: 1px dashed transparent;
        }
    }

    &[data-state="true"] {
        .images-switcher__icon {
            stroke: #aaa;
        }

        .images-switcher__text-for-hide {
            display: none;
        }

        .images-switcher__text-for-show {
            display: inline-block;
        }
    }

    &__icon {
        stroke: @primaryColor;
    }

    &__text {
        color: @primaryColor;
        border-bottom: 1px dashed @primaryColor;
        vertical-align: top;
        line-height: 14px;
        margin: 5px 0 0 10px;

        &:hover {
            color: @primaryColor;
        }
    }

    &__text-for-hide {
        display: inline-block;
    }

    &__text-for-show {
        display: none;
    }
}

.order-editing {
    background-color: #d0e6f3;

    .fa-exclamation {
        font-size: 17px;
        margin-left: 5px;
        top: 1px;
        position: relative;
        color: #1c84c6;
        cursor: help;
    }
}

.order-edit-wrapper {
    .content {
        min-height: 100px;
    }
}

.clients-list-wrapper {
    .ibox-content {
        padding: 0;

        .table {
            margin: 0;
        }
    }
}

.order-comment {
    &__label {
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 4px;
    }

    &__text {
        padding: 3px 10px 5px;
        border: 1px solid #e7eaec;
        word-break: break-word;
    }
}

.model-photo-magnifier {
    margin-top: -28px;
    margin-left: -7px;
    border: 1px solid #9c9c9c !important;
    background: #fff;
    @media (min-width: 1024px) {
        margin-left: 10px;
    }
    &.product-page {
        margin-top: -1px;
    }
}

.cart-parent {
    padding: 0;

    .empty-text {
        display: none;
    }

    .grid-wrapper {
        display: block;
    }

    .markup-box,
    .discount-box {
        display: block;
    }

    &.empty {
        padding: 15px;

        .empty-text {
            display: block;
        }

        .grid-wrapper {
            display: none;
        }

        .markup-box,
        .discount-box {
            display: none;
        }

        + .panel-footer {
            display: none;
        }
    }
}

.panel-footer {
    &.with-text {
        > * {
            float: right;
            margin-left: 15px;
        }

        .clear {
            float: none;
        }
    }

    .info-text {
        padding-right: 20px;
        line-height: 16px;
        color: #a7a7a7;
        font-size: 12px;
    }
}

.markup-box,
.discount-box {
    padding: 15px;
    font-size: 17px;
    text-align: right;

    .markups-value-box,
    .discounts-value-box {
        font-size: 0.8em;

        .value-box {
            .value {
                font-size: 17px;
                color: black;
            }
        }
    }

    .value-box {
        display: inline-block;
        min-width: 150px;

        .currency {
            min-width: 37px;
            display: inline-block;
        }

        .value {
            font-size: 20px;
            color: #de0000;
            font-weight: bold;
            margin-left: 15px;
        }
    }
}

.arrow-sended-to-order {
    display: none;
    position: fixed;
    right: 30px;
    bottom: 40px;
    font-size: 60px;
    color: #00e000;
    padding: 10px;
}

.panel {
    &.without-borders-radius {
        border-radius: 0;

        .panel-heading {
            border-radius: 0;
        }
    }
}

.manager-order {
    .go-to-edit-button {
        left: 200px;
        top: 12px;
    }
}

.orders-filters {
    .ibox-title {
        display: flex;
        justify-content: space-between;

        .reset-filters-button {
            position: absolute;
            display: none;
            padding: 6px;
            right: 30px;
            font-size: 14px;
            cursor: pointer;
            top: 7px;
            border-color: transparent;
        }

        .reset-filters-button:hover {
            border-color: #E86565;
            color: #E86565;
            background: transparent;
        }

        h5 {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    .input-group {
        width: 100%;
        height: 34px;
        max-width: 100%;
        display: flex;

        .input-group-btn {
            .btn {
                margin: 0;
            }
        }

        .input-group-addon {
            min-width: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .chosen-container {
            display: table-cell;
            width: 100% !important;
        }

        &.check-users-filter {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            flex-wrap: wrap;

            label {
                cursor: pointer;
            }
        }
    }

    .error_block {
        font-size: 14px;
        color: #de0000;
        font-weight: bold;
        margin-right: 15px;
        display: inline-block;

        .error_item {
            display: none;

            &.active {
                display: inline-block;
            }
        }
    }

    .select2-container {
        width: 100% !important;
    }

    .order-filters {
        position: relative;

        &.active &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background: rgba(255, 255, 255, 0.4);
        }

        &__filters {
            position: relative;
        }

        &__buttons .btn {
            margin-bottom: 10px;
        }
    }

    .filters_tags {
        display: none;
        flex-grow: 1;
        align-items: baseline;
        flex-wrap: wrap;
        margin-top: -3px;
        .filter_tags_item {
            display: flex;
            align-items: baseline;
            padding: 5px 8px;
            background-color: #e0e7ea;
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: normal;
            .close-icon {
                color: #a7a7a7;
                font-size: 12px;
                &:hover {
                    color: #5E5E5E;
                }
            }
        }
    }

    &.border-bottom {
        .filters_tags {
            display: flex;
            margin-left: 20px;
            margin-right: 150px;
        }
        .ibox-title .reset-filters-button.reset-button-show {
            display: inline-block;
        }
    }

    .border-bottom {
        margin: 0 13px 0 13px;
    }

    @media (min-width: 768px) {
        .col-sm-3 {
            width: 20%;
        }
    }
}

.download-orders-list {
    float: right;
    position: relative;
    margin-right: 5px;
    bottom: 7px;
    top: -8px;
    min-width: 197px;
    width: max-content;

    button {
        height: 34px;
        position: relative;
        background-color: #1D84C6;
        border-color: #1D84C6;
        border-radius: 4px;
        padding: 0 !important;
        min-width: 197px;
        text-align: left;
        &[disabled] {
            .dropdown-arrow {
                display: none;
            }
        }
    }
    .ladda-button {
        .ladda-label {
            position: initial;
        }
        &[data-style=expand-right] {
            .ladda-spinner {
                right: -14px;
            }
        }
    }

    .download-orders-text {
        border-right: 1px solid #fff;
        font-size: 13px;
        background: url(../img/icon-xls.svg) transparent no-repeat 0 9px;
        background-size: 18px;
        left: 0;
        line-height: 34px;
        margin-left: 15px;
        margin-right: 37px;
        padding-left: 26px;
        padding-right: 18px;
        position: relative;
        top: -1px;
        display: block;
    }

    .dropdown-arrow {
        width: 0;
        height: 0;
        position: absolute;
        right: 14px;
        top: 42%;

        &.dropdown-arrow-up {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 7px solid #fff;
            display: none;
        }

        &.dropdown-arrow-down {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 7px solid #fff;
        }
    }

    .download-orders-dropdown {
        display: none;
        color: #333;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 11px;
        text-align: left;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #1D84C6;
        border-radius: 4px;
        list-style: none outside none;
        text-shadow: none;
        box-shadow: none;
        li > a {
            line-height: 20px;
            padding-left: 6px;
            padding-right: 6px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &.active {
        .dropdown-arrow {
            &.dropdown-arrow-up {
                display: block;
            }

            &.dropdown-arrow-down {
                display: none;
            }
        }
        .download-orders-dropdown {
            display: block;
        }
    }
}

body.stop-scrolling {
    padding-right: 17px;
}

.sweet-alert {
    &.wide-600 {
        max-width: 600px;
        width: auto;

        p {
            font-size: 15px;

            &.small {
                font-size: 13px;
            }
        }
    }

    &.text-left {
        p {
            text-align: left;
        }
    }
}

.md-skin .nav .open > a, .md-skin .nav .open > a:hover, .md-skin .nav .open > a:focus {
    background: transparent;
}

@notificationNewColor: #D1F0EA;
.md-skin .navbar-top-links .dropdown.notifications-dropdown {
    .dropdown-menu .for-empty {
        display: none;
    }

    &.empty {
        .dropdown-menu .for-empty {
            display: block;
        }
    }

    .dropdown-menu {
        left: initial;
        width: 325px;
        right: -44px;

        li a {
            padding: 3px 7px;
        }

        @media (min-width: 500px) {
            width: 390px;
            right: 0;
            li a {
                padding: 3px 20px;
            }
        }
    }

    .notification {
        &.new {
            background-color: @notificationNewColor;

            &:hover {
                background-color: #b7ded6;

                > a:hover {
                    background-color: transparent;
                }
            }
        }
    }
}

.navbar-fixed-top {
    min-width: 320px;
}

.navbar-top-links {
    &.navbar-left {
        float: left;
    }

    &.navbar-right {
        float: right;
    }

    &.info-box {
        b {
            color: @primaryColorLight;
        }

        .order-info {
            margin-right: 10px;
        }

        .for-small-screen {
            display: block;
            padding: 10px 0;

            .order-info {
                display: block;
            }
        }

        .for-medium-screen {
            display: none;
            padding: 10px;

            .order-info {
                display: block;
            }
        }

        .for-wide-screen {
            display: none;
        }

        @media (min-width: 500px) {
            .for-small-screen {
                display: none;
            }

            .for-medium-screen {
                display: block;
            }

            .for-wide-screen {
                display: none;
            }
        }
        @media (min-width: 1140px) {
            .for-small-screen {
                display: none;
            }

            .for-medium-screen {
                display: none;
            }

            .for-wide-screen {
                display: block;
            }
        }
    }

    .notification-label {
        display: none;
    }

    .logout-label {
        display: none;
    }

    @media (min-width: 940px) {
        .notification-label {
            display: inline;
        }

        .logout-label {
            display: inline;
        }
    }
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.notification-popup {
    padding: 15px 15px 45px;
    width: 700px;
    min-height: 200px;

    .btn {
        float: right;
        margin-right: 10px;
    }

    .vertical-date {
        color: #1A7BB9;
    }
}

.feed-element {
    margin: 0;
    padding: 15px 10px;
    display: block;
    color: #676a6c;
}

.user-notifications,
.notifications-list {
    .hidden-on-list {
        display: none;
    }

    .notification {
        &.new {
            cursor: pointer;

            &.feed-element {
                background-color: @notificationNewColor;
                &:hover {
                    background-color: #b7ded6 !important;
                }
            }

            .vertical-timeline-content {
                background-color: @notificationNewColor;

                &::before {
                    border-right-color: @notificationNewColor;
                }
            }
        }
    }
}

.shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.branded-catalogs-list {
    .flex-display(flex);
    .flex-wrap(wrap);
    .justify-content(center);

    .branded-catalog {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
        margin-bottom: 20px;
        padding: 15px;
        background-color: white;
        width: 180px;
        min-height: 276px;
        position: relative;
        overflow: hidden;

        &.add-button {
            text-align: center;

            &:hover {
                background-color: #fafafa;
            }

            .fa {
                width: 40px;
                height: 40px;
                position: absolute;
                left: 50%;
                margin-left: -20px;
                top: 100px;
                font-size: 40px;
                text-shadow: 0 1px 0 #ccc;
                color: #aaa;
            }

            .placeholder {
                color: #888;
                line-height: 130px;
                font-size: 16px;
            }
        }

        &.editable {
            &:hover {
                .mask {
                    top: 0;
                    opacity: 1;
                }
            }
        }

        &.is-hidden {
            opacity: 0.6;
            background-color: #f7f7f7;

            .mask {
                .show-button {
                    display: block;
                }

                .hide-button {
                    display: none;
                }
            }
        }

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            background-color: rgba(255, 255, 255, 0.8);
            text-align: center;
            padding-top: 50px;
            .transition(opacity, 400ms, 10ms);
            top: -100%;
            opacity: 0;

            .btn {
                display: block;
                margin: 0 auto 10px;
            }

            .show-button {
                display: none;
            }

            .hide-button {
                display: block;
            }
        }

        .thumb {
            height: 200px;
            margin-bottom: 10px;
            text-align: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .name {
            text-align: center;
        }
    }
}

.slider-images-list {
    .slider-image {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
        margin-bottom: 20px;
        padding: 15px;
        width: 180px;
        min-height: 276px;
        position: relative;
        overflow: hidden;

        .card {
            .card-img {
                height: 200px;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
    }
}

.create-branded-catalog, .create-slider-image {
    .import-file-box {
        display: block;
        text-align: center;
    }

    .thumb-box {
        display: none;
        text-align: center;

        &.load-new {
            .thumb-form {
                display: block;
            }

            .image-box {
                display: none;
            }
        }

        .thumb-form {
            display: none;
        }

        .thumb-info {
            text-align: justify;
            margin-bottom: 10px;

            .small {
                line-height: 14px;
                display: block;
            }
        }

        .remove-button {
            color: rgb(255, 116, 116);
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 26px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
        }
    }

    .file-loaded {
        .import-file-box {
            display: none;
        }

        .thumb-box {
            display: block;
        }
    }
}

.user-status {
    &.waiting_checking {
        color: red;
    }

    &.waiting_verifying {
        color: orange;
    }

    &.verified {
        color: green;
    }
}

.catalog-top-controls {
    background-color: white;
    min-height: 43px;
    padding: 9px 6px 6px 17px;
    margin-top: -22px;
    margin-bottom: 10px;

    .loading {
        display: none;
        width: 50px;
        height: 30px;
        float: right;
    }

    .button-view-change {
        float: right;
        padding: 0 15px;
        font-size: 24px;
        opacity: 0.7;
        cursor: pointer;

        &:hover {
            color: @primaryColorLight;
        }

        &.active {
            opacity: 1;
            color: @primaryColor;
            cursor: default;
        }
    }

    .page-length-box {
        float: left;
        white-space: nowrap;
        margin-right: 40px;
        margin-bottom: 10px;

        .catalog-length-select {
            display: inline;
            width: 75px;
            border: none;
        }
    }

    .chosen-container {
        margin: 0 12px;
    }

    .main-order-box {
        float: left;
        white-space: nowrap;

        .catalog-order-select {
            display: inline;
            width: 180px;
        }

        .sort-direction-box {
            display: inline-block;
            vertical-align: middle;

            &.asc {
                .asc {
                    display: block;
                }

                .desc {
                    display: none;
                }
            }

            .fa {
                font-size: 18px;
                vertical-align: middle;
                cursor: pointer;
                display: none;
            }

            .desc {
                display: block;
            }
        }
    }
}

.catalog-page-wrapper {
    padding-top: 112px;

    .grid-start-page {
        display: none;
    }

    .hide-on-start-page {
        display: block;
    }

    &.with-start-page {
        .grid-start-page {
            display: block;
        }

        .hide-on-start-page {
            display: none;
        }
    }

    &-search {
        padding-top: 112px;
    }
}

.catalog-page-category-banners {
    .catalog-title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 100;
    }

    .categories-banners {
        list-style: none;
        margin: 0;
        padding: 0;

        .category-banner {
            margin-bottom: 20px;
            height: 237px;
            position: relative;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#666666+0,ffffff+70,ffffff+100 */
            background: #666666; /* Old browsers */
            background: -moz-linear-gradient(left,  #666666 0%, #ffffff 80%, #ffffff 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #666666 0%,#ffffff 80%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #666666 0%,#ffffff 80%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            background-repeat: no-repeat;
            background-size: 100% 100%;
            opacity: 1;
            .transition(opacity, 0.3s, 0s);

            .category-banner-link {
                text-transform: none;
                display: flex;
                height: 100%;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0 114px;

                .category-banner-name {
                    font-size: 40px;
                    color: #fff;
                    margin-bottom: 20px;
                    line-height: 45px;
                    text-transform: uppercase;
                    font-family: 'Forum', sans-serif;
                    max-height: 130px;
                    overflow: hidden;
                }

                .category-banner-button {
                    color: @text-color;
                    background: #fff;
                    width: 155px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 50px;
                    text-align: center;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    opacity: 1;
                    .transition(opacity, 0.3s, 0s);
                }
            }

            .edit-banner-category {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 22px;
                height: 22px;
                font-size: 18px;
                text-align: center;
                display: none;
                color: #e02729;

                &:hover {
                    color: #ba2828;
                }
            }

            &:hover {
                opacity: 0.9;

                .category-banner-link {
                    .category-banner-button {
                        box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.5);
                    }
                }

                .edit-banner-category {
                    display: block;
                }
            }
        }
    }
}

.grid-start-page {
    min-height: 510px;
    height: auto;
    padding-bottom: 50px;

    h2 {
        text-align: center;
        margin-top: 0;
    }

    .handled-categories {
        .flex-display(flex);
        .flex-wrap(wrap);
        .justify-content(center);

        &.waiting {
            .handled-category {
                opacity: 1;
            }
        }

        &.first-open {
            .handled-category {
                &:before {
                    .transition(height, 600ms, 0ms);
                }
            }
        }

        .handled-category {
            width: 140px;
            height: 175px;
            display: inline-block;
            padding: 15px;
            background-color: white;
            margin: 7px;
            vertical-align: top;
            text-align: center;
            cursor: pointer;
            opacity: 0.9;
            border: 1px solid transparent;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 100%;
                height: 0;
                width: 1px;
                background-color: @primaryColorLight;
            }

            &.active {
                opacity: 1;
                border-color: @primaryColorLight;

                &:before {
                    height: 28px;
                }
            }

            .photo-wrapper {
                width: 100%;
                height: 110px;

                .category-photo {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .category-name {
                margin-top: 12px;
                font-size: 16px;
            }
        }
    }

    .types-wrapper {
        display: none;

        .separator {
            width: 100%;
            background: @primaryColorLight;
            height: 1px;
            margin: 20px 0;
        }

        .category-types-wrapper {
            display: none;

            &.active {
                .flex-display(flex);
                .flex-wrap(wrap);
                .justify-content(center);
            }

            .category-type {
                width: 150px;
                height: 173px;
                display: inline-block;
                padding: 15px;
                background-color: white;
                margin: 7px;
                vertical-align: top;
                text-align: center;
                cursor: pointer;

                .photo-wrapper {
                    width: 100%;
                    height: 110px;

                    .type-photo {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .type-name {
                    margin-top: 5px;
                    font-size: 13px;
                    height: 36px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.catalog-grid {
    min-height: 150px;
    margin-bottom: 30px;

    .loading {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 3;
    }

    .models-list {
        .flex-display(flex);
        .flex-wrap(wrap);
        .justify-content(flex-start);
        min-height: 337px;

        .model-card {
            width: 230px;
            display: flex;
            padding: 0 8px;
            background-color: white;
            margin: 7px;
            vertical-align: top;
            border: 1px solid #E6E6E6;
            cursor: pointer;
            flex-direction: column;
            justify-content: space-between;

            .model-photo-wrapper {
                height: 318px;
                margin: 0 -8px;
                width: calc(100% + 16px);
                overflow: hidden;
                text-align: center;

                .photo-wrapper {
                    display: none;

                    &:first-child {
                        display: block;
                    }
                }

                .model-main-photo {
                    max-width: 100%;
                    max-height: 100%;
                    &.hover {
                        display: none;
                    }
                }

                .count-photos {
                    position: absolute;
                    width: 100%;
                    height: 15px;
                    bottom: 3px;
                    left: 0;
                    overflow: hidden;

                    .photo-dot {
                        border-radius: 50%;
                        width: 5px;
                        height: 5px;
                        background-color: @primaryColorLight;
                        display: inline-block;
                        margin: 3px;
                    }
                }

                .color-name {
                  position: absolute;
                  bottom: 0;
                  padding: 5px 0;
                  font-size: 14px;
                  color: black;
                  font-weight: 600;
                  height: 30px;
                  width: 100%;
                  -webkit-backdrop-filter: blur(10px);
                  backdrop-filter: blur(10px);
                  background-color: rgba(230, 230, 230, 0.4);
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                &.hover:hover {
                    .model-main-photo {
                        display: none;
                        &.hover {
                            display: inline-block;
                        }
                    }
                }
            }

            .model-name {
                font-weight: bold;
                color: black;
                text-overflow: ellipsis;
                font-family: "Open Sans", sans-serif;
                line-height: 15px;
                font-size: 13px;
                white-space: normal;
                height: 60px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 15px;
            }

            .model-sku {
                overflow: hidden;
                margin-top: 2px;
                text-overflow: ellipsis;
                color: rgb(88, 88, 88);
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                font-size: 10px;
                min-height: 20px;
                white-space: nowrap;
                padding-left: 5px;
                padding-top: 5px;
            }

            .model-pattern {
                float: right;
                margin-top: 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 50%;
                white-space: nowrap;
                font-weight: 400;
                font-size: 10px;
                min-height: 17px;
                padding-top: 5px;
                padding-right: 5px;
                color: rgb(88, 88, 88);
            }

            .model-price {
                margin-top: 5px;
                font-size: 12px;
                color: @primaryColorText;
                padding: 2px 5px 0 5px;

                .price-wrap-width-discount {
                    position: relative;
                }

                .price-value {
                    font-weight: bold;
                }

                &.oldPrice {
                    margin-top: 19px;
                    font-size: 15px;
                }

                .price-oldValue {
                    color: rgb(185, 122, 122);
                    font-size: 10px;
                    text-decoration: line-through;
                    position: absolute;
                    top: 0;
                }
            }

            .model-price-wrap {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                height: 26px;
            }

            .model-count {
                padding: 0 5px 10px;
                display: flex;
                justify-content: space-between;
                .count-wrap {
                    display: flex;
                    margin-top: 10px;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 5px 10px;
                    font-size: 12px;
                    font-weight: 600;
                    span {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 3px;
                    }
                    .count-available {
                        color: #1ca858;
                    }
                    .count-delay {
                        color: #1e526a;
                        svg {
                            width: 16px;
                        }
                    }
                    .count-zero {
                        color: #dadada;
                        path {
                            fill: #dadada;
                        }
                    }
                }
            }

            .model-retail-price {
                position: relative;
                margin-top: 5px;
                padding: 2px 5px 0 5px;

                .price-retail-wrap {
                    font-size: 9px;
                    text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 20px;

                    .price-retail-label {
                        display: inline;
                        width: 60%;
                        line-height: 10px;
                    }

                    .price-wrap {
                        color: #5f5f5f;
                        font-size: 10px;
                        width: 100%;
                        flex-basis: max-content;
                        white-space: nowrap;
                        line-height: 10px;

                        .price-value {
                            font-weight: bold;
                            font-size: 11px;
                        }
                    }
                }
            }

            .model-close-button {
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                color: red;
                font-size: 22px;
                font-weight: 500;
                line-height: 20px;
                width: 20px;
                height: 20px;
                text-align: center;
                cursor: pointer;
                z-index: 9;
            }

            &:hover {
                .model-close-button {
                    display: block;
                }
            }

            &.waiting-for-request {
                .loading {
                    display: block;
                }
            }

            &.hiddenPrice {
                .model-price {
                    display: none;
                }
            }

            .honest_sign {
                background-size: contain;
                background-image: url('../img/chz-logo.svg');
                background-repeat: no-repeat;
                background-position: center;
                width: 59px;
                height: 26px;
                position: relative;
                top: 5px;
            }

            .loading {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.7);
                display: none;
                z-index: 2;
            }
        }
    }
}

.currency-wrapper {
    font-size: 10px;
}

.price-progress {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 112;
    background-color: rgba(0, 0, 0, 0.2);

    &.shown {
        .flex-display(flex);
    }

    &__content {
        margin: auto;
        width: 500px;
        min-height: 100px;
        padding: 20px;
        text-align: center;
        background-color: white;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
    }

    &__header {
        font-size: 16px;
        color: #555;
        margin-bottom: 6px;
    }

    &__current-status {
        font-size: 14px;
        color: #888;
        margin-bottom: 12px;
    }

    &__progress {
        margin-bottom: 10px;
    }
}

.background-error {
    background-color: fade(@primaryColor, 20%);
}

.background-warning {
    background-color: fade(@warningColor, 30%);
}

.clear {
    clear: both;
}

.list_childs_store {
    padding-left: 0;

    &.show-all {
        li {
            display: block;
        }
    }

    li {
        display: none;

        &:nth-child(1), &:nth-child(2) {
            display: block;
        }
    }

    .dashed-link {
        display: inline-block;
    }
}

.info_store {
    margin-left: 5px;
    cursor: pointer;

    i {
        font-size: 16px;
    }

    &:nth-child(2) {
        margin-left: 0;
    }
}

table.table.stores-table {
    thead, tfoot {
        tr {
            th {
                font-size: 12px;
            }
        }
    }

    .managers-td {
        &.show-all {
            a {
                display: block;
            }

            .dashed-link {
                display: inline-block;
            }
        }

        a {
            display: none;

            &:nth-child(1), &:nth-child(2) {
                display: block;
            }
        }

        .dashed-link {
            display: inline-block;
        }
    }
}

.notifications-counter {
    position: absolute;
    left: 50%;
    bottom: 50%;
    padding-bottom: 2px;
    outline: none;
}

.events-none {
    pointer-events: none;
}

.cart-table-wrapper {
    margin: 0;
    border-bottom: 0 !important;

    .table {
        margin-bottom: 0;
    }

    .table > tbody > tr > td.cell {
        line-height: 28px;
    }

    .cell-delete {
        a {
            color: @primaryColorText;
            border-bottom: 1px dashed @primaryColorText;
        }
    }

    .product-image {
        height: 60px;
    }

    .cell-amount {
        white-space: nowrap;
    }

    .cell-price {
        white-space: nowrap;
    }

    .cart-store-name {
        font-weight: normal;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .cart-group-name {
        font-weight: normal;
        margin-top: 10px;
    }

    .cart-group-info {
        margin-bottom: 10px;
    }
}

.my-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 109;
    cursor: default;
}

.font-17 {
    font-size: 17px;
}

.primary-color {
    color: @primaryColor;
}

.text-color-gray {
    color: #ababab;
    width: 100%;
    display: inline-block;
    line-height: 18px;
}

.ibox-template {
    &__dot-icon {
        width: 17px;
        height: 17px;
        border-radius: 8.5px;
        background-color: #d7d7d7;
        display: inline-block;
    }

    &__title {
        width: 25%;
        height: 17px;
        background-color: #d7d7d7;
        border-radius: 8.5px;
    }

    &__text-line {
        width: 25%;
        height: 13px;
        background-color: #e0e0e0;
        border-radius: 7px;
        margin-top: 15px;
    }
}

.wrapSliders {
    width: 100%;
    max-width: 1196px;
    margin-bottom: 20px;
    .sliders-grid {
        width: 100%;
        padding: 18px 18px 30px 18px;
        position: relative;
        overflow: hidden;

        img {
            max-width: 100%;
            margin: 0 auto;
        }
        .slick-prev,
        .slick-next {
            z-index: 1;
            height: 30px;
            width: 30px;
            &:before {
                color: @primaryColor !important;
                font-size: 30px;
            }

        }
        .slick-prev {
            left: 25px;
        }
        .slick-next {
            right: 25px;
        }
        .slick-dots {
            bottom: 0;
            li {
                button {
                    &::before {
                        font-size: 10px;
                        color: @primaryColor;
                    }
                }
                &.slick-active {
                    button {
                        &::before {
                            color: @primaryColor;
                        }
                    }
                }
            }
        }
    }

    .slider-progress {
        width: calc(~'100% - 36px');
        height: 3px;
        background: #eee;
        margin: 0 auto -18px;
    }

    .slider-progress .progress {
        width: 0;
        height: 3px;
        background: @primaryColor;
    }
}

.banners-grid {
    margin-bottom: 10px;
    width: 100%;
    min-height: 246px;
    padding: 13px;
    margin-top: -18px;
    position: relative;
    .transition(height, 300ms, 0ms);
    .offsel();

    &_disabled:before {
        content:" ";
        display:block;
        position:absolute;
        top:0;left:0;right:0;bottom:0;
        z-index:999;
        opacity: 0.5;
    }

    &__cells-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
        line-height: 0;
    }

    &__cell-wrapper {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        padding: 0;
        .transition(all, 500ms, 0ms);

        &_selected {
            padding: 15px;

            .banners-grid__cell {
                border: 2px dashed #aad7a2;
            }

            .banners-grid__cell-icon {
                color: #aad7a2;
            }

            .banners-grid__icon-plus {
                opacity: 0;
            }

            .banners-grid__icon-check {
                opacity: 1;
            }
        }
    }

    &__cell {
        display: block;
        width: 100%;
        height: 100%;
        border: 2px dashed #8da8d7;
        .transition(all, 500ms, 0ms);

        &:hover {
            background-color: rgba(189, 249, 177, 0.15);
        }
    }

    &__cell-icon {
        font-size: 100px;
        color: #8da8d7;
        font-style: normal;
        border: none;
        width: 100px;
        height: 60px;
        display: block;
        line-height: 60px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -33px;
        margin-left: -49px;
        .transition(all, 0ms, 500ms);
    }

    &__icon-plus {
        opacity: 1;
    }

    &__icon-check {
        opacity: 0;
        font-size: 60px;
    }

    &__mask {
        display: block;
        border: 1px solid #aad7a2;
        background-color: rgba(189, 249, 177, 0.15);
        position: absolute;
        z-index: 1;
        .transition(all, 500ms, 0ms);
    }

    &__media-form {
        width: 240px;
        height: 50px;
        padding-top: 6px;
        background-color: #aad7a2;
        display: none;
        position: absolute;
        z-index: 3;
        padding-right: 40px;
        .transition(box-shadow, 200ms, 0ms, linear);

        &:hover {
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
        }

        [type="file"] {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            cursor: pointer;
        }

        [type="submit"] {
            display: none;
        }

        .placeholder {
            text-align: center;
        }

        .file-icon {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 40px;
            text-align: center;
            font-size: 23px;
            line-height: 49px;
        }

        &_loading {
            .file-icon {
                display: none;
            }
        }
    }

    &__media-form_loading &__media-form-loader {
        display: block;
    }

    &__media-form-loader {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 40px;
        text-align: center;
        font-size: 23px;
        line-height: 49px;
    }

    &__media {
        display: block;
        position: absolute;
        z-index: 4;
        cursor: default;

        &.pointer {
            cursor: pointer;
        }

        img {
            width: 100%;
            height: 100%;
        }

        .change-link-button {
            position: absolute;
            bottom: 9px;
            left: 9px;
            width: 150px;
            z-index: 2;
            .transition(width, 300ms, 0ms);
        }

        .link-input {
            display: block;
            opacity: 0;
            height: 31px;
            position: absolute;
            z-index: 1;
            bottom: 5px;
            left: 5px;
            padding-left: 33px;
            width: 0;
            .transition(opacity, 300ms, 0ms);
        }

        &_with-input {
            .change-link-button {
                width: 23px;
                overflow: hidden;
            }

            .link-input {
                width: 370px;
                opacity: 1;
            }
        }
    }

    &__media-remove {
        position: absolute;
        right: 0;
        top: 0px;
        font-size: 34px;
        font-weight: 500;
        color: #ff7070;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    &__fixed-button {
        position: fixed;
        bottom: 20px;
        left: 50%;
        z-index: 5;
    }

    &__add-row-button {
        position: absolute;
        right: -20px;
        font-size: 20px;
    }

    &__button-save {
        margin-left: -203px;
        width: 200px;
    }

    &__button-cancel {
        margin-left: 4px;
    }

    &__filler {
        display: block;
        width: 100%;
    }
}

.banner-video {
    height: 100%;
    width: 100%;
    display: flex;
    background: rgba(0, 0, 0, 1);
}

[data-sort-category-container] {
    .ui-sortable-placeholder {
        border: 0 !important;
        //box-shadow: 0 0 0 1px #ff9c9c inset;
        background-color: transparent;
    }

    [data-sort-category-id] {
        box-shadow: 0 0 0 1px red inset;
    }

    .ui-sortable-helper {
        background-color: #ffe4e9;
    }
}

.category-separator {
    clear: both;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 8px;
    font-size: 18px;
    .breadcrumb-list {
        padding: 0;
    }

    &:first-child {
        h2 {
            margin-top: 0;
            margin-right: 30px;
            flex-grow: 1000;
        }
    }

    h2 {
        font-size: 20px;
        color: #000;

        a {
            color: #666666;

            &:hover {
                text-decoration: underline;
                color: #666666;
            }
        }
    }

    .fa {
        margin: 0 10px;
    }
}

.admin-select-language {
    select {
        width: 100%;
        margin-top: 10px;
    }
}

.underline {
    text-decoration: underline;
}

.article-edit-page {
    .store-label {
        font-weight: bold;
        margin-bottom: 5px;
    }

    [data-editable] {
        //border: 1px dashed #0080e5;
    }
}

.buttons-cell {
    width: 103px;

    &__button {
        padding: 2px 10px;
        width: 35px;
    }
}

.childs-control-table {
    @hiddenColor: #fee4e4;
    @partiallyColor: #fcfee4;
    @visibilityColor: #e3ffe3;

    thead th {
        &:not(.model-th) {
            background-color: @hiddenColor !important;

            &.checked {
                background-color: @visibilityColor !important;
            }
        }
    }

    .columns-selector {
        visibility: hidden;
    }


    .visibility-th {
        text-align: center;
        cursor: pointer;

        &.data-cell {
            position: relative;
            background-color: @hiddenColor;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &.partially {
                background-color: @partiallyColor;

                &:hover {
                    background-color: darken(shade(@partiallyColor, 10%), 5%);
                }
            }

            &.checked {
                background-color: @visibilityColor;

                &:hover {
                    background-color: darken(shade(@visibilityColor, 10%), 5%);
                }
            }

            &:hover {
                background-color: darken(shade(@hiddenColor, 10%), 5%);
            }
        }
    }

    .model-th {
        .model-name {
            border-bottom: 1px dashed #ccc;
            cursor: pointer;
        }

        .spinner-box {
            display: inline-block;
            height: 14px;
        }

        .spinner {

        }
    }

    .subgrid-row {
        display: none;
        background-color: white !important;

        > .subgrid-data {
            padding: 0;
        }

        .subgrid-table {
            width: calc(~"100% - 20px") !important;
            margin-top: 0 !important;
            border-right: 0;
            border-top: 0;
            background-color: #fafafa;
            margin-left: 20px;
        }
    }
}

.chosen-container-single .chosen-single span, .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    position: relative;
    top: 1px;
}

.tinymce-wrapper {
    .editable-area {
        outline: none !important;
    }

    .mce-tinymce.mce-container.mce-panel {
        border: none;
    }

    .mce-content-body {
        padding: 0;
    }
}

.buttons-wrapper {
    margin-top: 20px;
}

.checkboxes-list {
    .icheckbox_square-green {
        margin-right: 4px;
    }

    &__row {
        margin-bottom: 9px;
    }

    &__info-text {
        display: block;
        margin-left: 30px;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: #999;
        top: -2px;
        position: relative;
    }

    &__separator {
        width: 100%;
        height: 1px;
        background-color: #ccc;
        margin: 8px 0 12px;
    }
}

//redesign
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 112;
    width: 100%;
    height: 48px;
    background-color: #F4F4F4;
    .transition(box-shadow, 400ms, 0ms);

    &.fixed:not(.catalog-view) {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    }

    .main-menu {
        position: relative;

        .dashboard-icon {
            position: relative;
            top: -2px;
        }

        .dashboard-icon_cafe {
            position: relative;
            top: -5px;
        }

        .pull-left {
            height: 100%;
        }

        > .pull-right {
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background-color: #F4F4F4;
            z-index: 1;
        }

        .catalog-items {
            .menu-item {
                color: #666666;
                text-transform: uppercase;
                font-weight: 600;
                position: relative;

                &.active {
                    background-color: white;
                    height: 51px;

                    &:before, &:after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 48px;
                        position: absolute;
                        top: 0;

                        @media (max-width: 1023px) {
                            height: 46px;
                        }
                    }

                    &:before {
                        background: url(../img/menu-active-left.jpg) center center transparent no-repeat;
                        left: -10px;
                    }

                    &:after {
                        background: url(../img/menu-active-right.jpg) center center transparent no-repeat;
                        right: -10px;
                    }
                }

                &.pageCategoryModel {
                    color: #fff;
                    margin-left: 10px;

                    &.active {
                        height: 100%;
                    }
                }

                &.newModel {
                    background: #67c581;
                    z-index: 1;

                    .count_new_model {
                        color: #fff;
                        background: #e02729;
                        padding: 1px 5px;
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: 12px;
                        transform: translate(50%, 0);
                        border-radius: 2px;
                    }
                }

                &.outletModel {
                    background: #e02729;
                    margin-left: 15px;
                }
            }
        }

        .menu-item {
            padding: 15px 10px 4px;
            display: inline-block;
            font-family: 'Open Sans', sans-serif;
            text-decoration: none;
            font-size: 12px;
            height: 100%;
            vertical-align: top;
            color: black;

            span {
                vertical-align: top;
            }

            .fa-sort-desc {
                vertical-align: top;
                margin-left: 7px;
            }

            &.cart-info {
                text-decoration: none;
                background-color: @primaryColorText;
                color: white;
                padding: 5px 18px;

                .left-block, .right-block {
                    float: left;
                }

                .left-block {
                    margin-right: 12px;
                }

                .weight {
                    font-size: 11px;
                    line-height: 12px;
                    opacity: 0.8;
                }

                .amount {
                    line-height: 21px;
                    font-size: 15px;
                }

                .count {
                    font-size: 11px;
                    top: 2px;
                    opacity: 0.8;
                }

                .cart-icon {
                    background: url(../img/icon-delivery.png) center center transparent no-repeat;
                    width: 35px;
                    height: 20px;
                    display: inline-block;
                }
            }

            &.with-submenu {
                cursor: pointer;
                &:not(:last-child) {
                    margin-right: 8px;
                }

                &.active, &:hover {
                    background-color: #E6E6E6;

                    &:before {
                        content: "";
                        display: block;
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background-color: @primaryColorText;
                    }

                    .submenu {
                        display: block;
                    }
                }

                &.user-menu {
                    min-width: 190px;
                    .user-logo {
                        display: none;
                    }
                    .submenu {
                        width: 100%;
                    }
                }

                &.user-notifications {
                    .submenu-item {
                        padding: 8px 16px 8px 16px;

                        p {
                            width: 85%;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0;
                            text-align: left;
                            color: #686A6C;
                            padding-top: 1px;
                        }
                    }

                    .submenu {
                        min-width: 350px;
                    }
                }

                &.user-settings, &.user-notifications {
                    &.active, &:hover {
                        &:before {
                            display: none;
                        }
                    }
                }

                .submenu {
                    display: none;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    height: auto;
                    padding: 0;
                    background-color: white;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

                    .submenu-title {
                        cursor: default;
                        display: flex;
                        border-bottom: 2px solid #E02629;
                        font-size: 14px;
                        font-weight: 600;
                        background: #F4F4F4;
                        padding: 16px;
                        white-space: nowrap;
                        align-items: center;

                        a {
                            padding: 0;
                            color: #686A6C;
                            font-size: 11px;

                            &:hover {
                                color: #515151;
                                background: transparent;
                            }
                        }
                    }

                    .submenu-footer {
                        cursor: default;
                        display: flex;
                        font-size: 14px;
                        font-weight: 600;
                        background: #F4F4F4;
                        padding: 16px;
                        white-space: nowrap;

                        a {
                            color: @primaryColorText;
                            font-size: 11px;
                            text-decoration: underline;
                            padding: 0;

                            &:hover {
                                text-decoration: none;
                                background: transparent;
                            }
                        }
                    }

                    .submenu-item {
                        border-bottom: 1px solid #99999980;
                    }

                    .submenu-item-title {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0;
                    }

                    .submenu-item-body {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0;
                        text-align: left;
                        color: #686A6C;
                        padding-top: 1px;

                        &.empty-item {
                            padding: 16px 8px 16px 8px;
                            display: inline-block;
                            cursor: auto;
                        }
                    }

                    a {
                        display: block;
                        width: 100%;
                        color: black;
                        padding: 8px 10px 8px 16px;

                        &:hover {
                            background-color: #f1f1f1;
                        }
                        @media (max-width: 900px) {
                            padding: 4px 5px;
                        }

                        &.flex {
                            display: flex;
                        }
                    }

                    .separator {
                        height: 1px;
                        width: 100%;
                        background-color: #999999;
                        margin: 2px 0;
                    }

                    .label {
                        padding: 1px 5px 1px 5px;
                        display: flex;
                        align-items: center;
                        background-color: #23c6c8;
                        color: #FFFFFF;
                        margin-left: auto;
                        &:empty {
                            display: none;
                        }
                    }
                }
            }

            &.menu-button {
                width: auto;
                cursor: pointer;

                &_green {
                    color: white;
                    background-color: @green3Color;
                }
            }

            &.guest-menu {
                text-transform: uppercase;
                padding: 4px 4px 4px;

                a {
                    color: #ed1c24;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    font-weight: 600;
                    font-size: 12px;
                    padding: 12px 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .icon-dashboard {
            background: url(../img/icon-dashboard.png) center center transparent no-repeat;
            width: 30px;
            height: 25px;
            display: inline-block;
            position: relative;
            top: -2px;
        }

        .icon-settings {
            background: url(../img/icon-settings.png) center center transparent no-repeat;
            width: 25px;
            height: 24px;
            display: inline-block;
            position: relative;
            top: -3px;
        }

        .icon-notifications {
            background: url(../img/icon-notifications.png) center center transparent no-repeat;
            width: 25px;
            height: 24px;
            display: inline-block;
            position: relative;
            top: -3px;
        }

    }
}

.right-text {
    text-align: right;
}

.w-lg-100 {
    width: 100% !important;
}

.offset-right-30 {
    padding-right: 30px;
}

.content-wrapper {
    width: 100%;
    background-color: white;
    padding-top: 20px;
}

.footer {
    position: fixed;
    bottom: -35px;
    left: 0;
    z-index: 110;
    width: 100%;
    min-width: 770px;
    background-color: #F4F4F4;
    margin-top: 20px;
    border-top: 1px solid #e7eaec;
    padding: 10px 20px;
    .transition(bottom, 300ms, 0ms);

    .for-opened {
        display: none;
    }

    .for-closed {
        display: block;
    }

    &.opened {
        bottom: 0;

        .for-opened {
            display: block;
        }

        .for-closed {
            display: none;
        }
    }

    &__text {
        line-height: 16px;
        font-size: 12px;
    }

    &__hide-button {
        width: 50px;
        height: 15px;
        position: absolute;
        left: 50%;
        margin-left: -25px;
        top: -15px;
        background-color: #F4F4F4;
        border: 1px solid #e7eaec;
        border-bottom: 0;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        cursor: pointer;
        text-align: center;

        svg {
            height: 16px;
            position: relative;
            top: -1px;
        }
    }
    .current_version_app {
        position: absolute;
        top: 9px;
        right: 40px;
        color: brown;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
}

.inner {
    max-width: 1306px;
    min-width: 750px;
    height: 100%;
    margin: auto;
    padding: 0 20px;
}

.extended-content {
    max-width: 1420px;
    .orders-filters {
        margin-bottom: 58px;
    }
    .pagination {
        width: auto;
    }
}

.order-legend-on-stock {
    position: absolute;
    right: 260px;
    height: 38px;
    width: auto;
    z-index: 1;
    svg {
        position: relative;
        top: 5px;
    }
}

.order-legend-pre-order {
    position: absolute;
    right: 130px;
    height: 38px;
    width: auto;
    z-index: 1;
    svg {
        position: relative;
        top: 5px;
    }
}

.order-legend {
    position: absolute;
    right: 0;
    height: 38px;
    width: auto;
    z-index: 1;
    svg {
        position: relative;
        top: 5px;
    }
}

table.orderTable {
    .description.whose_order {
        display: block;
        font-size: 10px;
        color: #888;
        line-height: 7px;
    }
}

#toast-container > div {
    border-radius: 0;
}

.catalog-tags-panel {
    display: none;

    .tag {
        text-decoration: underline;
        color: #8e8e8e;
        cursor: pointer;
        margin: 5px 10px 10px 10px;
    }
}

.table-bottom__main-info {
    height: 37px;
}

.green {
    color: @greenColor;
}

.red {
    color: @primaryColor;
}

.my-dropdown {
    display: none;
    position: absolute;
    top: 16px;
    left: -9px;
    z-index: 101;
    background-color: white;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.2);
    margin-top: -10px;
    margin-left: -10px;
    min-width: 180px;
    border: 1px solid #e5e6e7;
    .transition(top, 400ms, 0ms);

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    .checkbox {
        margin: 0;

        label:before {
            top: 50%;
            margin-top: -8px;
        }
    }

    .my-dropdown-item {
        white-space: nowrap;
        line-height: 36px;
        padding: 0 17px;
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background-color: #f4f4f4;
        }
    }
}

.catalog-filter-panel {
    &.filter_search_panel {
        .title_filter {
            display: inline-block;
            text-align: center;
            font-size: 15px;
            color: black;
            cursor: pointer;

            &.not-js {
                cursor: inherit;
            }
        }

        .title_block_search {
            padding-left: 20px;
            line-height: 42px;
            display: inline-block;
            flex: 0 auto;

            span {
                position: relative;
                font-family: Open Sans, sans-serif;
                line-height: 135%;
                display: inline-block;
                max-width: none;
                font-weight: 600;
                font-size: 15px;
                width: 100%;
                color: black;
            }
        }

        &.filters {
            overflow: hidden;
            padding: 0;
            margin-top: -1px;
            border-bottom: 1px solid #E6E6E6;
            width: 100%;
            text-align: center;
            left: 0;
            background: #fff;
            .transition(all, 500ms, 400ms);

            &:not(.open) {
                top: 50px;
                border-top-color: transparent;
                border-bottom-color: transparent;
                border: 0;
                height: 0;
                .transition(all, 500ms, 400ms);

                .item {
                    opacity: 0;
                    .transition(opacity, 500ms, 400ms);
                }
            }

            &.open {
                padding: 4px 0;
                margin: 0;
                overflow: visible;
                .transition(all, 400ms);

                .item {
                    opacity: 1;
                    .transition(opacity, 400ms);

                    &.filters {
                        margin-left: 12px;
                        margin-top: 2px;
                        flex: 1;
                    }
                }
            }

            .inner {
                justify-content: center;

                &-search {
                    justify-content: flex-start;

                    .item {
                        text-align: center;
                    }
                }
            }
        }

        .filter_search_item {
            display: inline-block;
        }

        .switch-box {
            top: 33px;
        }
    }

    &.filter_page_panel {
        border-top: none;
    }

    .filter {
        &.filter_loading:before {
            content: ' ';
            position: absolute;
            top: 10px;
            right: -10px;
            width: 15px;
            --b: 3px;
            aspect-ratio: 1;
            border-radius: 50%;
            padding: 1px;
            background: conic-gradient(#0000 10%, #f03355) content-box;
            -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
            -webkit-mask-composite: destination-in;
            mask-composite: intersect;
            animation: spinner 1s infinite steps(10);
        }
        @keyframes spinner {to{transform: rotate(1turn)}}

        .filter_page.active {
            background: @primaryColorText;
            color: #fff;
            border-radius: 3px;
        }

        .filter_page {
            position: relative;
            color: @primaryColorText;
            padding: 3px 5px 3px 23px;
            margin-left: 5px;
            font-weight: 600;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 7px;
                width: 8px;
                height: 8px;
                background: #fff;
                border-radius: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}

.catalog-top-panel {
    height: auto;
    background-color: white;
    z-index: 110;
    white-space: nowrap;
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    .transition(all, 400ms, 0ms);

    &.fixed {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

        .submenu {
            top: 42px;
        }

        .catalog-filter-panel:not(.filter_search_panel) {
            border-bottom: 0;

            .switch-box {
                top: 33px;
            }


            .my-dropdown {
                top: 13px;
            }
        }

        .catalog-filter-panel.filter_page_panel {
            //border-bottom: 1px solid transparent;
            //padding: 2px 0;
        }

        .catalog-filter-panel.filter_search_panel.filters.open,
        .catalog-filter-panel {

            .filters__clear-button {
                font-size: 14px;

                .icon-close {
                    right: 10px;
                    font-size: 21px;
                }
            }

            .icon-filters {
                width: 20px;
            }

            .current-category {
                font-size: 14px;
            }

            .my-dropdown {
                top: 9px;
            }
        }

        .catalog-filter-panel.filter_search_panel {
            &.filters {
                &.open {
                    .my-dropdown {
                        top: 14px;
                    }
                }
            }

            .switch-box {
                top: 30px;
            }
        }

        > .inner {
            height: 42px;
        }

        .categories-list {
            .item {
                line-height: 42px;
                font-size: 11px;
            }
        }

        .switch-box {
            top: 29px;
        }

        .filters.filter_search_panel.open {
            //margin-top: -9px;
            //z-index: 2;
        }

        .search_filters.filters.filter_search_panel.open {
            //margin-top: 0;
            //box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
            //z-index: 1;
        }
    }

    .categories-list {
        .arrow_after,
        .arrow_before {
            display: none;
        }

        .item {
            display: inline-block;
            padding: 0 15px;
            line-height: 56px;
            text-transform: uppercase;
            font-size: 12px;
            cursor: pointer;
            font-weight: 600;
            color: #666666;
            vertical-align: top;
            .transition(all, 400ms, 0ms);

            &:hover {
                color: @primaryColorText;
            }

            &.active {
                color: black;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 100%;
                    background-color: black;
                }
            }
        }
    }

    .submenu {
        display: none;
        position: absolute;
        left: 0;
        top: 56px;
        z-index: 102;
        background-color: white;
        width: 100%;
        min-height: 256px;
        padding: 17px 0;

        .column {
            float: left;
            padding-left: 14px;
            width: 240px;

            border-right: 1px solid #e6e6e6;

            &:last-child {
                border-right-color: transparent;
            }
        }

        .child {
            font-size: 14px;
            line-height: 31px;
            text-decoration: none;
            display: block;
            color: black;

            &:hover {
                text-decoration: underline;
            }

            &.first-level {
                color: @primaryColorText;
                font-weight: 600;
            }

            &.second-level {

            }
        }
    }

    > .inner {
        .transition(all, 400ms, 0ms);
        height: 56px;
    }

    .search_filters.filters.filter_search_panel.open {
        margin-top: 0;

        .my-dropdown {
            top: 14px;
        }
    }
}

.catalog-filter-panel {
    background-color: white;
    border-bottom: 1px solid #E6E6E6;
    .transition(all, 400ms, 0ms);
    padding: 6px 0;

    &:not(.filter_search_panel) {
        border-top: 1px solid #E6E6E6;
    }

    &.filter_page_panel {
        //height: 52px;
    }

    .inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: baseline;
    }

    .item {
        line-height: 42px;
        vertical-align: top;
        display: inline-block;
        .transition(all, 400ms, 0ms);
    }

    .current-category {
        font-weight: 600;
        color: black;
        padding: 0 20px;
        width: 240px;
        overflow: hidden;
        font-size: 15px;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        .transition(all, 400ms, 0ms);

        &.search {
            width: auto;
            max-width: calc(~'100% - 300px');
            min-width: 240px;
        }
    }

    .icon-filters {
        background: url(../img/icon-filters.png) no-repeat center center;
        width: 26px;
        height: 26px;
        margin-top: 8px;
        position: relative;
        background-size: contain;
        .transition(all, 400ms, 0ms);
    }

    .filters {
        display: flex;
        flex-flow: row wrap;
        max-width: 1000px;
        flex: 1;

        .filter {
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 3px;
            color: black;
            cursor: pointer;
            font-size: 14px;
            .transition(all, 400ms, 0ms);

            .selector-button {
                padding: 6px 8px;
                line-height: 22px;
                user-select: none;

                .fa {
                    top: -3px;
                    position: relative;
                    margin-left: 6px;
                }

                .filter__drop-button {
                    display: none;
                    position: absolute;
                    top: calc(50% - 11px);
                    right: 8px;
                    font-size: 21px;
                }
            }

            &.active {
                .selector-button {
                    padding-right: 31px;
                    background-color: #C94942;
                    color: white;
                    border-radius: 5px;

                    .fa {
                        display: none;
                    }

                    .filter__drop-button {
                        display: block;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .filters__clear-button {
        display: none;
        margin-right: 15px;
        padding: 4px 31px 6px 8px;
        font-size: 14px;
        line-height: 30px;
        color: #C94942;
        user-select: none;
        .transition(all, 400ms, 0ms);

        &_active {
            display: inline-block;
        }

        &:hover {
            color: #B93932;
        }

        .icon-close {
            position: absolute;
            right: 10px;
            font-size: 21px;
            top: 4px;
            .transition(all, 400ms, 0ms);
        }
    }

    .selector {
        select {
            display: none;
        }

        &-right {
            float: right;
        }

        .selector-button {
            &:hover {
                text-decoration: underline;
            }
        }

        .selector-dropdown {
            display: none;
            position: absolute;
            left: -10px;
            top: 50px;
            margin-top: -10px;
            z-index: 101;
            line-height: 0;
            background-color: white;
            width: 240px;
            box-shadow: 0 2px 10px -3px rgba(0, 0, 0, .2);
            .transition(top, 400ms, 0ms);

            &-search {
                text-align: start;
            }

            .SumoSelect {
                position: relative;
                top: 0;
                width: 100%;
                line-height: 19px;

                label {
                    font-weight: normal;
                }

                > .CaptionCont {
                    width: 100%;
                    border: 0;
                    box-shadow: none;
                    line-height: 24px;
                }

                &.open > .CaptionCont {
                    &, &:hover, &:focus {
                        border: 0;
                        box-shadow: none;
                    }
                }

                .optWrapper {
                    top: 0;
                    z-index: 2;
                    margin-top: -2px;
                    box-shadow: none;
                    border: 0;
                    position: relative;

                    &::-webkit-scrollbar {
                        width: 8px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: darkgrey;
                        outline: 1px solid slategrey;
                    }

                    .options {
                        max-height: 300px;
                    }
                }

                .clear-all-button {
                    display: none;
                    padding: 5px 8px;
                    font-size: 13px;
                }
            }

            input {
                outline: none !important;
            }
        }
    }

    .sort-direction {
        background: url(../img/icon-sort.png) 0 0 transparent no-repeat;
        width: 33px;
        height: 17px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
        cursor: pointer;

        &.desc {
            background-position: -33px 0;
        }
    }

    .title_search {
        padding-right: 15px;
        font-size: 15px;
        color: #000;
    }

    .title_filter {
        display: none;
        flex: 1 0 auto;

        span {
            margin-right: 3px;
        }

        .fa {
            top: -3px;
            position: relative;
            margin-left: 2px;
        }
    }

    .sort-type {
        font-size: 14px;
        color: black;
        cursor: pointer;
        text-align: left !important;
        .transition(all, 400ms, 0ms);

        &:hover {
            .order-by {
                text-decoration: underline;
            }
        }

        .fa {
            top: -3px;
            position: relative;
            margin-left: 2px;
        }

        .order-field {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: rgba(0, 0, 0, 0.6);
            display: block;
            bottom: 1px;
            position: absolute;
            .transition(bottom, 400ms, 0ms);
        }
    }

    .catalog-view {
        margin-left: 40px;

        .view-change-loading {
            display: none;
            position: absolute;
            left: -17px;
            top: 50%;
        }

        .view {
            background: url(../img/grid-list.png) 0 0 transparent no-repeat;
            background-position-y: -20px;
            width: 25px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            margin-top: -2px;

            &.active {
                background-position-y: 0;
            }

            &.view-grid {
                background-position-x: 0;
            }

            &.view-table {
                background-position-x: -26px;
            }

            &.view-color_grid {
                background-position-x: -52px;
            }
        }
        .color-pattern-view-switch {
            background-size: contain;
            background-image: url('../img/color-pattern-view-switch.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 25px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            margin-top: -2px;
            &.active:after {
                content: ' ';
                position: absolute;
                width: 38px;
                height: 38px;
                border: #EAEAEA solid 1px;
                border-radius: 2px;
                bottom: -9px;
                left: -6px;
            }
        }
    }

    .inner-search {
        .sort-type {
            .my-dropdown {
                left: auto;
                right: 0;
            }
        }
    }
}

.switch-box {
    display: none;
    position: absolute;
    right: -7px;
    top: 36px;
    background-color: white;
    white-space: normal;
    line-height: 16px;
    font-size: 12px;
    width: 270px;
    color: #000000;
    box-shadow: 0 2px 10px -3px rgba(0, 0, 0, .2);
    z-index: 101;
    .transition(all, 400ms, 0ms);

    &__item {
        height: 50px;
        border: 1px solid #ddd;
        cursor: default;
        &__label {
            width: 170px;
            height: 100%;
            display: inline-block;
            padding-left: 18px;
            padding-top: 8px;
            vertical-align: middle;
        }
        &__onoffswitch {
            display: inline-block;
            vertical-align: middle;
            margin-left: 25px;
        }
    }
}

.count-on-page {
    text-align: center;

    &__before-text {
        display: inline-block;
    }

    &__select {
        display: inline-block;
        margin: 0 6px;
        border-color: #ccc;
        width: 75px;
        vertical-align: middle;
        padding-top: 3px;
    }

    &__after-text {
        display: inline-block;
    }
}

.stretch-blocks {
    .flex-display(flex);
    .flex-direction(row);

    &__block {
        margin: 0;
        padding-bottom: 30px;

        .ibox {
            height: 100%;
            margin: 0;
        }
    }
}

.wrap_manager_block {
    padding: 15px 20px 0 20px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e5e6e7;
    margin: 10px 0;

    label:not(.strong) {
        font-weight: 500;
    }

    &.disabled {
        cursor: default;
        label {
            cursor: default;
        }
    }
}

.input-group .bootstrap-touchspin-up {
    padding: 6px 10px;
}

.wrap_select_price_list {
    border-radius: 3px;
    margin: 0 0 23px 0;
    display: inline-block;
    width: 50%;
    max-width: 250px;

    select {
        height: 34px;
        width: 100%
    }
}

.back-to-the-list {
    height: 40px;
    margin: 0 0 15px;
    line-height: 20px;
    font-size: 15px;

    &:hover {
        a {
            color: darken(@primaryColor, 10%);
            background-color: #F9F8F8;
        }
    }

    a {
        display: block;
        text-align: left;
        padding: 7px;
        border-radius: 5px;
        color: @primaryColor;
    }

    svg {
        vertical-align: middle;
    }
}

.child-control-page {
    .chosen-container {
        width: 100% !important;
    }

    .selectItem {
        padding: 5px 10px;
        border: 1px solid #f4f4f4;
        margin: 5px 0;
        background: #f9f8f8;
        position: relative;
        font-size: 14px;

        .childItem {
            padding-left: 20px;
            font-size: 12px;
        }
    }

    #itemsSelect {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .clearSelectItem {
        position: absolute;
        top: 4px;
        right: 10px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        padding: 5px;
        box-sizing: border-box;

        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 12px;
            border-radius: 2px;
            top: 50%;
            left: 50%;
            background: #ed1c24;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover {
            &:after,
            &:before {
                box-shadow: 0 0 2px 0 rgba(237, 28, 36, 0.5);
            }
        }
    }

    .ibox-content .error {
        color: red;
    }
    #wrap_items_version {
        .version_name {
            color: brown;
            margin: 0 5px;
        }
        .current_version {
            color: forestgreen;
        }
    }
}

.block_bond {
    position: relative;
    margin: 0 5px;
    padding: 0;
    font-weight: 500;

    .current_bond {
        border-bottom: 1px dashed #e86565;
        cursor: pointer;
    }

    .drop_down_bond {
        text-decoration: none;
        position: absolute;
        display: none;
        top: 30px;
        left: 0;
        background: #fff;
        box-shadow: 0 1px 10px -1px rgba(0, 0, 0, .5);
        border-radius: 5px;
        padding: 5px 0;
        transform: translate(-20%, 0);
        z-index: 10;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: -5px;
            left: 50%;
            width: 0;
            height: 0;
            border: 5px solid #fff;
            margin-left: -5px;
            box-shadow: -1px -1px 6px -2px rgba(0, 0, 0, 0.5);
            z-index: -1;
            transform: rotate(45deg);
        }

        .drop_down_item {
            padding: 2px 10px;
            display: block;
            cursor: pointer;

            &:hover {
                background: rgba(223, 37, 37, 0.28);
            }
        }
    }
}

#wrap_items_file,
#wrap_items_rule {
    .action_td {
        min-width: 70px;
    }
}

@media (max-width: 1192px) {
    #wrap_items_file,
    #wrap_items_rule {
        .action_td {
            min-width: 86px;
        }
    }
}

.input-group .form-control#markupInput,
.input-group .form-control#discountInput {
    min-width: 70px;
    text-align: center;
}

.switch {
    display: inline-block;
    text-align: initial;
}

.onoffswitch-label {
    margin: 0;
}

.productFieldDelete,
.mainFieldDelete {
    display: inline-block;
    position: relative;
    top: -7px;
    padding: 0 10px;
    margin-right: 20px;
    font-size: 12px;
    line-height: 18px;
}

.input-with-button {
    button[type="reset"] {
        background-color: transparent;
        border: 0;
        border-radius: 50%;
        color: #666;
        display: none;
        outline: none;
        padding: 16px 6px;
        position: absolute;
        right: 16px;
        top: 1px;
        transition: background .2s;
        line-height: 0;
        font-size: 20px;

        &:hover {
            opacity: 0.8;
        }
    }

    input:valid ~ button[type="reset"] {
        display: block;
    }
}

.wrapTable {
    &.wrapTable_cart {
        max-height: 55vh;
        overflow-y: auto;
        background: #fafafa;
    }

    .table {
        .sort-element {
            cursor: pointer;

            span {
                position: relative;

                .table_name {
                    display: inline-block;
                }

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    right: -12px;
                    width: 8px;
                    height: 8px;
                    transform: rotate(45deg);
                }

                &:before {
                    top: 9px;
                    border: 1px solid transparent;
                }

                &:after {
                    top: 4px;
                    border: 1px solid transparent;
                }
            }

            &.sort-asc {
                span {
                    &:before {
                        border-top-color: #337ab7;
                        border-left-color: #337ab7;
                    }
                }
            }

            &.sort-desc {
                span {
                    &:after {
                        border-bottom-color: #337ab7;
                        border-right-color: #337ab7;
                    }
                }
            }
        }
    }
}

.catalog-bottom-panel {
    display: flex;

    .stores_catalog-switcher {
        margin-right: 5px;

        &__button {
            display: inline-block;
            border-bottom: 1px dotted @primaryColor;
            cursor: pointer;
        }

        &.open {
            .stores_catalog-switcher__current-store_catalog {
                &_absolute {
                    display: block;
                }
            }

            .stores_catalog-switcher__list-of-stores_catalog {
                display: block;
            }
        }

        &__current-store_catalog {
            color: @primaryColor;

            &_static {
                display: block;
            }

            &_absolute {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 10;
            }
        }

        &__list-of-stores_catalog {
            display: none;
            padding: 15px 0 30px;
            background-color: white;
            position: absolute;
            z-index: 9;
            text-align: left;
            bottom: -6px;
            left: -25px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
            line-height: 22px;
            white-space: nowrap;
        }

        &__scroll-list {
            max-height: 200px;
            overflow-y: auto;
        }

        &__list-row {
            display: block;
            color: #333;
            padding: 0 25px;

            &:hover {
                background-color: #eeeeee;
            }

            &.active {
                background-color: rgba(223, 37, 37, 0.28);
                color: white;
                cursor: default;
            }
        }
    }
}

span.small_position {
    display: block;
    font-size: 12px;
    color: #888888;
}

span.row_company {
    display: block;
}

.one-radio-payment {
    label {
        &:before,
        &:after {
            display: none;
        }

        padding-left: 0;
        margin-left: -20px;
        cursor: inherit;
    }
}

.branded_catalog_wrap {
    .filter {
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
        align-items: flex-end;

        .filter_item {
            margin-right: 10px;
            padding: 5px 10px;
            cursor: pointer;

            .filter_title {
                color: #DF2424;
                border-bottom: 1px dashed #DF2424;
                display: inline-block;

                &.img_name {
                    border-bottom-color: transparent;
                    .transition(opacity, 300ms);
                }

                img {
                    max-width: 100px;
                    max-height: 40px;
                    padding-bottom: 4px;
                }
            }

            &:hover,
            &.active {
                .filter_title {
                    &.img_name {
                        opacity: 0.7;
                    }

                    border-bottom-color: transparent;
                }
            }
        }
    }
}

#fancybox_popup {
    position: relative;
    background: #f9f9f9;
    color: #444;
    border-radius: 4px;
    text-shadow: none;

    h3 {
        font-size: 24px;
        font-weight: 500;
    }

    .btn-primary {
        background-color: #E86565;
        border-color: #E86565;

        &:hover,
        &:focus,
        &:active,
        &.active,
        &:active:focus,
        &:active:hover,
        &.active:hover,
        &.active:focus {
            background-color: #DF2424;
            border-color: #DF2424;
        }
    }

    .text-muted {
        color: #888888;
    }

    .btn-white {
        color: inherit;
        background: white;
        border: 1px solid #e7eaec;

        &:hover,
        &:focus,
        &:active,
        &.active,
        &:active:focus,
        &:active:hover,
        &.active:hover,
        &.active:focus {
            color: inherit;
            border: 1px solid #d2d2d2;
        }

        &:active,
        &.active {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
        }
    }

    .btn {
        border-radius: 3px;
    }

    .form-control,
    .single-line {
        background-color: #FFFFFF;
        background-image: none;
        border: 1px solid #e5e6e7;
        border-radius: 1px;
        color: inherit;
        display: block;
        padding: 6px 12px;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        box-shadow: none;
        position: relative;

        &:focus {
            border-color: #1ab394 !important;
        }
    }

    .help-bubble {
        visibility: hidden;
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 320px;
        background-color: white;
        min-height: 50px;
        margin-bottom: 36px;
        opacity: 0;
        margin-left: -160px;
        padding: 15px;
        z-index: 2;
        filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
        .transition(all, 700ms, 200ms);

        &:before {
            content: "";
            border: 10px solid transparent;
            border-top-color: white;
            position: absolute;
            left: 50%;
            top: 100%;
            margin-left: -10px;
        }

        &.show {
            visibility: visible;
            margin-bottom: 6px;
            opacity: 1;
        }
    }

    .chosen-container {
        text-align: left;
        width: 100% !important;

        .chosen-single {
            outline: none !important;

            div b {
                background-position: 3px 7px;
            }
        }

        .chosen-results li.highlighted {
            background: #DF2424;
        }

        &.chosen-container-active {
            &.chosen-with-drop {
                .chosen-single {
                    background: white;
                    border: 1px solid #aaa;

                    div b {
                        background-position: -15px 6px;
                    }
                }

                .chosen-drop {
                    left: 0;
                    z-index: 1000000;
                }
            }
        }
    }

    .chosen-container-single .chosen-single,
    .chosen-container-multi .chosen-choices {
        background: #ffffff;
        box-shadow: none;
        -moz-box-sizing: border-box;
        border: 1px solid #e5e6e7;
        border-radius: 2px;
        cursor: text;
        height: auto !important;
        margin: 0;
        min-height: 30px;
        overflow: hidden;
        padding: 4px 12px;
        position: relative;
        width: 100%;

        span,
        li.search-field input[type=text] {
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            position: relative;
            top: 1px;
        }
    }
}

.small, small {
    display: block;
}

.download_price_list,
.hidden_product_rule_one {
    .checkboxes-multicheck-list__item-label {
        margin: 0;
    }
    .multicheck__item.category1,
    .multicheck__item.category2,
    .multicheck__item.category3,
    .multicheck__item.category4,
    .multicheck__item.category5 {
        padding-left: 20px;
    }
    .checkboxes-multicheck-list__item {
        margin-top: 0.5em;
    }
    .checkboxes-multicheck-list__item.all_item {
        margin: 0;
    }
}

.hidden_product_rule_one {
    label.label_or {
        position: absolute;
        top: 45%;
        left: 32%;
    }

    label.label_and {
        position: absolute;
        top: 45%;
        left: 66.3%;
    }

    @media (max-width: 991px) {
        label.label_or {
            position: absolute;
            top: 32%;
            left: 50%;
            transform: translate(-50%, 0);
        }

        label.label_and {
            position: absolute;
            top: 65.3%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}

.wrapButtonShowTable {
    margin-bottom: 20px;
}

.button_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

body {
    &.with-announce {
        padding-top: 98px;

        #header {
            top: 50px;
        }

        .catalog-top-panel {
            top: 98px;
        }

        .wide-announce {
            display: flex;
        }
    }

    &.with-announce-outlet {
        padding-top: 98px;

        #header {
            top: 50px;
        }

        .catalog-top-panel {
            top: 98px;
        }

        .wide-announce-outlet {
            display: flex;
        }
    }
}

.wide-announce {
    display: none;
    position: fixed;
    width: 100%;
    height: 50px;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 1000;
    outline: none;
    align-items: center;

    &_orange {
        background-color: orange;
        color: white;
        a {
            color: white;
        }
    }

    &_red {
        background-color: #e02729;
        color: white;
        a {
            color: white;
        }
    }

    &_white {
        background-color: white;
        color: #ed5565;
        box-shadow: 0 -7px 3px -6px rgba(0, 0, 0, 0.3) inset;
        a {
            color: #ed5565;
        }
    }

    &__close {
        font-size: 20px;
        cursor: pointer;
        line-height: 50px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 20px;
    }

    &__text {
        width: 100%;
        line-height: 16px;
        font-size: 14px;
        padding: 2px 50px;
        display: block;
        top: 0;
        left: 0;
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        @media (max-width: 700px) {
            line-height: 14px;
            padding-top: 4px;
            font-size: 12px;
        }
    }
}

.wide-announce-outlet {
    position: fixed;
    width: 100%;
    height: 50px;
    text-align: center;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 1000;
    outline: none;
    align-items: center;
    box-shadow: 0 -7px 3px -6px rgba(0, 0, 0, 0.3) inset;

    &__close {
        font-size: 20px;
        cursor: pointer;
        line-height: 50px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 20px;
    }

    &__text {
        width: 100%;
        line-height: 18px;
        font-size: 16px;
        padding: 2px 50px;
        display: block;
        top: 0;
        left: 0;
        color: #ed5565;

        a {
            text-decoration: underline;
            color: #ed5565;

            &:hover {
                text-decoration: none;
            }
        }
        @media (max-width: 601px) {
            line-height: 14px;
            font-size: 12px;
        }
    }
}

.announce-add-phone {
    .wide-announce__text {
        padding-left: 10px;
        padding-right: 10px;
    }

    #popup_phone_not_verified {
        background: #fff;
        padding: 35px 30px;
        text-align: center;
        width: 400px;
        border-radius: 3px;
        font-size: 16px;

        .close {
            position: absolute;
            top: 12px;
            right: 15px;
        }

        strong {
            display: block;
            font-size: 18px;
            color: #e02729;
        }

        a {
            font-size: 18px;
        }
    }
}

@color_critical: red;
@color_warning: orange;
@color_success: #89ca89;

.main_status_block {
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 45px;
    height: 45px;
    display: block;
    outline: none;
    z-index: 1000;

    & > svg {
        width: 45px;
        height: 45px;
    }

    .fill_heart {
        fill: none;
    }

    .contour_heart {
        fill: #fff;
    }

    &.critical {
        .circle {
            fill: @color_critical;
            stroke: @color_critical;
        }

        border-radius: 50%;
        background: @color_critical;
        cursor: pointer;
        box-shadow: 0 0 0 fade(@color_critical, 50%);
        animation: pulse 2s infinite;
    }

    &.warning {
        .circle {
            fill: @color_warning;
            stroke: @color_warning;
        }
    }

    &.success {
        .circle {
            fill: @color_success;
            stroke: @color_success;
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 fade(@color_critical, 50%);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}

.wrap_text_block {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 5px;
    word-wrap: break-word;

    svg {
        width: 20px;
        height: 20px;
    }

    .critical span {
        color: @color_critical;
    }

    .warning span {
        color: @color_warning;
    }

    .success span {
        color: @color_success;
    }

    .mainStatus {
        line-height: 16px;
        color: #696969;
        padding-top: 6px;
        border-top: 1px solid #ababab;
        margin-top: 5px;
    }
}

.tippy-content .wrap_text_block{
    max-height: 300px;
    overflow: hidden auto;
}

#ordersTable {
    .type_order {
        background: fade(@blueColor, 20%);
    }

    .type_pre_order {
        background: fade(#f6fda7, 20%);
    }

    .type_stock {
        background: fade(@green2Color, 20%);
    }
}

.creationTime-th,
.id-th,
.status-th,
.amount-th {
    white-space: nowrap;
}

.horizontal-layout > * {
    display: inline;
    margin-right: 10px;
}

.category-view-switcher {
    position: absolute;
}

.category-view-switcher > a {
    color: #df2424;
    border-bottom: #df2424 dashed 1px;
    line-height: 14px;
    margin: 5px 0 0 10px;
    display: inline-block;
}

.accordion-open-button {
    background-color: rgba(227, 43, 45, 0.1);
    color: #666666;
    padding: 5px 5px 5px 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #666666;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(0, -50%) rotate(-45deg);
        width: 6px;
        height: 6px;
        background: transparent;
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
    }

    &.down {
        &:after {
            transform: translate(0, -50%) rotate(45deg);
            left: 13px;
            top: 48%;
        }
    }
}

#usersTable {
    .creationTime-th {
        max-width: 100px;
        width: 100px;
        min-width: 100px;
    }

    th.creationTime-th {
        white-space: unset;
    }

    .company-th {
        &.show-all {
            span {
                display: block;
            }

            .dashed-link {
                display: inline-block;
            }
        }

        span {
            display: none;

            &:nth-child(1), &:nth-child(2) {
                display: block;
            }
        }

        .dashed-link {
            display: inline-block;
        }
    }
}

.order-icons {
    display: inline-block;
    cursor: pointer;
    outline: none;
}

.wrapp_accordion {
    margin: 20px 0;

    &.wrapp_accordion_cart {
        background: #fafafa;
    }
}

.loading-addresses {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: none;
    z-index: 2;
}

.date-range-examples {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
}

.ex-inputs {
    position: relative;
}

.range-date {
    width: 40%;
    padding: 0
}

.ex-inputs-picker {
    position: absolute;
    left: 50%;
    top: calc(100% + 8px);
    opacity: 0;
    z-index: -20;
    transform: translateX(-50%);
    transition: all 0.25s;
}

.ex-inputs-picker-visible {
    opacity: 1;
    z-index: 1000;
}

.order-confirm-btn {
    display: flex;
    width: 122px;
    height: 32px;
    text-align:center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px
}

.result_update_photo {
    display: none;
    margin: 0 0 0 30px;
    max-width: 100%;
    font-weight: 700;
    &.proceed {
        display: inline-block;
        color: #e86565;
    }
    &.finish {
        display: inline-block;
        color: #89CA89;
    }
}

#statusBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .status_content {
        display: flex;
        flex: 1;
        @media (min-width: 1200px) {
            dl dt {
                width: 150px;
            }

            dl dd {
                margin-left: 150px;
            }
        }
    }

    .wrapper_boxlabel {
        min-height: 43px;

        .pull-left {
            display: flex;
            flex-direction: column;
            text-align: left;
        }

        .desc {
            font-size: 10px;
            line-height: 8px;
        }

        input {
            max-width: 170px;
            display: inline-block;
        }

        @media (min-width: 1200px) {
            input {
                max-width: 200px;
            }
        }

        @media (max-width: 900px) {
            input {
                max-width: 300px;
            }
        }
    }
}

.input_limit_amount {
    &.error {
        input {
            border: 1px solid #ed5565;
        }
    }
}

.breadcrumb-list {
    padding: 0 25px;
    a {
        color: #ed1c24;
        &:hover {
            text-decoration: underline;
        }
    }
}

.wrapper-pagination {
    width: 100%;
    display: block;
    float: left;
    margin: 0;
    padding-bottom: 6px;
    .pagination {
        width: auto;
    }
}

.branded-catalog .btn-circle.btn-lg {
    padding: 10px 10px;
}

.mb-5px {
    margin-bottom: 5px;
}

.onoffswitch-switch {
    transition: 0.01s;
}

.onoffswitch-inner {
    transition: 0.01s;
}

.mb-15px {
    margin-bottom: 15px;
}

.table-hidden-product {
    & .th-user {
        width: 130px;
    }
    & .td-user {
        width: 150px;
    }
    & .td-name {
        max-width: 400px;
    }
    & .th-notAuthorize {
        width: 75px;
    }
    & .td-notAuthorize {
        width: 100px;
    }
}

@media (max-width: @screen-xs-max) {
    .pull-right-xs {
        .pull-right()
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .pull-right-sm {
        .pull-right();
    }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .pull-right-md {
        .pull-right();
    }
}

@media (min-width: @screen-lg-min) {
    .pull-right-lg {
        .pull-right();
    }
}

.form-group .form-group-label-normal-weight {
    font-weight: normal;
}

.chosen-container .chosen-results li em {
    text-decoration: none !important;
}

.btn-group-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
input.phone-code {
    font-family: monospace;
    letter-spacing: 10px;
}

.descr {
    margin-bottom: 20px;
    display:block;
}

.btn.btn-input {
    font-size: 14px;
    border-radius: 0;
}

.check_pattern_code {
    margin-bottom: 10px;
    flex-grow: 1;

    label {
        font-weight: 400;
        display: flex;
        font-size: 16px;
        flex-wrap: nowrap;
        justify-content: end;
        align-items: center;
        margin: 0;

        & > div {
            margin-right: 10px;
        }
    }

    &.absolute_merge {
        position: absolute;
        right: 0;
        top: -35px;
    }
}

.symbol-infinit {
    font-size: 24px;
    line-height: 1;
}

.warning-icon {
    background: url(../img/icon-warning.png) 0 0 transparent no-repeat;
    min-width: 16px !important;
    min-height: 16px !important;
    background-size: contain;
}

td .warning-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.company-tippy {
    font-size: 12px;
    padding: 5px;
}

.inline {
    display: inline-block;
}

.wide-percent-85 {
    width: 85%;
}

.dashboard-last-orders .wrapper_button-show-more {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-show-more {
        color: @primaryColorText;
        outline: none;
        border: 0;
        background: transparent;
        padding: 6px;

        .span__wrapper-show-more {
            border-bottom: 1px dotted;
        }
    }
}

.cartTable {
    width: 100%;
    table-layout: fixed;
    tr.block-info,  tr.block-category {
        border-style: hidden;
        background: white;
    }

    table.table.cart {
        display: block;
    }

    tbody.scrollable {
        display: block;
        max-height: 70vh;
        overflow-y: auto;
    }

    tr.block-category {
        display: table;
        width: 100%;
    }

    tbody.block-info {
        display: table;
        width: 100%;
    }

    td.cell.cell-index	{
        width: 4%;
    }

    td.cell.cell-image	{
        width: 5%;
    }

    td.cell.cell-sku.art-code	{
        width: 15%;
        word-break: break-word;
    }

    td.cell.cell-barcode.barcode	{
        width: 15%;
        word-break: break-word;
    }

    td.cell.cell-name	{
        width: 15%;
        word-break: break-word;
    }

    td.cell.cell-color 	{
        width: 7%;
        word-break: break-word;
    }

    td.cell.cell-size {
        width: 9%;
        word-break: break-word;
    }

    td.cell.cell-price	{
        width: 7%;
    }

    td.cell.cell-availableCount {
        width: 8%;
    }

    td.cell.cell-delayCount {
        width: 6%;
    }

    td.cell.count-box	{
        width: 10%;
    }

    td.cell.cell-amount {
        width: 10%;
    }

    td.cell.cell-delete {
        width: 4%;
    }
}
