.beauty-dropdown{
    @padding: 15px;
    @activeColor: @primaryColor;
    position: relative;
    &.opened{
        .beauty-dropdown__drop-menu{
            display: block;
        }
    }
    &__drop-menu{
        display: none;
        position: absolute;
        padding: @padding 0;
        left: -@padding;
        top: -@padding - 4px;
        background-color: white;
        box-shadow: 0 1px 10px rgba(0,0,0,0.3);
        color: #666;
        z-index: 9;
        max-height: 300px;
        overflow-y: auto;
    }
    &__drop-menu-row{
        padding: 4px @padding;
        cursor: pointer;
        &.active{
            color: @activeColor;
        }
        &:hover{
            background-color: fade(@activeColor, 5%);
        }
    }
}
