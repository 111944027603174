// Variables
@toggle-enabled-bg: #86d993;
@toggle-enabled-color: #FFFFFF;
@toggle-enabled-tab-bg: #FFFFFF;

@toggle-disabled-bg: #de474e;
@toggle-disabled-color: #FFFFFF;
@toggle-disabled-tab-bg: #FFFFFF;

@size: 14px;

/** FROM https://codepen.io/labithiotis/pen/byskq **/
/** EXAMPLE
  <label class="styled-switch">
    <input type="checkbox" />
    <span data-on="1" data-off="0"></span>
  </label>
**/

.styled-switch {
    position: relative;
    outline: 0;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    margin: 0 0 5px 0;
    font-size: @size;

    // add default box-sizing for this scope
    &, &:after, &:before,
    & *, & *:after, & *:before,
    & + .styled-switch-btn {
        box-sizing: border-box;
        &::selection{
            background: none;
        }
    }

    span {
        position: relative;
        display: block;
        height: 1.8em;
        line-height: 1.2em;
        overflow: hidden;
        font-weight: normal;
        text-align: center;
        border-radius: 2em;
        padding: 0.2em 1em;
        border: 1px solid darken(@toggle-enabled-tab-bg, 2);
        box-shadow:
                inset 0 2px 0 rgba(0,0,0,.2),
                0 2px 0 rgba(255,255,255,0.7);

        transition:
                color 0.3s ease,
                padding 0.3s ease-in-out,
                background 0.3s ease-in-out;

        &:before {
            position: relative;
            display: block;
            line-height: 1.3em;
            padding: 0 0.2em;
            font-size: 1em;
        }

        &:after {
            position: absolute;
            display: block;
            content: '';
            border-radius: 2em;
            width: 1.3em;
            height: 1.3em;
            margin-left: -1.45em;
            top: 0.2em;
            background: @toggle-enabled-tab-bg;
            transition:
                    left 0.3s cubic-bezier( 0.175, 0.885, 0.320, 0.97 ),
                    background 0.3s ease-in-out;

        }

    }

    input[type="checkbox"]{

        display: none !important;

        // Off
        &:not(:checked) {
            + span {
                background: @toggle-disabled-bg;
                color: @toggle-disabled-color;
                padding-left: 1.6em;
                padding-right: 0.4em;
                &:before {
                    content: attr(data-off);
                    color: @toggle-disabled-color;
                }
                &:after {
                    background: @toggle-disabled-tab-bg;
                    left: 1.6em;
                }
            }
        }

        // On
        &:checked {
            + span {
                background: @toggle-enabled-bg;
                color: @toggle-enabled-color;
                padding-left: 0.4em;
                padding-right: 1.6em;
                &:before {
                    content: attr(data-on);
                }
                &:after {
                    background: @toggle-enabled-tab-bg;
                    left: 100%;
                }
            }
        }

        // Disabled
        &:disabled, &:disabled + span, &:read-only, &:read-only + span {
            cursor: not-allowed;
        }

    }

}

.styled-switch-gray {
    input[type="checkbox"]{
        &:not(:checked) {
            + span {
                background: lighten(desaturate(@toggle-enabled-bg, 100%), 20%);
                color: darken(desaturate(@toggle-disabled-color, 100%), 40%);
                &:before {
                    color: darken(desaturate(@toggle-disabled-color, 100%), 40%);
                }
                &:after {
                    background: desaturate(@toggle-disabled-tab-bg, 100%);
                }
            }
        }
    }
}

.styled-switch-inline {
    display: inline-block !important;
    vertical-align: top;

    &.styled-switch {
        font-size: 16px;
        span {
            min-width: 50px;
            &:before {
                line-height: 1.4em;
                padding-left: 0.4em;
                padding-right: 0.4em;
            }
        }
    }

}

.styled-switch-inline-label {
    display: inline-block !important;
    vertical-align: top;
    line-height: 26px;
}