/**
 * Стили для показа во всплывающем окне
 * истории активности пользователя
 */

// Ширина колонки со значками переключения и показа истории
.switch-th {
    min-width: 40px;

    & > a {
        margin-left: 5px;
        outline: none;

        i {
            outline: none;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

// Стиль всей подсказки
.fixed-size-history {
    height: auto;
    max-height: 400px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    // Отдельная запись
    .access-entry-row {
        width: 240px;
        padding-left: 50px;
        text-align: left;
        margin-bottom: 8px;

        &.new_date_line {
            margin-top: -8px;
        }

        // Ячейка с краткой датой
        .date-cell {
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            // Вертикальная красная линия
            &:before {
                content: '';
                position: fixed;
                top: 4px;
                left: 33px;
                height: 98%;
                width: 2px;
                background: red;
                z-index: 0;
            }

            // Кружок с краткой датой внутри
            .little-date {
                border: solid red 2px;
                border-radius: 50%;
                padding-top: 4px;
                padding-bottom: 5px;
                z-index: 1;
                width: 32px;
                height: 32px;
                line-height: 20px;
                color: red;
                background-color: #fff;
                font-weight: bold;
            }
        }

        // Заполнитель пространства напротив даты в кружке
        // (содержит пункитрную линию)
        .history-filler {
            padding-top: 16px;
            margin-bottom: 16px;

            &:before {
                content: '';
                position: absolute;
                height: 1px;
                width: calc(~'100% + 30px');
                z-index: 0;
                top: 16px;
                left: -18px;
                border-bottom: 1px dashed #bbb;
            }
        }

        // Полная дата события
        .access-full-date {
            color: #606060;
        }
    }

    // Кнопка подгрузки следующего куска истории
    .load-history {
        text-decoration: underline;
        margin: 12px 0 15px;
        display: block;
    }
}

