.parentStoresSortable {
    .storeParentBlock {
        margin-bottom: 10px;
        padding: 30px 10px 10px 10px;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        background-color: #f0f0f0;
        cursor: move;

        &__move-button {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg width='100px' height='100px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'/%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M4 18L20 18' stroke='%23676a6c' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M4 12L20 12' stroke='%23676a6c' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M4 6L20 6' stroke='%23676a6c' stroke-width='2' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            border-bottom: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            border-bottom-right-radius: 5px;
            box-shadow: 2px 2px 5px -5px rgba(0, 0, 0, 0.3);
            background-size: 25px;
        }

        &__number, &__number~span {
            display: block;
            font-size: 1.5em;
            font-weight: 700;
        }

        &__remove-button {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            font-size: 2rem;
            display: block;
            color: @primaryColor;
            z-index: 1;
        }
    }
}
