@hold:#65afde;
@pending:#23c6c8;
@processing:#60a3ce;
@accepted:#1c84c6;
@packed:#ffb91b;
@shipped:#f8ac59;
@delivered:#1ab394;
@closed:#ed5565;
@canceled:#f18585;

.label-hold, .badge-hold {
  background-color: @hold;
  color: #ffffff;
}
.label-pending, .badge-pending {
  background-color: @pending;
  color: #ffffff;
}
.label-processing, .badge-processing {
  background-color: @processing;
  color: #ffffff;
}
.label-accepted, .badge-accepted {
  background-color: @accepted;
  color: #ffffff;
}
.label-packed, .badge-packed {
  background-color: @packed;
  color: #ffffff;
}
.label-shipped, .badge-shipped {
  background-color: @shipped;
  color: #ffffff;
}
.label-delivered, .badge-delivered {
  background-color: @delivered;
  color: #ffffff;
}
.label-closed, .badge-closed {
  background-color: @closed;
  color: #ffffff;
}
.label-canceled, .badge-canceled {
  background-color: @canceled;
  color: #ffffff;
}

.text_status-hold {
  color: @hold;
}
.text_status-pending {
  color: @pending;
}
.text_status-processing {
  color: @processing;
}
.text_status-accepted {
  color: @accepted;
}
.text_status-packed {
  color: @packed;
}
.text_status-shipped {
  color: @shipped;
}
.text_status-delivered {
  color: @delivered;
}
.text_status-closed {
  color: @closed;
}
.text_status-canceled {
  color: @canceled;
}


.btn-hold {
  background-color: @hold;
  border-color: @hold;
  color: #FFFFFF;
}

.btn-hold:hover, .btn-hold:focus, .btn-hold:active, .btn-hold.active, .open .dropdown-toggle.btn-hold, .btn-hold:active:focus, .btn-hold:active:hover, .btn-hold.active:hover, .btn-hold.active:focus {
  background-color: darken(@hold, 3%);
  border-color: darken(@hold, 3%);
  color: #FFFFFF;
}

.btn-hold:active, .btn-hold.active, .open .dropdown-toggle.btn-hold {
  background-image: none;
}

.btn-hold.disabled, .btn-hold.disabled:hover, .btn-hold.disabled:focus, .btn-hold.disabled:active, .btn-hold.disabled.active, .btn-hold[disabled], .btn-hold[disabled]:hover, .btn-hold[disabled]:focus, .btn-hold[disabled]:active, .btn-hold.active[disabled], fieldset[disabled] .btn-hold, fieldset[disabled] .btn-hold:hover, fieldset[disabled] .btn-hold:focus, fieldset[disabled] .btn-hold:active, fieldset[disabled] .btn-hold.active {
  background-color: lighten(@hold, 4%);
  border-color: lighten(@hold, 4%);
}
.btn-pending {
  background-color: @pending;
  border-color: @pending;
  color: #FFFFFF;
}

.btn-pending:hover, .btn-pending:focus, .btn-pending:active, .btn-pending.active, .open .dropdown-toggle.btn-pending, .btn-pending:active:focus, .btn-pending:active:hover, .btn-pending.active:hover, .btn-pending.active:focus {
  background-color: darken(@pending, 3%);
  border-color: darken(@pending, 3%);
  color: #FFFFFF;
}

.btn-pending:active, .btn-pending.active, .open .dropdown-toggle.btn-pending {
  background-image: none;
}

.btn-pending.disabled, .btn-pending.disabled:hover, .btn-pending.disabled:focus, .btn-pending.disabled:active, .btn-pending.disabled.active, .btn-pending[disabled], .btn-pending[disabled]:hover, .btn-pending[disabled]:focus, .btn-pending[disabled]:active, .btn-pending.active[disabled], fieldset[disabled] .btn-pending, fieldset[disabled] .btn-pending:hover, fieldset[disabled] .btn-pending:focus, fieldset[disabled] .btn-pending:active, fieldset[disabled] .btn-pending.active {
  background-color: lighten(@pending, 4%);
  border-color: lighten(@pending, 4%);
}
.btn-processing {
  background-color: @processing;
  border-color: @processing;
  color: #FFFFFF;
}

.btn-processing:hover, .btn-processing:focus, .btn-processing:active, .btn-processing.active, .open .dropdown-toggle.btn-processing, .btn-processing:active:focus, .btn-processing:active:hover, .btn-processing.active:hover, .btn-processing.active:focus {
  background-color: darken(@processing, 3%);
  border-color: darken(@processing, 3%);
  color: #FFFFFF;
}

.btn-processing:active, .btn-processing.active, .open .dropdown-toggle.btn-processing {
  background-image: none;
}

.btn-processing.disabled, .btn-processing.disabled:hover, .btn-processing.disabled:focus, .btn-processing.disabled:active, .btn-processing.disabled.active, .btn-processing[disabled], .btn-processing[disabled]:hover, .btn-processing[disabled]:focus, .btn-processing[disabled]:active, .btn-processing.active[disabled], fieldset[disabled] .btn-processing, fieldset[disabled] .btn-processing:hover, fieldset[disabled] .btn-processing:focus, fieldset[disabled] .btn-processing:active, fieldset[disabled] .btn-processing.active {
  background-color: lighten(@processing, 4%);
  border-color: lighten(@processing, 4%);
}
.btn-accepted {
  background-color: @accepted;
  border-color: @accepted;
  color: #FFFFFF;
}

.btn-accepted:hover, .btn-accepted:focus, .btn-accepted:active, .btn-accepted.active, .open .dropdown-toggle.btn-accepted, .btn-accepted:active:focus, .btn-accepted:active:hover, .btn-accepted.active:hover, .btn-accepted.active:focus {
  background-color: darken(@accepted, 3%);
  border-color: darken(@accepted, 3%);
  color: #FFFFFF;
}

.btn-accepted:active, .btn-accepted.active, .open .dropdown-toggle.btn-accepted {
  background-image: none;
}

.btn-accepted.disabled, .btn-accepted.disabled:hover, .btn-accepted.disabled:focus, .btn-accepted.disabled:active, .btn-accepted.disabled.active, .btn-accepted[disabled], .btn-accepted[disabled]:hover, .btn-accepted[disabled]:focus, .btn-accepted[disabled]:active, .btn-accepted.active[disabled], fieldset[disabled] .btn-accepted, fieldset[disabled] .btn-accepted:hover, fieldset[disabled] .btn-accepted:focus, fieldset[disabled] .btn-accepted:active, fieldset[disabled] .btn-accepted.active {
  background-color: lighten(@accepted, 4%);
  border-color: lighten(@accepted, 4%);
}
.btn-packed {
  background-color: @packed;
  border-color: @packed;
  color: #FFFFFF;
}

.btn-packed:hover, .btn-packed:focus, .btn-packed:active, .btn-packed.active, .open .dropdown-toggle.btn-packed, .btn-packed:active:focus, .btn-packed:active:hover, .btn-packed.active:hover, .btn-packed.active:focus {
  background-color: darken(@packed, 3%);
  border-color: darken(@packed, 3%);
  color: #FFFFFF;
}

.btn-packed:active, .btn-packed.active, .open .dropdown-toggle.btn-packed {
  background-image: none;
}

.btn-packed.disabled, .btn-packed.disabled:hover, .btn-packed.disabled:focus, .btn-packed.disabled:active, .btn-packed.disabled.active, .btn-packed[disabled], .btn-packed[disabled]:hover, .btn-packed[disabled]:focus, .btn-packed[disabled]:active, .btn-packed.active[disabled], fieldset[disabled] .btn-packed, fieldset[disabled] .btn-packed:hover, fieldset[disabled] .btn-packed:focus, fieldset[disabled] .btn-packed:active, fieldset[disabled] .btn-packed.active {
  background-color: lighten(@packed, 4%);
  border-color: lighten(@packed, 4%);
}
.btn-shipped {
  background-color: @shipped;
  border-color: @shipped;
  color: #FFFFFF;
}

.btn-shipped:hover, .btn-shipped:focus, .btn-shipped:active, .btn-shipped.active, .open .dropdown-toggle.btn-shipped, .btn-shipped:active:focus, .btn-shipped:active:hover, .btn-shipped.active:hover, .btn-shipped.active:focus {
  background-color: darken(@shipped, 3%);
  border-color: darken(@shipped, 3%);
  color: #FFFFFF;
}

.btn-shipped:active, .btn-shipped.active, .open .dropdown-toggle.btn-shipped {
  background-image: none;
}

.btn-shipped.disabled, .btn-shipped.disabled:hover, .btn-shipped.disabled:focus, .btn-shipped.disabled:active, .btn-shipped.disabled.active, .btn-shipped[disabled], .btn-shipped[disabled]:hover, .btn-shipped[disabled]:focus, .btn-shipped[disabled]:active, .btn-shipped.active[disabled], fieldset[disabled] .btn-shipped, fieldset[disabled] .btn-shipped:hover, fieldset[disabled] .btn-shipped:focus, fieldset[disabled] .btn-shipped:active, fieldset[disabled] .btn-shipped.active {
  background-color: lighten(@shipped, 4%);
  border-color: lighten(@shipped, 4%);
}
.btn-delivered {
  background-color: @delivered;
  border-color: @delivered;
  color: #FFFFFF;
}

.btn-delivered:hover, .btn-delivered:focus, .btn-delivered:active, .btn-delivered.active, .open .dropdown-toggle.btn-delivered, .btn-delivered:active:focus, .btn-delivered:active:hover, .btn-delivered.active:hover, .btn-delivered.active:focus {
  background-color: darken(@delivered, 3%);
  border-color: darken(@delivered, 3%);
  color: #FFFFFF;
}

.btn-delivered:active, .btn-delivered.active, .open .dropdown-toggle.btn-delivered {
  background-image: none;
}

.btn-delivered.disabled, .btn-delivered.disabled:hover, .btn-delivered.disabled:focus, .btn-delivered.disabled:active, .btn-delivered.disabled.active, .btn-delivered[disabled], .btn-delivered[disabled]:hover, .btn-delivered[disabled]:focus, .btn-delivered[disabled]:active, .btn-delivered.active[disabled], fieldset[disabled] .btn-delivered, fieldset[disabled] .btn-delivered:hover, fieldset[disabled] .btn-delivered:focus, fieldset[disabled] .btn-delivered:active, fieldset[disabled] .btn-delivered.active {
  background-color: lighten(@delivered, 4%);
  border-color: lighten(@delivered, 4%);
}
.btn-closed {
  background-color: @closed;
  border-color: @closed;
  color: #FFFFFF;
}

.btn-closed:hover, .btn-closed:focus, .btn-closed:active, .btn-closed.active, .open .dropdown-toggle.btn-closed, .btn-closed:active:focus, .btn-closed:active:hover, .btn-closed.active:hover, .btn-closed.active:focus {
  background-color: darken(@closed, 3%);
  border-color: darken(@closed, 3%);
  color: #FFFFFF;
}

.btn-closed:active, .btn-closed.active, .open .dropdown-toggle.btn-closed {
  background-image: none;
}

.btn-closed.disabled, .btn-closed.disabled:hover, .btn-closed.disabled:focus, .btn-closed.disabled:active, .btn-closed.disabled.active, .btn-closed[disabled], .btn-closed[disabled]:hover, .btn-closed[disabled]:focus, .btn-closed[disabled]:active, .btn-closed.active[disabled], fieldset[disabled] .btn-closed, fieldset[disabled] .btn-closed:hover, fieldset[disabled] .btn-closed:focus, fieldset[disabled] .btn-closed:active, fieldset[disabled] .btn-closed.active {
  background-color: lighten(@closed, 4%);
  border-color: lighten(@closed, 4%);
}
.btn-canceled {
  background-color: @canceled;
  border-color: @canceled;
  color: #FFFFFF;
}

.btn-canceled:hover, .btn-canceled:focus, .btn-canceled:active, .btn-canceled.active, .open .dropdown-toggle.btn-canceled, .btn-canceled:active:focus, .btn-canceled:active:hover, .btn-canceled.active:hover, .btn-canceled.active:focus {
  background-color: darken(@canceled, 3%);
  border-color: darken(@canceled, 3%);
  color: #FFFFFF;
}

.btn-canceled:active, .btn-canceled.active, .open .dropdown-toggle.btn-canceled {
  background-image: none;
}

.btn-canceled.disabled, .btn-canceled.disabled:hover, .btn-canceled.disabled:focus, .btn-canceled.disabled:active, .btn-canceled.disabled.active, .btn-canceled[disabled], .btn-canceled[disabled]:hover, .btn-canceled[disabled]:focus, .btn-canceled[disabled]:active, .btn-canceled.active[disabled], fieldset[disabled] .btn-canceled, fieldset[disabled] .btn-canceled:hover, fieldset[disabled] .btn-canceled:focus, fieldset[disabled] .btn-canceled:active, fieldset[disabled] .btn-canceled.active {
  background-color: lighten(@canceled, 4%);
  border-color: lighten(@canceled, 4%);
}

.change_status {
  &.disabled {
    opacity: 1;
    cursor: default;
    .statuses-switcher__scroll-list{
      opacity: 0.5;
    }
  }
  .statuses-switcher{
    &__button{
      display: inline-block;
      text-decoration: underline dotted @primaryColor;
      cursor: pointer;
      &-hold .selected_name_status {
        text-decoration: underline dotted @hold;
      }
      &-pending .selected_name_status {
        text-decoration: underline dotted @pending;
      }
      &-processing .selected_name_status {
        text-decoration: underline dotted @processing;
      }
      &-accepted .selected_name_status {
        text-decoration: underline dotted @accepted;
      }
      &-packed .selected_name_status {
        text-decoration: underline dotted @packed;
      }
      &-shipped .selected_name_status {
        text-decoration: underline dotted @shipped;
      }
      &-delivered .selected_name_status {
        text-decoration: underline dotted @delivered;
      }
      &-closed .selected_name_status {
        text-decoration: underline dotted @closed;
      }
      &-canceled .selected_name_status {
        text-decoration: underline dotted @canceled;
      }
    }
    &.open{
      .statuses-switcher__current-status{
        &_absolute{
          display: block;
        }
      }
      .statuses-switcher__list-of-statuses{
        display: block;
      }
    }
    &__current-status{
      &_static{
        display: block;
      }
      &_absolute{
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
      }
    }
    &__list-of-statuses{
      display: none;
      padding: 30px 0 15px;
      background-color: white;
      position: absolute;
      z-index: 9;
      text-align: left;
      top: -6px;
      left: -25px;
      box-shadow: 0 1px 5px rgba(0,0,0,0.4);
      line-height: 22px;
      white-space: nowrap;
    }
    &__list-row{
      display: block;
      padding: 0 25px;
      &:hover{
        background-color: #eeeeee;
      }
      &.active{
        background-color: rgba(223, 37, 37, 0.28);
        color: white;
        cursor: default;
      }
      &-hold {
        &:hover {
          color: @hold;
        }
        &.active {
          background-color: @hold;
          color: white;
        }
      }
      &-pending {
        &:hover {
          color: @pending;
        }
        &.active {
          background-color: @pending;
          color: white;
        }
      }
      &-processing {
        &:hover {
          color: @processing;
        }
        &.active {
          background-color: @processing;
          color: white;
        }
      }
      &-accepted {
        &:hover {
          color: @accepted;
        }
        &.active {
          background-color: @accepted;
          color: white;
        }
      }
      &-packed {
        &:hover {
          color: @packed;
        }
        &.active {
          background-color: @packed;
          color: white;
        }
      }
      &-shipped {
        &:hover {
          color: @shipped;
        }
        &.active {
          background-color: @shipped;
          color: white;
        }
      }
      &-delivered {
        &:hover {
          color: @delivered;
        }
        &.active {
          background-color: @delivered;
          color: white;
        }
      }
      &-closed {
        &:hover {
          color: @closed;
        }
        &.active {
          background-color: @closed;
          color: white;
        }
      }
      &-canceled {
        &:hover {
          color: @canceled;
        }
        &.active {
          background-color: @canceled;
          color: white;
        }
      }
    }
  }
}