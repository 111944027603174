.branch-cafe {
    .type-switcher__button.active,
    .months-switcher__current-month,
    .type-switcher-senior-manager__current-type,
    .main-amount,
    .countries-stat__empty-box,
    .dashboard-last-orders .wrapper_button-show-more .button-show-more,
    .stores_catalog-switcher__current-store_catalog,
    header .main-menu .menu-item.with-submenu .submenu .submenu-footer a,
    header .main-menu .catalog-items .menu-item,
    .catalog-top-panel .categories-list .item,
    .catalog-top-panel .submenu .child.first-level {
        color: #000000;
    }

    .dashboard-top-buttons__button {
        color: #000000 !important;

        &::before {
            background-color: #000000;
        }
    }

    .catalog-top-panel .categories-list .item:hover {
        color: #666666;
    }

    header .main-menu .catalog-items .menu-item.pageCategoryModel {
        color: #ffffff;
    }

    header .main-menu .menu-item.with-submenu .submenu .submenu-title,
    .months-switcher__button,
    .type-switcher-senior-manager__button,
    .stores_catalog-switcher__button,
    .type-switcher__button.active {
        border-bottom-color: #000000;
    }

    .stores_catalog-switcher__list-row.active,
    .months-switcher__list-row.active {
        background-color: rgba(72, 72, 72, 0.28);
    }
}
