.d-inline-block {
    display: inline-block;
}

div, a {
    position: relative;
}

a {
    color: #337ab7;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    &:focus,
    &:hover {
        text-decoration: none;
        color: #23527c;
    }
}

.showed {
    display: block;
}

.hide {
    display: none;
}

.pointer {
    cursor: pointer;
}

* {
    outline: none !important;
}

body {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
}

@media (max-width: 900px) {
    header {
        font-size: 9px;
    }

    body {
        font-size: 13px;
    }

    .btn {
        font-size: 16px;
    }

    .inner {
        padding: 0 2px;
    }
}

.no-photo {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #f4f4f4;

    .fa {
        margin: auto;
        color: white;
        font-size: 60px;
        text-shadow: 1px 1px 0 rgb(220, 220, 220);
    }
}

.dashed-link {
    border-bottom: 1px dashed #337ab7;
}

.dashed-gray {
    border-bottom: 1px dashed #666;
}

.dashed-red-link {
    color: #E32B2D;
    border-bottom: 1px dashed #E32B2D;

    &:hover {
        border-bottom-color: transparent;
    }
}

.styled-fancybox{
    &.background-white{
        background-color: white;
    }
    .fancybox-skin{
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
        .fancybox-outer{
            .fancybox-inner{
                > .fancybox-close-button{
                    display: block;
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    z-index: 2;
                    .material-icons{
                        font-size: 23px;
                    }
                }
            }
        }
    }
    .mdl-card{
        padding: 20px;
        > h5{
            margin-top: 5px;
        }
    }
    &.max-size{
        width: 96% !important;
        height: 95% !important;
        left: 2% !important;
        top: 2.5% !important;
        background-color: white;
        box-sizing: border-box;
        padding: 10px;
        .fancybox-skin{
            height: 100% !important;
            .fancybox-outer{
                height: 100%;
            }
        }
        .fancybox-inner{
            width: 100% !important;
            height: 100% !important;
            left: 0 !important;
            top: 0 !important;
        }
    }
    &.close-popup-fancybox {
        .close-popup {
            position: absolute;
            right: -25px;
            top: -25px;
            color: #cacaca;
            font-size: 30px;
            font-weight: 500;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            z-index: 2;
            &:hover {
                color: #e02729;
            }
        }
    }
    &.overflow-visible{
        .fancybox-skin{
            .fancybox-outer{
                .fancybox-inner{
                    overflow: visible!important;
                }
            }
        }
    }
}
.fancybox-overlay{
    background: rgba(0, 0, 0, 0.4);
}
.disabled {
    opacity: 0.5;
    cursor: default !important;
}

.fancybox-lock {
    overflow: visible !important;
    padding-right: 17px;
    header{
        padding-right: 17px;
    }
}

.chosen-container{
    text-align: left;
    .chosen-single{
        outline: none!important;
    }
    .chosen-single div b{
        background-position: 3px 7px;
    }
    .chosen-single abbr {
        top: 10px;
        right: 24px;
        cursor: pointer;
    }
    &.chosen-container-active{
        &.chosen-with-drop{
            .chosen-single div b {
                background-position: -15px 6px;
            }
            .chosen-single{
                background: white;
            }
        }
    }
    .chosen-results li.highlighted{
        //noinspection LessResolvedByNameOnly
        background: @primaryColor;
    }
}
.chosen-container-single .chosen-single span, .chosen-container-multi .chosen-choices li.search-field input[type=text]{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    position: relative;
    top: 1px;
}
.no-padding{
    padding: 0!important;
}

.select2-container{
    &.select2-container--open{
        .select2-selection.select2-selection--single {
            border: 1px solid #aaa;
            border-bottom: 0;
        }
    }
    .select2-selection.select2-selection--single {
        border: 1px solid #e5e6e7;
        border-radius: 1px;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        height: 34px;
        outline: none!important;
        .select2-selection__rendered{
            line-height: 34px;
            padding-left: 12px;
            .select2-selection__clear{
                font-size: 20px;
                color: #777;
            }
        }
        .select2-selection__arrow{
            height: 34px;
        }
    }
    &.select2-container--default{
        .select2-results__option--highlighted[aria-selected]{
            //noinspection LessResolvedByNameOnly
            background-color: @primaryColor;
            color: white;
        }
        &.select2-container--open{
            .select2-dropdown{
                .select2-search__field{
                    outline: none!important;
                }
            }
        }
    }
}

.ui-autocomplete{
    &.ui-menu{
        position: absolute !important;
        background-color: #fff;
        padding: 5px 0;
        border: 1px solid #1ab394;
        border-top: none;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
        list-style: none;
        font-size: 13px;
        margin: -2px 0;
        top: 100% !important;
        left: 0 !important;
        z-index: 1000;
        text-align: left;
        box-sizing: border-box;
        width: 100%!important;
        overflow: auto;
        max-height: 200px;
        > .ui-menu-item{
            cursor: pointer;
            padding: 0 12px;
            &:hover{
                background-color: #DF2424;
                color: white;
                .ui-state-focus, .ui-state-active{
                    color: white;
                    text-decoration: none;
                }
            }
            .ui-state-focus, .ui-state-active{
                border: none;
                background: transparent;
                text-decoration: underline;
            }
        }
    }
}
.ui-helper-hidden-accessible {
    display: none;
}

.pt-6 {
    padding-top: 6px;
}
@media (min-width: 768px) {
    .pt-sm-6 {
        padding-top: 6px;
    }
}
