/* BEGIN Thin */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Thin/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/Roboto/Thin/Roboto-Thin.woff") format("woff"),
    url("../fonts/Roboto/Thin/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;}
/* END Thin */
/* BEGIN Thin Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.woff2") format("woff2"),
    url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.woff") format("woff"),
    url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;}
/* END Thin Italic */
/* BEGIN Light */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Light/Roboto-Light.eot");
    src: url("../fonts/Roboto/Light/Roboto-Light.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Light/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto/Light/Roboto-Light.woff") format("woff"),
    url("../fonts/Roboto/Light/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;}
/* END Light */
/* BEGIN Light Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/LightItalic/Roboto-LightItalic.woff2") format("woff2"),
    url("../fonts/Roboto/LightItalic/Roboto-LightItalic.woff") format("woff"),
    url("../fonts/Roboto/LightItalic/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot");
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto/Regular/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot");
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto/Regular/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;}
/* END Regular */
/* BEGIN Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Italic/Roboto-Italic.woff2") format("woff2"),
    url("../fonts/Roboto/Italic/Roboto-Italic.woff") format("woff"),
    url("../fonts/Roboto/Italic/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Italic/Roboto-Italic.woff2") format("woff2"),
    url("../fonts/Roboto/Italic/Roboto-Italic.woff") format("woff"),
    url("../fonts/Roboto/Italic/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;}
/* END Italic */
/* BEGIN Medium */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Medium/Roboto-Medium.eot");
    src: url("../fonts/Roboto/Medium/Roboto-Medium.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Medium/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto/Medium/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto/Medium/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;}
/* END Medium */
/* BEGIN Medium Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.woff2") format("woff2"),
    url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.woff") format("woff"),
    url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;}
/* END Medium Italic */
/* BEGIN Bold */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot");
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto/Bold/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot");
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto/Bold/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff2") format("woff2"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff") format("woff"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;}
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff2") format("woff2"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff") format("woff"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;}
/* END Bold Italic */
/* BEGIN Black */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Black/Roboto-Black.woff2") format("woff2"),
    url("../fonts/Roboto/Black/Roboto-Black.woff") format("woff"),
    url("../fonts/Roboto/Black/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;}
/* END Black */
/* BEGIN Black Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.woff2") format("woff2"),
    url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.woff") format("woff"),
    url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;}
/* END Black Italic */



@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansLight/OpenSansLight.eot");
    src: url("../fonts/OpenSans/OpenSansLight/OpenSansLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansLight/OpenSansLight.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansLight/OpenSansLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.eot");
    src: url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot");
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot");
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot");
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot");
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansMedium/OpenSansMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansMediumItalic/OpenSansMediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot");
    src: url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot");
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot");
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.eot");
    src: url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}
