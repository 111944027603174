@media (max-width: 1023px){
    .inner {
        padding: 0 2px;
    }
    .navbar-top-links .dropdown-alerts {
        margin-left: -54px;
    }

    header .main-menu .catalog-items .menu-item {
        padding: 14px 5px 4px;
    }
    header .main-menu .menu-item.with-submenu.user-menu {
        min-width: 120px;
        .user-fullname {
            display: none;
        }

        .user-logo {
            display: inline-grid;
        }
    }
}

@media (max-width: 900px) {
    #ordersTable {
        .actions-th {
            .btn-group {
                .flex-display(flex);
                .flex-direction(column);
                align-items: stretch;
            }
            .btn-xs {
                font-size: 14px;
                &:first-child {
                    margin-bottom: 5px;
                }
            }
        }
    }
    table.table tbody tr td {
        padding: 5px 5px;
        &.cell {
            white-space: nowrap;
        }
    }
    .fancybox-lock header {
        padding-right: 0;
    }

    header {
        font-size: 9px;
    }

    header .main-menu .catalog-items .menu-item {
        padding: 14px 10px 4px;
        font-size: 9px;
    }

    header .main-menu .catalog-items .menu-item.pageCategoryModel {
        margin: 0;
        z-index: 2;
    }

    header .main-menu .catalog-items .menu-item.active:before {
        background: none;
    }

    header .main-menu .menu-item {
        font-size: 9px;
    }

    header .main-menu .menu-item.cart-info {
        text-align: center;
        max-width: 146px;
    }

    header .main-menu .menu-item {
        padding: 15px 5px 4px;
        &.with-submenu {
            &.user-settings,
            &.user-notifications {
                padding: 15px 10px 4px;
                .submenu-title {
                    font-size: 13px;
                    padding: 10px;
                    a {
                        font-size: 10px;
                    }
                }
                .submenu-footer {
                    font-size: 13px;
                    padding: 10px;
                    a {
                        font-size: 10px;
                    }
                }
            }
            &.user-notifications {
                .submenu {
                    min-width: 300px;
                }
            }
            .submenu {
                .submenu-item-title {
                    font-size: 10px;
                    line-height: 12px;
                }
                .submenu-item-body {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
        }
    }

    header .main-menu > .pull-right {
        z-index: 2;

    }

    header .main-menu .menu-item.cart-info .weight,
    header .main-menu .menu-item.cart-info .cart-icon {
        display: none;
    }
    header .main-menu .menu-item.cart-info .left-block, 
    header .main-menu .menu-item.cart-info .right-block {
        float: none;
    }

    .cart-table-wrapper .table > tbody > tr > td.cell  {
        white-space: unset;
    }

    header .main-menu .menu-item.with-submenu.user-menu {
        min-width: 80px;
        max-width: 200px;
        padding-left: 10px;
        padding-top: 10px;
    }
    header .main-menu .menu-item.with-submenu.user-menu span {
        width: calc(~'100% - 20px');
        display: inline-block;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    header .main-menu .main-search.active {
        width: 415px;
        z-index: 99;
    }
    form.image-form .placeholder {
        font-size: 1.4rem;
    }

    header .main-menu .pull-left {
        z-index: 2;
    }

    header .main-menu .menu-item.with-submenu.user-menu {
        width: 50px;
        .user-fullname {
            display: none;
        }

        .user-logo {
            display: inline-grid;
        }
    }

    header .main-menu .catalog-items .menu-item {
        padding: 14px 5px 4px;
    }
    header .main-menu .menu-item.cart-info {
        z-index: 2;
    }

    table.table thead tr th {
        font-size: 11px;
        .mobile {
            display: inline-block;
        }
        .desktop {
            display: none;
        }
    }
    table.table tbody {
        .count-box {
            width: 50px;
        }
    }
    .catalog-page-wrapper {
        padding-top: 142px;
    }
    .wrapBanners {
        padding: 0;
        .banners-grid {
            padding: 0;
            margin-top: 0;
        }
        .banners-grid__filler {
            height: auto !important;
            clear: both;
            margin-bottom: 10px;
        }
    }
    .banners-grid__media.pointer {
        width: auto !important;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        height: auto !important;
        margin: 8px 4px 0 5px;
        display: inline-block;
        float: left;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .stretch-blocks{
        .flex-direction(column);
    }
    .close {
        font-size: 40px;
    }
    .catalog-top-panel {
        &.fixed{
            box-shadow: 0 0 4px rgba(0,0,0,0.4);
            .submenu{
                top: 56px;
            }
            .catalog-filter-panel{
                height: auto;
                padding: 6px 0;
                &:not(.filter_search_panel) {
                    border-bottom: 0;
                    height: auto;
                    padding: 6px 0;

                    .selector {
                        .selector-dropdown {
                            top: 58px;
                        }
                    }

                    .my-dropdown {
                        top: 16px;
                    }

                    .switch-box {
                        top: 36px;
                    }
                }
                &.filters:not(.open) {
                    height: 0;
                    padding: 0;
                }
                .filters:not(.filters-search) {
                    .filter{
                        font-size: 15px;
                    }
                    &.open {
                        border-top: 1px solid #E6E6E6;
                        margin-top: -1px;
                        box-shadow: 0 3px 4px -3px rgba(0, 0, 0, 0.4);
                        -webkit-transition: all 400ms 0ms ease, border 0ms 0ms ease;
                        -moz-transition: all 400ms 0ms ease, border 0ms 0ms ease;
                        -ms-transition: all 400ms 0ms ease, border 0ms 0ms ease;
                        -o-transition: all 400ms 0ms ease, border 0ms 0ms ease;
                        transition: all 400ms 0ms ease, border 0ms 0ms ease;
                    }
                }
                .icon-filters{
                    width: 26px;
                }
                .sort-type {
                    font-size: 15px;
                    .order-field {
                        bottom: -2px;
                    }
                }
                .current-category{
                    font-size: 15px;
                }

                .selector {
                    .selector-dropdown {
                        top: 58px;
                    }
                }

                .my-dropdown {
                    top: 16px;
                }
            }
            & > .inner {
                height: auto;
            }
            .categories-list{
                .item {
                    line-height: 33px;
                    font-size: 12px;
                }
            }

            .switch-box {
                top: 36px;
            }

            .catalog-filter-panel.filter_search_panel {
                .switch-box {
                    top: 30px;
                }
            }
        }
        .categories-list .item {
            line-height: 33px;
            float: left;
        }
        & > .inner {
            max-height: 86px;
            min-height: 52px;
            height: auto;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .submenu {
            width: auto;
            min-height: auto;
            filter: drop-shadow(0 2px 3px rgba(0,0,0,.3));
            padding: 10px 0;
            &:before {
                content: '';
                position: absolute;
                top: -30px;
                left: 30px;
                border: 16px solid transparent;
                border-bottom-color: #fff;
            }
            .column {
                width: auto;
            }
            .child {
                padding-right: 14px;
                font-size: 16px;
                line-height: 35px;
            }
        }
    }
    .catalog-filter-panel .filters:not(.filters-search) {
        overflow: hidden;
        height: 0;
        padding: 0;
        margin-top: -1px;
        border-bottom: 1px solid #E6E6E6;
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        top: 49px;
        background: #fff;
        .transition(all, 400ms, 400ms, ease);
        .filter {
            opacity: 0;
            .transition(all, 400ms, 0ms, ease);
        }
    }
    .catalog-filter-panel .filters.open:not(.filters-search) {
        padding: 3px 0 3px 20px;
        margin: 0;
        height: auto;
        overflow: visible;
        .transition(all, 400ms, 0ms, ease);
        .filter {
            opacity: 1;
            -webkit-transition: all 0ms ease 400ms, opacity 400ms ease 400ms;
            -moz-transition: all 0ms ease 400ms, opacity 400ms ease 400ms;
            -ms-transition: all 0ms ease 400ms, opacity 400ms ease 400ms;
            -o-transition: all 0ms ease 400ms, opacity 400ms ease 400ms;
            transition: all 0ms ease 400ms, opacity 400ms ease 400ms;
        }
    }
    .catalog-filter-panel .filters .filter:not(.filters-search) {
        text-align: left;
    }
    .catalog-filter-panel {
        .title_filter {
            display: inline-block;
            text-align: center;
            font-size: 15px;
            color: black;
            cursor: pointer;
        }
    }
    .wrapTable {
        overflow-x: auto;
    }
    .wrapp_accordion {
        font-size: 9px;
        overflow-x: auto;
    }

    html.fancybox-margin, .fancybox-margin body {
        overflow: hidden!important;
    }
    .admin-stat__country-box {
        float: left;
    }
    #ordersFilters > div.cartTable.ibox-content.m-b-sm.border-bottom.cart-table-wrapper > div.wrapp_accordion > div.accordion-content > table > tbody > tr > td.cell.count-box.text-center > div {
        display: flex;
    }

    .count-box {
        .count-selector-button {
            font-size: 25px;
        }
        .number-wrapper {
            width: 55px;
            margin: 5px 7px 0;
            height: 21px;
        }
        input[type=number] {
            font-size: 12px;
            line-height: 100%;
            padding: 0;
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
                font-size: 20px;
                width: 100%;
                line-height: 100%;
                padding: 0;
            }
        }
    }
    .catalog-grid .models-list {
        padding-left: 30px;

        .model-card {
            width: 208px;
        }
    }
    .order-confirm-btn {
        width: auto;
    }
}

@media (max-width: 750px) {
    .fancybox-margin, .fancybox-margin body {
        overflow: visible!important;
    }
    html.fancybox-margin {
        overflow: hidden!important;
    }
    .order-proceed-modal {
        &.animated {
            -webkit-animation-duration: 0s;
            animation-duration: 0s;
        }
    }
}
@media (max-width: 350px) {
    .nav.navbar-top-links li.dropdown {
        display: inline-table;
    }
}
