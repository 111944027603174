.multicheck{
    width: 100%;
    height: 300px;
    border: 1px solid #e5e6e7;
    overflow: hidden;
    &.distStores {
        height: 200px;
    }
    &__search-input-wrapper{
        width: 100%;
    }
    &__search-input{
        width: 100%;
        line-height: 25px;
        border: none;
        border-bottom: 1px solid #e5e6e7;
        padding: 2px 10px 2px 18px;
        outline: none!important;
        font-weight: 400;
    }
    &__list-wrapper{
        height: calc(~"100% - 30px");
        overflow-y: auto;
    }
    &__list{

    }
    &__item{
        display: block;
    }
    &__filter {
        padding: 0;
        max-height: 360px;
        height: auto;
        .multicheck__search-input-wrapper{
            &.visible + .multicheck__list-wrapper {
                max-height: 290px;
            }
        }
        .multicheck__list-wrapper{
            height: 100%;
            max-height: 330px;
            margin-bottom: 25px;
            overflow-y: auto;
        }
        .multicheck__search-input {
            padding: 5px .5em 4px 1em;
        }
        .checkboxes-multicheck-list {
            margin: 0;
        }
        .checkboxes-multicheck-list__item-label {
            padding: 6px .5em 6px 1em;
            margin: 0;
        }
        .multicheck__item{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            .checkboxes-multicheck-list__checkbox[type=checkbox]:before {
                -webkit-transition: all 0.3s ease-in-out 0ms, background 0ms ease-in-out 0ms;
                -moz-transition: all 0.3s ease-in-out 0ms, background 0ms ease-in-out 0ms;
                -ms-transition: all 0.3s ease-in-out 0ms, background 0ms ease-in-out 0ms;
                -o-transition: all 0.3s ease-in-out 0ms, background 0ms ease-in-out 0ms;
                transition: all 0.3s ease-in-out 0ms, background 0ms ease-in-out 0ms;
                background: #fff;
            }
            &:hover {
                background: #e4e4e4;
                .checkboxes-multicheck-list__checkbox[type=checkbox]:after,
                .checkboxes-multicheck-list__checkbox[type=checkbox]:before,
                .checkboxes-multicheck-list__checkbox[type=checkbox]:checked:before {
                    background: #e4e4e4;
                }
            }
        }
        &__control_btn {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
            box-shadow: 0 0 2px rgba(0,0,0,.13);
            width: 100%;
            height: 25px;
        }
        &__btn_bottom {
            cursor: pointer;
            width: 50%;
            min-width: 50%;
            box-sizing: border-box;
            text-align: center;
            padding: 2px;
            font-size: 13px;
            line-height: 1.5;
            display: inline-block;
            float: left;
            border-radius: 0;
        }
    }
}