/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.4
 *
*/

// Variables, Mixins
@import "variables.less";
@import "mixins.less";
@import "fonts.less";

// INSPINIA Theme Elements
@import "typography.less";
@import "navigation.less";
@import "top_navigation.less";
@import "buttons.less";
@import "badgets_labels.less";
@import "statuses_order.less";
@import "elements.less";
@import "sidebar.less";
@import "base.less";
@import "pages.less";
@import "chat.less";
@import "metismenu.less";
@import "spinners.less";

// Landing page styles
@import "landing.less";

// RTL Support
@import "rtl.less";

// For demo only - config box style
@import "theme-config.less";

// INSPINIA Skins
@import "skins.less";
@import "md-skin.less";

// Media query style
@import "media.less";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  #page-wrapper {
    margin: 0;
  }
}














