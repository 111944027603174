.tippy-tooltip {
    .extended-info-my-storecounts {
        display: block;
    }

    &.no-max-width-theme {
        max-width: none !important;
    }

    &.help_block-theme {
        box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.5) !important;
        max-width: 360px !important;
    }

    &.select_user_not_active_text-theme {
        border-radius: 8px;
        padding: 16px 24px;
        .tippy-arrow {
            border-top-width: 13px !important;
            border-right-width: 9px !important;
            border-left-width: 9px !important;
            bottom: -13px !important;
        }
        .tippy-content {
            color: #C94942;
            font-size: 16px;
            font-weight: 400;
        }
    }
}
