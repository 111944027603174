@font-face {
    font-family: "SegoeUI";
    src: url("../fonts/SegoeUI/SegoeUIRegular/SegoeUIRegular.eot");
    src: url("../fonts/SegoeUI/SegoeUIRegular/SegoeUIRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUIRegular/SegoeUIRegular.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "SegoeUI";
    src: url("../fonts/SegoeUI/SegoeUIBold/SegoeUIBold.eot");
    src: url("../fonts/SegoeUI/SegoeUIBold/SegoeUIBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUIBold/SegoeUIBold.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUIBold/SegoeUIBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
/* font-family: "SegoeUIItalic"; */
@font-face {
    font-family: "SegoeUI";
    src: url("../fonts/SegoeUI/SegoeUIItalic/SegoeUIItalic.eot");
    src: url("../fonts/SegoeUI/SegoeUIItalic/SegoeUIItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUIItalic/SegoeUIItalic.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUIItalic/SegoeUIItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}
/* font-family: "SegoeUILight"; */
@font-face {
    font-family: "SegoeUI";
    src: url("../fonts/SegoeUI/SegoeUILight/SegoeUILight.eot");
    src: url("../fonts/SegoeUI/SegoeUILight/SegoeUILight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUILight/SegoeUILight.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUILight/SegoeUILight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}
/* font-family: "SegoeUISemiBold"; */
@font-face {
    font-family: "SegoeUI";
    src: url("../fonts/SegoeUI/SegoeUISemiBold/SegoeUISemiBold.eot");
    src: url("../fonts/SegoeUI/SegoeUISemiBold/SegoeUISemiBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUISemiBold/SegoeUISemiBold.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUISemiBold/SegoeUISemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'Forum';
    src: url("../fonts/Forum/Forum-Regular.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}
/* BEGIN Thin */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Thin/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/Roboto/Thin/Roboto-Thin.woff") format("woff"),
    url("../fonts/Roboto/Thin/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
    font-style: normal; }
/* END Thin */
/* BEGIN Thin Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.woff2") format("woff2"),
    url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.woff") format("woff"),
    url("../fonts/Roboto/ThinItalic/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
    font-style: italic; }
/* END Thin Italic */
/* BEGIN Light */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Light/Roboto-Light.eot");
    src: url("../fonts/Roboto/Light/Roboto-Light.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Light/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto/Light/Roboto-Light.woff") format("woff"),
    url("../fonts/Roboto/Light/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal; }
/* END Light */
/* BEGIN Light Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/LightItalic/Roboto-LightItalic.woff2") format("woff2"),
    url("../fonts/Roboto/LightItalic/Roboto-LightItalic.woff") format("woff"),
    url("../fonts/Roboto/LightItalic/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: italic; }
/* END Light Italic */
/* BEGIN Regular */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot");
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto/Regular/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal; }
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot");
    src: url("../fonts/Roboto/Regular/Roboto-Regular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto/Regular/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto/Regular/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
    font-style: normal; }
/* END Regular */
/* BEGIN Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Italic/Roboto-Italic.woff2") format("woff2"),
    url("../fonts/Roboto/Italic/Roboto-Italic.woff") format("woff"),
    url("../fonts/Roboto/Italic/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: italic; }
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Italic/Roboto-Italic.woff2") format("woff2"),
    url("../fonts/Roboto/Italic/Roboto-Italic.woff") format("woff"),
    url("../fonts/Roboto/Italic/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
    font-style: italic; }
/* END Italic */
/* BEGIN Medium */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Medium/Roboto-Medium.eot");
    src: url("../fonts/Roboto/Medium/Roboto-Medium.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Medium/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto/Medium/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto/Medium/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal; }
/* END Medium */
/* BEGIN Medium Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.woff2") format("woff2"),
    url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.woff") format("woff"),
    url("../fonts/Roboto/MediumItalic/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: italic; }
/* END Medium Italic */
/* BEGIN Bold */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot");
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto/Bold/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal; }
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot");
    src: url("../fonts/Roboto/Bold/Roboto-Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto/Bold/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto/Bold/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
    font-style: normal; }
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff2") format("woff2"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff") format("woff"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: italic; }
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff2") format("woff2"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.woff") format("woff"),
    url("../fonts/Roboto/BoldItalic/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
    font-style: italic; }
/* END Bold Italic */
/* BEGIN Black */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/Black/Roboto-Black.woff2") format("woff2"),
    url("../fonts/Roboto/Black/Roboto-Black.woff") format("woff"),
    url("../fonts/Roboto/Black/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: normal; }
/* END Black */
/* BEGIN Black Italic */
@font-face {
    font-family: Roboto;
    src: url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.woff2") format("woff2"),
    url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.woff") format("woff"),
    url("../fonts/Roboto/BlackItalic/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: italic; }
/* END Black Italic */


@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansLight/OpenSansLight.eot");
    src: url("../fonts/OpenSans/OpenSansLight/OpenSansLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansLight/OpenSansLight.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansLight/OpenSansLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.eot");
    src: url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansLightItalic/OpenSansLightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot");
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot");
    src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot");
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot");
    src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansMedium/OpenSansMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansMediumItalic/OpenSansMediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot");
    src: url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot");
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot");
    src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBold/OpenSansBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansBoldItalic/OpenSansBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.eot");
    src: url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansExtraBold/OpenSansExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot");
    src: url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'Droid Sans Mono';
    src: url('../fonts/DroidSansMono/DroidSansMono-webfont.eot');
    src: url('../fonts/DroidSansMono/DroidSansMono-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DroidSansMono/DroidSansMono-webfont.woff2') format('woff2'),
    url('../fonts/DroidSansMono/DroidSansMono-webfont.woff') format('woff'),
    url('../fonts/DroidSansMono/DroidSansMono-webfont.ttf') format('truetype'),
    url('../fonts/DroidSansMono/DroidSansMono-webfont.svg#droid_sans_monoregular') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/montserrat-400-normal.woff2') format('woff2'),
    url('../fonts/Montserrat/montserrat-400-normal.woff') format('woff'),
    url('../fonts/Montserrat/montserrat-400-normal.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/NotoSans/noto-700-normal.woff2') format('woff2'),
    url('../fonts/NotoSans/noto-700-normal.woff') format('woff'),
    url('../fonts/NotoSans/noto-700-normal.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}