.transition(@params: all, @time: 400ms, @delay: 0ms, @func: ease){
    -webkit-transition: @params @time @func @delay;
    -moz-transition: @params @time @func @delay;
    -ms-transition: @params @time @func @delay;
    -o-transition: @params @time @func @delay;
    transition: @params @time @func @delay;
}

.offsel(){
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}