.standalone-message {
    width: 890px;
    margin: 173px auto;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 54px 195px;
    font-family: Source Sans Pro, sans-serif;
    color: #020204;

    &__icon {
        display: block;
        width: 54px;
        height: 54px;
        background: no-repeat;
        background-size: contain;
        margin-bottom: 24px;

        &_success {
            background-image: url("data:image/svg+xml,%3Csvg width='55' height='54' viewBox='0 0 55 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1667 29.0834L25.4167 35.3334L35.8333 20.75M52.5 27C52.5 40.8071 41.3071 52 27.5 52C13.6929 52 2.5 40.8071 2.5 27C2.5 13.1929 13.6929 2.00003 27.5 2.00003C41.3071 2.00003 52.5 13.1929 52.5 27Z' stroke='%231BC000' stroke-width='3.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }

        &_tools {
            background-image: url("data:image/svg+xml,%3Csvg width='55' height='54' viewBox='0 0 55 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.0399 34.7581L40.3727 49.0909C42.9182 51.6364 47.0453 51.6364 49.5909 49.0909C52.1364 46.5453 52.1364 42.4182 49.5908 39.8727L35.1448 25.4267M26.0399 34.7581L32.1758 27.3073C32.9527 26.364 33.9917 25.7706 35.1448 25.4267M26.0399 34.7581L14.597 48.653C13.4074 50.0976 11.634 50.9345 9.76267 50.9345C6.30389 50.9345 3.5 48.1306 3.5 44.6718C3.5 42.8004 4.33687 41.0271 5.78144 39.8375L22.5868 25.9978M35.1448 25.4267C36.4953 25.0239 38.0025 24.9633 39.4285 25.0839C39.7397 25.1102 40.0546 25.1236 40.3727 25.1236C46.482 25.1236 51.4345 20.1711 51.4345 14.0618C51.4345 12.4387 51.0849 10.8973 50.457 9.50874L42.4036 17.5622C39.6611 16.9326 37.5022 14.7738 36.8727 12.0313L44.9262 3.97771C43.5375 3.34964 41.9959 3 40.3727 3C34.2634 3 29.3109 7.95254 29.3109 14.0618C29.3109 14.3799 29.3243 14.6948 29.3506 15.006C29.5741 17.6497 29.1752 20.5721 27.1272 22.2587L26.8762 22.4653M22.5868 25.9978L12.4945 15.9054H9.0309L3.5 6.68727L7.18727 3L16.4054 8.5309V11.9945L26.8762 22.4653M22.5868 25.9978L26.8762 22.4653M43.1381 42.6381L36.6854 36.1854M9.93363 44.4819H9.95207V44.5003H9.93363V44.4819Z' stroke='%23E4003D' stroke-width='3.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
    }

    &__title {
        margin-bottom: 12px;
        font-size: 38px;
        font-weight: 600;
        line-height: 42px;
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}
