.pagination{
    text-align: center;
    width: 100%;
    margin-top: 30px;
    &__page-separator{
        display: inline-block;
        width: 30px;
        color: black;
        font-size: 16px;
        vertical-align: top;
        line-height: 52px;
    }
    &__page-button{
        display: inline-block;
        padding: 9px 15px;
        background-color: white;
        margin: 5px;
        font-size: 16px;
        color: black;
        :hover{
            background-color: #fafafa;
        }
        &_current{
            color: @primaryColor;
        }
    }
}