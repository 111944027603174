/**
структура:
 <span class="my-tooltip">
     <i class="fa fa-question-circle"></i>
     <span class="my-tooltip__content">Контент внутри всплывашки</span>
 </span>
модификаторы для .my-tooltip:
 my-tooltip_blue - голубой цвет балуна
 my-tooltip_help - дополнительные свойства, если используется с иконкой для создания всплывающей подсказки
 my-tooltip_on-hover - показывать балун при наведении (по-умолчанию - только при клике, через добавление класса .active)

 */

@contentWidth: 360px;
@contentMargin: 180px;

@vBaseContentHeight: 80px;
@vBaseContentWidth: 200px;

@borderWidth: 7px;

@default_iconColor: #333;
@default_bgColor: white;
@default_textColor: #333;

@blue_iconColor: #0080e5;
@blue_bgColor: #0080e5;
@blue_textColor: white;

.my-tooltip{
    position: relative;
    &_help{
        display: inline-block;
        font-size: 17px;
        vertical-align: middle;
        margin-left: 6px;
    }
    &_blue{
        color: @blue_iconColor;
        & .my-tooltip__content{
            background-color: @blue_bgColor;
            color: @blue_textColor;
            &:before{
                border-top-color: @blue_bgColor;
            }
        }
    }
    &_right{
        & .my-tooltip__content{
            left: 0;
            top: 50%;
            bottom: initial;
            margin: 0 0 0 3px;
            min-width: @vBaseContentWidth;
            min-height: @vBaseContentHeight;
            transform: translate(5px, -50%);
            height: auto;
            width: auto;
            &:before{
                top: 50%;
                left: -@borderWidth*2;
                margin-left: 0;
                margin-top: -@borderWidth;
                border-color: transparent;
                border-right-color: white;
            }
        }
        &.my-tooltip_on-hover:hover, &.my-tooltip.active{
            .my-tooltip__content{
                visibility: visible;
                opacity: 1;
                left: 100%;
                bottom: initial;
            }
        }
    }
    &_left{
        & .my-tooltip__content{
            left: -10px;
            top: 50%;
            bottom: initial;
            margin: 0 0 0 3px;
            min-width: @vBaseContentWidth;
            min-height: @vBaseContentHeight;
            transform: translate(-100%, -50%);
            height: auto;
            width: auto;
            &:before{
                top: 50%;
                right: -@borderWidth*2;
                left: 100%;
                margin-left: 0;
                margin-top: -@borderWidth;
                border-color: transparent;
                border-left-color: white;
            }
        }
        &.my-tooltip_on-hover:hover, &.my-tooltip.active{
            .my-tooltip__content{
                visibility: visible;
                opacity: 1;
                left: -10px;
                bottom: initial;
            }
        }
    }
    &_on-click{
        cursor: pointer;
    }
    &__content{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s ease, bottom 0.5s ease, left 0.5s ease;
        position: absolute;
        z-index: 5;
        left: 50%;
        bottom: -0px;
        margin: 0 0 5px -@contentMargin;
        width: @contentWidth;
        height: auto;
        background-color: @default_bgColor;
        color: @default_textColor;
        font-size: 12px;
        font-weight: normal;
        padding: 10px 15px;
        border-radius: 5px;
        filter: drop-shadow(0 0 4px rgba(0,0,0,0.3));
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -@borderWidth;
            width: 0;
            height: 0;
            border: @borderWidth solid transparent;
            border-top-color: @default_bgColor;
        }
        &__auto {
            width: auto;
            margin: 0 0 5px 0;
            transform: translate(-50%, 0);
            white-space: nowrap;
        }
    }
    &_on-hover:hover, &.active{
        .my-tooltip__content{
            visibility: visible;
            opacity: 1;
            bottom: 100%;
        }
    }
}
